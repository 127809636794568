<template>
<div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="header text-center">
                            <h3 class="mt-2 mb-3">{{ titulo }}</h3>
                            <div class="row justify-content-center">
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-2">
                                            <div class="tribunales">
                                                <a class="btn" href="#">Civil</a>
                                                <ul class="sub">
                                                    <li v-for="lista in lstCvl[0]" v-bind:key="lista.TITULO"><a class="link" v-on:click="cargaTribunal(lista.SECCION, 'Civil', lista.TITULO)">{{ lista.TITULO }}</a></li>
                                                </ul>
                                            </div>
                                            <input v-model="listaCivil" style="display: none">
                                        </div>
                                        <div class="col-md-2">
                                            <div class="tribunales">
                                                <a class="btn" href="#">Cobranza</a>
                                                <ul class="sub">
                                                    <li v-for="lista in lstCbz[0]" v-bind:key="lista.TITULO"><a class="link" v-on:click="cargaTribunal(lista.SECCION, 'Cobranza', lista.TITULO)">{{ lista.TITULO }}</a></li>
                                                </ul>
                                            </div>
                                            <input v-model="listaCobranza" style="display: none">
                                        </div>
                                        <div class="col-md-2">
                                            <div class="tribunales">
                                                <a class="btn" href="#">Familia</a>
                                                <ul class="sub">
                                                    <li v-for="lista in lstFml[0]" v-bind:key="lista.TITULO"><a class="link" v-on:click="cargaTribunal(lista.SECCION, 'Familia', lista.TITULO)">{{ lista.TITULO }}</a></li>
                                                </ul>
                                            </div>
                                            <input v-model="listaFamilia" style="display: none">
                                        </div>
                                        <div class="col-md-2">
                                            <div class="tribunales">
                                                <a class="btn" href="#">Laboral</a>
                                                <ul class="sub">
                                                    <li v-for="lista in lstLbl[0]" v-bind:key="lista.TITULO"><a class="link" v-on:click="cargaTribunal(lista.SECCION, 'Laboral', lista.TITULO)">{{ lista.TITULO }}</a></li>
                                                </ul>
                                            </div>
                                            <input v-model="listaLaboral" style="display: none">
                                        </div>
                                        <div class="col-md-2">
                                            <div class="tribunales">
                                                <a class="btn" href="#">Penal</a>
                                                <ul class="sub">
                                                    <li v-for="lista in lstPnl[0]" v-bind:key="lista.TITULO"><a class="link" v-on:click="cargaTribunal(lista.SECCION, 'Penal', lista.TITULO)">{{ lista.TITULO }}</a></li>
                                                </ul>
                                            </div>
                                            <input v-model="listaPenal" style="display: none">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="trib != ''">
                          <CorteApeTerr
                              :tabs="tbs"
                              :cgraf="cgraf"
                              :secc="secc"
                              :cort="cort"
                              :trib="trib"
                              :comp="comp"
                              :titulo="tit"
                              :key="keyCorteTerr"
                          >
                          </CorteApeTerr>
                        </div>
                    </div>
                </div>
            </div>
</div>
</template>
<script>
import {mapState, mapActions} from 'vuex';
import CorteApeTerr from '@/components/CorteApeTerr.vue';
export default {
  props: {
    cort: {
      type: String,
    },
    titulo: {
      type: String,
      default: '',
    },
  },
  name: 'TribunalesTerr',
  methods: {
    ...mapActions([
      'categoriasM',
      'seriesM',
      'actubicacionMaps',
      'actmodulos',
      'acttabulaciones',
    ]),
    cargaTribunal: function(seccion, competencia, titulo) {
      this.keyCorteTerr += 1;
      this.tit = titulo;
      this.trib = seccion;
      this.comp = competencia;
      console.log(this.ipapi + 'modulos/0/' + seccion + '/' + competencia);
      this.axios.get(this.ipapi + 'modulos/0/' + seccion + '/' + competencia)
          .then((response) => {
            this.m = [];
            this.m.push(JSON.parse(response.data[0].GRAFICOS));
            this.tbs = this.m[0].TEXTO;
            this.secc = this.m[0].SECCION;
            this.cgraf = [];
            for (const i in this.secc) {
              this.axios.get(this.ipapi + 'textos/territorios/' + this.secc[i] + '/' + competencia)
                  .then((response) => {
                    this.cgraf.push(response.data);
                    console.log(this.cgraf);
                  })
                  .catch(function(error) {

                  });
            }
          })
          .catch(function(error) {

          });
    },
  },
  mounted() {
    console.clear();
  },
  components: {CorteApeTerr},
  data() {
    return {
      lstCvl: [],
      lstCbz: [],
      lstFml: [],
      lstLbl: [],
      lstPnl: [],
      tbs: [],
      secc: [],
      cgraf: [],
      trib: '',
      tit: '',
      comp: '',
      keyCorteTerr: 0,
      selectedComponent: 'CorteApeTerr',
    };
  },
  computed: {
    ...mapState([
      'categoriasC',
      'seriesC',
      'ubicacionmaps',
      'modulos',
      'tabulaciones',
      'ipapi',
    ]),
    listaCivil() {
      this.trib = '';
      this.lstCvl = [];
      if (this.cort !== undefined) {
        this.axios.get(this.ipapi + 'modulos/' + this.cort + '/0/Civil')
            .then((response) => {
              this.lstCvl.push(response.data);
              console.log(this.lstCvl);
            })
            .catch(function(error) {

            });
      }
    },
    listaCobranza() {
      this.lstCbz = [];
      if (this.cort !== undefined) {
        this.axios.get(this.ipapi + 'modulos/' + this.cort + '/0/Cobranza')
            .then((response) => {
              this.lstCbz.push(response.data);
              console.log(this.lstCbz);
            })
            .catch(function(error) {

            });
      }
    },
    listaFamilia() {
      this.lstFml = [];
      if (this.cort !== undefined) {
        this.axios.get(this.ipapi + 'modulos/' + this.cort + '/0/Familia')
            .then((response) => {
              this.lstFml.push(response.data);
              console.log(this.lstFml);
            })
            .catch(function(error) {

            });
      }
    },
    listaLaboral() {
      this.lstLbl = [];
      if (this.cort !== undefined) {
        this.axios.get(this.ipapi + 'modulos/' + this.cort + '/0/Laboral')
            .then((response) => {
              this.lstLbl.push(response.data);
              console.log(this.lstLbl);
            })
            .catch(function(error) {

            });
      }
    },
    listaPenal() {
      this.lstPnl = [];
      if (this.cort !== undefined) {
        this.axios.get(this.ipapi + 'modulos/' + this.cort + '/0/Penal')
            .then((response) => {
              this.lstPnl.push(response.data);
              console.log(this.lstPnl);
            })
            .catch(function(error) {

            });
      }
    },
  },
};
</script>
