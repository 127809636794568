<template>
  <div class="home">
    <MenuElement/>
    <router-view/>
    <FooterElement/>

  </div>
</template>

<script>
// @ is an alias to /src
import MenuElement from '@/components/MenuElement.vue';
import FooterElement from '@/components/FooterElement.vue';

export default {
  name: 'home',
  data() {
    return {
      contentOnly: false,
    };
  },
  components: {
    MenuElement,
    FooterElement},
  mounted() {
    // Tribunales
    $(document).on('click', '.tribunales a.btn', function(e) {
      e.preventDefault();
      if ($(this).parent().hasClass('active')) {
        $('.tribunales').removeClass('active');
      } else {
        $('.tribunales').removeClass('active');
        $(this).parent().addClass('active');
      }
    });
    $(document).on('click', '.tribunales a.link', function(e) {
      e.preventDefault();
      if ($(this).parent().hasClass('active')) {
        $('.tribunales').removeClass('active');
      } else {
        $('.tribunales').removeClass('active');
        $(this).parent().addClass('active');
      }
    });
    console.clear();
  },
};
</script>
