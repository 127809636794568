<template>
	<div>
	 <div class="box_mode content_wrapper">
		<br>
		<br>
		<br>
		<br>
		<section id="territorios">
			  <div class="container">
				  <div class="row justify-content-center">
					  <div class="col-md-2"></div>
					  <div class="col-md-8">
						  <h2 class="text-center" v-if="titulo[0]">{{ titulo[0].TITULO }}</h2>
					  </div>
					  <div class="col-md-2"></div>
				  </div>
				  <div class="row">
					  <div class="col-md-12">
						  <p class="text-center">
	  "La Corte Suprema funcionará dividida en salas especializadas o en pleno.
	  La Corte funcionará ordinariamente dividida en tres salas o extraordinariamente en cuatro, correspondiéndole a la propia Corte determinar
	  uno u otro modo de funcionamiento.
	  En cualquier caso, las salas deberán funcionar con no menos de cinco jueces y/o juezas cada una y el pleno con la concurrencia de once de sus miembros a lo menos.
	  Corresponderá a la propia Corte, mediante auto acordado, establecer la forma de distribución de sus ministros entre las diversas salas
	  de su funcionamiento ordinario o extraordinario.
	  La distribución de ministras y ministros que se efectúe permanecerá invariable por un período de, a lo menos, dos años.
	  La integración de sala será facultativa para el Presidente de la Corte. Si opta por hacerlo, podrá integrar cualquiera de las salas.
	  Cada sala en que se divida la Corte Suprema será presidida por el ministro más antiguo, cuando no esté presente el Presidente de la Corte." Art. 95 Código Orgánico de Tribunales
						  </p>
					  </div>
				  </div>
				  <div class="corte row justify-content-center">
					  <div class="col-md-4"></div>
					  <div class="col-md-4 text-center">
						  <div class="icon icon-corte">
							  <img src="@/assets/img/01_ctesup.png" class="img">
							  <h3>La Corte Suprema <i>conoce</i></h3>
						  </div>
						  <div class="table-bordered"></div>
						  <div class="col-md-4"></div>
					  </div>
				  </div>
			  </div>
			  <div class="container">
			  <div class="cifras2 row justify-content-center">
					  <div class="col-md-3"></div>
					  <div class="col-md-2 text-center">
						  <div class="icon icon-cifras2">
							  <img src="@/assets/img/01_pleno.png" class="img" style="margin-top: 0px;margin-bottom: 0px;">
							  <h3>En Pleno<br></h3>
							  <p class="mb-0">Al menos 11 de sus miembros</p>
						  </div>
  
					  </div>
					  <div class="col-md-2 text-center">
  
					  </div>
					  <div class="col-md-2 text-center">
						  <div class="icon icon-cifras2">
							  <img src="@/assets/img/04_garantia.png" class="img" >
							  <h3 style="margin-top: 0px;margin-bottom: 0px;">Salas especializadas</h3>
							  <p class="mb-0">No menos de 5 integrantes por sala</p>
						  </div>
						  <div class="table-bordered"></div>
					  </div>
					  <div class="col-md-3"></div>
				  </div>
			  </div>
			  <div class="container">
				  <div class="cifras2 row justify-content-center">
					  <div class="col-md-6 text-center"></div>
					  <div class="col-md-2 text-center">
						  <div class="icon icon-cifras2">
							  <span>3 Salas</span>
							  <h3>Funcionamiento Ordinario</h3>
						  </div>
					  </div>
						  <div class="col-md-2 text-center">
							  <div class="icon icon-cifras2">
								  <span style="margin-top: 0px;margin-bottom: 0px;">4 Salas</span>
								  <h3 style="margin-top: 0px;margin-bottom: 0px;">Funcionamiento Extraordinario</h3>
								  <p class="mb-0">(regular en la práctica)</p>
								  <p class="mb-0">Corresponden a las siguientes Salas</p>
								  <div class="arrow-down"></div>
							  </div>
						  </div>
					  <div class="col-md-2 text-center"></div>
				  </div>
			  </div>
			  <br>
			  <br>
		  </section>
		  <section id="salas" class="bg-celeste">
			  <div class="container">
				  <div class="row justify-content-center">
					  <div class="col-md-12">
					  <div class="col-md-2"></div>
					  <div class="col-md-8 salas">
						  <h3 class="text-center">Sala Civil</h3>
					  </div>
					  <div class="col-md-2"></div>
					  </div>
					  <div class="col-md-12">
					  <div class="col-md-2"></div>
					  <div class="col-md-8 text-center"><p>Integrada por los Ministros y Ministras: Sr. Arturo Prado Puga, Sra. María Angélica Repetto García, Sr. Mauricio Silva Cancino y Sra. María Soledad Melo Labra</p></div>
					  <div class="col-md-2"></div>
					  </div>
				  </div>
  
				  <div class="cifras row justify-content-center">
					  <div class="col-md-3"></div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/16_ingresos.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  <a class="numerogrande">
								  <countTo :startVal='0' :endVal='2819' :duration='2500'  separator="."></countTo>
							  </a>
						  </div>
						  <p class="mb-0">Ingresos</p>
					  </div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/17_terminos.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  <a class="numerogrande">
								  <countTo :startVal='0' :endVal='2962' :duration='2500'  separator="."></countTo>
							  </a>
						  </div>
						  <p class="mb-0">Términos</p>
					  </div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/18_en_tramite.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  <a class="numerogrande">
								  <countTo :startVal='0' :endVal='165' :duration='2500'  separator="."></countTo> días
							  </a>
						  </div>
						  <p class="mb-0">Duración promedio de causas</p>
					  </div>
					  <div class="col-md-3"></div>
				  </div>
  
				  <hr>
  
			  </div>
			  <div class="container">
				  <div class="row justify-content-center">
					  <div class="col-md-12">
					  <div class="col-md-2"></div>
					  <div class="col-md-8 salas">
						  <h3 class="text-center">Sala Penal</h3>
  
					  </div>
					  <div class="col-md-2"></div>
					  </div>
					  <div class="col-md-12">
					  <div class="col-md-2"></div>
					  <div class="col-md-8 text-center"><p>Integrada por los Ministros y Ministras: Sr. Haroldo Brito Cruz, Sr. Manuel Valderrama Rebolledo, Sr. Jorge Dahm Oyarzún, Sr. Leopoldo Llanos Sagristá y Sra. María Teresa Letelier Ramírez</p></div>
					  <div class="col-md-2"></div>
					  </div>
				  </div>
  
				  <div class="cifras row justify-content-center">
					  <div class="col-md-3"></div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/16_ingresos.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  <a class="numerogrande">
								  <countTo :startVal='0' :endVal='8259' :duration='2500'  separator="."></countTo>
							  </a>
						  </div>
						  <p class="mb-0">Ingresos</p>
					  </div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/17_terminos.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  <a class="numerogrande">
								  <countTo :startVal='0' :endVal='7944' :duration='2500'  separator="."></countTo>
							  </a>
						  </div>
						  <p class="mb-0">Términos</p>
					  </div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/18_en_tramite.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  <a class="numerogrande">
								  <countTo :startVal='0' :endVal='44' :duration='2500'  separator="."></countTo> días
							  </a>
						  </div>
						  <p class="mb-0">Duración promedio de causas</p>
					  </div>
					  <div class="col-md-3"></div>
				  </div>
  
				  <hr>
  
			  </div>
			  <div class="container">
				  <div class="row justify-content-center">
					  <div class="col-md-12">
					  <div class="col-md-2"></div>
					  <div class="col-md-8 salas">
						  <h3 class="text-center">Sala Constitucional y Contencioso Administrativa</h3>
  
					  </div>
					  <div class="col-md-2"></div>
					  </div>
					  <div class="col-md-12">
					  <div class="col-md-2"></div>
					  <div class="col-md-8 text-center"><p>Integrada por los Ministros y Ministras: Sr. Sergio Muñoz Gajardo, Sra. Angela Vivanco Martínez, Sra. Adelita Ravanales Arriagada, Sr. Mario Carroza Espinoza y Sr. Jean Pierre Matus Acuña</p></div>
					  <div class="col-md-2"></div>
					  </div>
				  </div>
  
				  <div class="cifras row justify-content-center">
					  <div class="col-md-3"></div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/16_ingresos.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  <a class="numerogrande">
								  <countTo :startVal='0' :endVal='237802' :duration='2500'  separator="."></countTo>
							  </a>
						  </div>
						  <p class="mb-0">Ingresos</p>
					  </div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/17_terminos.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  <a class="numerogrande">
								  <countTo :startVal='0' :endVal='228681' :duration='2500'  separator="."></countTo>
							  </a>
						  </div>
						  <p class="mb-0">Términos</p>
					  </div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/18_en_tramite.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  <a class="numerogrande">
								  <countTo :startVal='0' :endVal='141' :duration='2500'  separator="."></countTo> días
							  </a>
						  </div>
						  <p class="mb-0">Duración promedio de causas</p>
					  </div>
					  <div class="col-md-3"></div>
				  </div>
				  <hr>
			  </div>
			  <div class="container">
				  <div class="row justify-content-center">
					  <div class="col-md-12">
					  <div class="col-md-2"></div>
					  <div class="col-md-8 salas">
						  <h3 class="text-center">Sala Laboral y Previsional</h3>
  
					  </div>
					  <div class="col-md-2"></div>
					  </div>
					  <div class="col-md-12">
					  <div class="col-md-2"></div>
					  <div class="col-md-8 text-center"><p>Integrada por los Ministros y Ministras: Sr. Ricardo Blanco Herrera, Sra. Gloria Ana Chevescih Ruiz, Sra. Andrea Muñoz Sánchez, Sra. María Gajardo Harboe y Sr. Diego Simpertigue Limare</p></div>
					  <div class="col-md-2"></div>
					  </div>
				  </div>
				  <br>
				  <div class="cifras row justify-content-center">
					  <div class="col-md-3"></div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/16_ingresos.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  <a class="numerogrande">
								  <countTo :startVal='0' :endVal='3751' :duration='2500'  separator="."></countTo>
							  </a>
						  </div>
						  <p class="mb-0">Ingresos</p>
					  </div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/17_terminos.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  <a class="numerogrande">
								  <countTo :startVal='0' :endVal='4007' :duration='2500'  separator="."></countTo>
							  </a>
						  </div>
						  <p class="mb-0">Términos</p>
					  </div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/18_en_tramite.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  <a class="numerogrande">
								  <countTo :startVal='0' :endVal='132' :duration='2500'  separator="."></countTo> días
							  </a>
						  </div>
						  <p class="mb-0">Duración promedio de causas</p>
					  </div>
					  <div class="col-md-3"></div>
				  </div>
  
				  <hr>
  
			  </div>
			  <!-- <div class="container">
				  <div class="row justify-content-center">
					  <div class="col-md-12">
					  <div class="col-md-2"></div>
					  <div class="col-md-8 salas">
						  <h3 class="text-center">Otras Salas Especiales</h3>
  
					  </div>
					  <div class="col-md-2"></div>
					  </div>
					  <div class="col-md-12">
					  <div class="col-md-2"></div>
					  <div class="col-md-8 text-center"><p></p></div>
					  <div class="col-md-2"></div>
					  </div>
				  </div>
  
				  <div class="cifras row justify-content-center">
					  <div class="col-md-3"></div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/16_ingresos.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  &nbsp;
							  <a class="numerogrande">&nbsp;
								  <countTo :startVal='0' :endVal='51' :duration='2500'  separator="."></countTo>&nbsp;
							  </a>&nbsp;
						  </div>
						  <p class="mb-0">Ingresos</p>
					  </div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/17_terminos.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  &nbsp;
							  <a class="numerogrande">&nbsp;
								  <countTo :startVal='0' :endVal='83' :duration='2500'  separator="."></countTo>
							  </a>&nbsp;
						  </div>
						  <p class="mb-0">Términos</p>
					  </div>
					  <div class="col-md-2 text-center">
						  <div class="circulo3 feature_img animated a_fu">
							  <img src="@/assets/img/18_en_tramite.png" class="img" style="width: 60%;display: inline-block;margin-top: -15%;">
							  <a class="numerogrande">
								  <countTo :startVal='0' :endVal='50' :duration='2500'  separator="."></countTo> días
							  </a>
						  </div>
						  <p class="mb-0">Duración promedio de causas</p>
					  </div>
					  <div class="col-md-3"></div>
				  </div>
  
				  <hr>
  
			  </div> -->
		  </section>
	  </div>
	</div>
  </template>
  <script>
  
  import countTo from 'vue-count-to';
  import {
	mapState,
	mapActions,
  } from 'vuex';
  export default {
	methods: {
	  ...mapActions([
		'actjuzgados',
		'actcortes',
	  ]),
	  UbiTerrMaps: function(a) {
		this.partes = a.split('-');
		this.ubicacion = this.partes[1];
	  },
	},
	mounted() {
	  this.axios.get(this.ipapi + 'textos/funcionamiento/titulo/0')
		  .then((response) => {
				this.titulo.push(response.data[0]);
				console.log(this.titulo);
		  })
		  .catch(function(error) {
			console.log(error);
		  });
	},
	components: {countTo},
	data() {
	  return {
		ubicacion: 0,
		titulo: [],
	  };
	},
	computed: {
	  ...mapState([
		'juzgados',
				'cortes',
				'ipapi',
	  ]),
	},
  };
  </script>
  <style src="vue-slim-tabs/themes/default.css"></style>
  <style>
  /* tribunales */
  .tribunales2{
	  position: relative;
  }
  .tribunales2 a.btn{
	  background-color: #fc9c3b;
	  color: #fff;
	  border-radius: 100px;
	  padding: 10px 15px;
	  line-height: 14px;
	  width: auto;
	  text-align: center;
  }
  
  .tribunales2 a.btn:hover{
	  color: #fff;
	  background-color: #3f51b5;
  
  }
  .tribunales2.active a.btn{
	  background-color: #3f51b5;
	  color: #FFF;
  
  }
  .tribunales2 ul.sub{
	  margin: 0;
	  list-style: none;
	  padding: 10px 20px;
	  background-color: #bfcdee;
	  border-radius: 20px;
	  position: absolute;
	  bottom: 50px;
	  left: -16%;
	  z-index: 99;
	  display: none;
	  max-height: 300px;
	  overflow: scroll;
  }
  .tribunales2.active ul.sub{
	  display: block;
  }
  .tribunales2 ul.sub li{
	  text-align: left;
	  padding: 0;
	  white-space: nowrap;
	  min-width: 280px;
  }
  .tribunales2 ul.sub li,
  .tribunales2 ul.sub li a{
	  font-size: 11px;
	  color: #666;
  }
  
  section .corte .icon.icon-corte{
	  width: 180px;
	  height: 180px;
	  border-radius: 50%;
	  background-color: rgba(63,81,181,.2);
	  margin: 50px auto 0;
  }
  section .corte .icon.icon-corte img{
	  width: 100%;
	  display: inline-block;
	  position: relative;
	  margin-top: 25%;
  
  }
  section .corte .icon.icon-corte h3{
	  font-size: 18px;
	  margin-bottom: 20px;
  
  }
  section .corte .icon.icon-corte span{
	  font-size: 32px;
	  color: #FFFF;
	  font-weight: bolder;
	  text-shadow: 2px 1px 6px #666666;
	  margin-top: 20px;
  
  }
  section .corte p{
	  color: #666;
	  font-size: 14px;
	  line-height: 18px;
	  margin: 5px auto;
	  max-width: 100%;
  }
  section .corte h3{
	  font-size: 28px;
	  font-weight: bolder;
	  text-align: center;
	  line-height: 18px;
	  margin: 15px auto;
  }
  
  section .cifras2 .icon{
  z-index: 3;}
  
  section .cifras2 .icon.icon-cifras2{
	  width: 130px;
	  height: 130px;
	  border-radius: 50%;
	  background-color: rgba(63,81,181,.2);
	  margin: auto;
  }
  section .cifras2 .icon.icon-cifras2 img{
	  width: auto;
	  max-height: 40px;
	  display: inline-block;
	  margin-top: 0;
  }
  section .cifras2 .icon.icon-cifras2 h3{
	  font-weight: bold;
	  font-size: 14px;
	  bottom: 0px;
  }
  section .cifras2 .icon.icon-cifras2 span{
	  font-size: 28px;
	  color: #FFFF;
	  font-weight: bold;
	  text-shadow: 2px 1px 6px #666666;
		  padding: 20px 0 0 0;
	  top: 0;
  
  }
  section .cifras2 p{
	  color: #666;
	  font-weight: normal;
	  font-style: italic;
	  font-size: 12px;
	  line-height: 14px;
	  margin: 5px auto;
	  max-width: 100px;
  }
  section .cifras3 .icon{
  z-index: 3;}
  
  section .cifras3 .icon.icon-cifras3{
	  width: 100px;
	  height: 100px;
	  margin: auto;
  }
  section .cifras3 .icon.icon-cifras3 img{
	  width: auto;
	  max-height: 40px;
	  display: inline-block;
	  margin-top: 0;
  }
  section .cifras3 .icon.icon-cifras3 h3{
	  font-weight: bold;
	  font-size: 14px;
	  bottom: 0px;
  }
  section .cifras3 .icon.icon-cifras3 span{
	  font-size: 18px;
	  color: #fc4f5e;
	  font-weight: bold;
	  padding: 20px 0 0 0;
	  top: 0;
  
  }
  section .cifras3 p{
	  color: #666;
	  font-weight: normal;
	  font-style: italic;
	  font-size: 12px;
	  line-height: 14px;
	  margin: 5px auto;
	  max-width: 100px;
  }
  </style>
  