<template>
  <div>
    <div class="box_mode content_wrapper">
      <br>
      <br>
      <br>
      <br>
      <div class="home_area">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section_title animated a_fu">
                <div class="h1">Corte Suprema</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2 text-left" style="">
                <div class="feature_dsc animated a_fu">
                    <div class="clearfix">
                    </div>
                    <div class="feature_txt">
                        <p>La Corte Suprema de Justicia de Chile es el <strong>máximo órgano jurisdiccional</strong> del Poder Judicial. Fue creada por la Constitución Política de 1823 e instalada el 29 de diciembre el mismo año, con lo que se convierte en una de las más antiguas instituciones en su tipo en el mundo.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-8 text-center" style="">
              <div class="corte row justify-content-center">
                <div class="col-md-12">
                  <div class="col-md-3"></div>
                  <div class="col-md-3 text-center" v-if="cifprinc[0]">
                    <div class="icon icon-corte">
                      <img :src="cifprinc[0].ICONO" class="img" style="width:95px; height:95px; margin-top: -20%;">
                        <h3>{{ cifprinc[0].TEXTO }}</h3>
                    </div>
                    <p>{{ cifprinc[0].VALOR }}</p>
                  </div>
                  <div class="col-md-3 text-center">
                    <div class="icon icon-corte" v-if="cifprinc[1]">
                      <img :src="cifprinc[1].ICONO" class="img" style="width:130px; height:95px; margin-top: -20%;">
                        <a class="numerogrande">
                          <countTo :startVal='0' :endVal='parseInt(cifprinc[1].VALOR)' :duration='2500'  separator="."></countTo>
                        </a>
                        <h3>{{ cifprinc[1].TEXTO }}</h3>
                    </div>
                    <!-- <div class="tribunales2" v-if="cminis">
                      <a class="btn">Ver Ministros</a>
                        <ul class="sub">
                          <li v-for="cmini in cminis" v-bind:key="cmini.TEXTO">{{ cmini.TEXTO }}</li>
                        </ul>
                    </div> -->
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </div><br>
              <div class="cifras row justify-content-center">
                <div class="col-md-2"></div>
                <div class="col-md-8" v-if="cifras && cifras.length">
                  <div v-for="cifra of cifras" v-bind:key="cifra + '' + cifra.TEXTO">
                    <div :class="cifra.CLASE + ' text-center'">
                      <div class="circulo3 feature_img animated a_fu">
                        <a :href="'#'+cifra.LINK" v-on:click="selectTab(cifra.LINK)">
                          <img :src="cifra.ICONO"  alt="" style="width: 60%;display: inline-block;margin-top: -15%;"><br>
                          <a class="numerogrande">
                            <countTo :startVal='0' :endVal='parseInt(cifra.VALOR)' :duration='2500'  separator="."></countTo>
                          </a>
                        </a>
                      </div>
                      <h3>{{ cifra.TEXTO }}</h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-2"></div>
              </div>
              <div class="col-md-12">
                <div class="tribunales2 text-center">
                  <router-link :to="{ name: 'Funcionamiento'}" class="btn">
                    Funcionamiento de la Corte Suprema
                  </router-link>
                </div><br>
                <div class="tribunales2 text-center">
                  <router-link :to="{ name: 'PersonalyUnidades'}" class="btn">
                    Organización del personal en la Corte Suprema
                  </router-link>
                </div>
                <div class="col-md-2"></div>
              </div>
            </div>
            <div class="col-md-2 animated a_fu text-right" style="">
                <div class="single_feature">
                    <div class="sf_inner">
                        <p>Es el superior jerárquico de las <strong>17 Cortes de Apelaciones</strong> y los más de <strong>448 tribunales</strong> de primera instancia a lo largo de todo el país, le corresponde la Superintendencia Directiva, Correccional y Económica sobre todos los tribunales de la nación, exceptuando al Tribunal Constitucional, el Tribunal Calificador de Elecciones y los tribunales electorales regionales.</p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class="brand_area sp100 animated a_fu" id="territorio-result">
    <CorteApeTerr
      :tabs="tabs"
      :cgraf="cgraf"
      :secc="secc"
      :cort="'0'"
      comp="suprema"
      titulo="Corte Suprema"
      subtitulo='Seleccione el ítem para revisar las cifras de la Corte Suprema'
    >
    </CorteApeTerr>
    <br>
    <br>
  </div>
</div>
</template>
<script>
import countTo from 'vue-count-to';
import CorteApeTerr from '@/components/CorteApeTerr.vue';
import {
  mapState,
  mapActions,
} from 'vuex';
export default {
  methods: {
    ...mapActions([
      'actubicacionMaps',
      'actcifras',
      'acttexto',
      'actmodulos',
      'actanioi',
    ]),
    selectTab: function(a) {
      if (a !== undefined) {
        $('.vue-tab').each(function() {
          const $this = $(this);
          if ($this.html() == a) {
            $this.trigger('click');
          }
        });
      }
      console.clear();
    },
    loadRoute(l) {
      // alert(this.$router.currentRoute.name)
      window.location.href = l;
    },
  },
  components: {countTo, CorteApeTerr},
  mounted() {
    // alert(this.$router.currentRoute.name)
    if (this.$router.currentRoute.name == 'Competencias') {
      this.UbiCompt(this.$route.params.competencia);
    }
    $(document).on('click', '.tribunales2 a.btn', function(e) {
      e.preventDefault();
      if ($(this).parent().hasClass('active')) {
        $('.tribunales2').removeClass('active');
      } else {
        $('.tribunales2').removeClass('active');
        $(this).parent().addClass('active');
      }
    });
    this.axios.get(this.ipapi + 'modulos/0/Suprema/0')
        .then((response) => {
          this.m = [];
          this.m.push(JSON.parse(response.data[0].GRAFICOS));
          this.tabs = this.m[0].TEXTO;
          this.secc = this.m[0].SECCION;
          for (const i in this.secc) {
            this.axios.get(this.ipapi + 'textos/suprema/' + this.secc[i] + '/0')
                .then((response) => {
                  this.cgraf.push(response.data);
                })
                .catch(function(error) {
                });
          }
        })
        .catch(function(error) {
        });
    this.axios.get(this.ipapi + 'cifras_clave/suprema/datos/' + (this.anioI === '' ? (new Date().getFullYear()) - 2 : this.anioI))
        .then((response) => {
          this.actcifras(response.data);
        })
        .catch(function(error) {
        });
    this.axios.get(this.ipapi + 'cifras_clave/suprema/principal/' + (this.anioI === '' ? (new Date().getFullYear()) - 2 : this.anioI))
        .then((response) => {
          this.cifprinc = response.data;
        })
        .catch(function(error) {
        });
    this.axios.get(this.ipapi + 'cifras_clave/suprema/ministros/' + (this.anioI === '' ? (new Date().getFullYear()) - 2 : this.anioI))
        .then((response) => {
          this.cminis = response.data;
        })
        .catch(function(error) {
        });
    console.clear();
  },
  data() {
    return {
      tabs: [],
      cgraf: [],
      cifprinc: [],
      cminis: [],
      secc: [],
      base_url: window.location.protocol + '//' + window.location.host,
    };
  },
  computed: {
    ...mapState([
      'ubicacionmaps',
      'cifras',
      'texto',
      'modulos',
      'anioI',
      'ipapi',
    ]),
  },
};
</script>
<style>
/* tribunales */
.tribunales2{
	position: relative;
}
.tribunales2 a.btn{
    background-color: #fc9c3b;
    color: #fff;
    border-radius: 100px;
    padding: 10px 15px;
    line-height: 14px;
    width: auto;
    text-align: center;
}

.tribunales2 a.btn:hover{
    color: #fff;
	background-color: #3f51b5;

}
.tribunales2.active a.btn{
	background-color: #3f51b5;
	color: #FFF;

}
.tribunales2 ul.sub{
	margin: 0;
    list-style: none;
    padding: 10px 20px;
    background-color: #bfcdee;
    border-radius: 20px;
    position: absolute;
    bottom: 50px;
    left: -16%;
    z-index: 99;
    display: none;
	max-height: 300px;
	overflow: scroll;
}
.tribunales2.active ul.sub{
	display: block;
}
.tribunales2 ul.sub li{
	text-align: left;
    padding: 0;
    white-space: nowrap;
    min-width: 280px;
}
.tribunales2 ul.sub li,
.tribunales2 ul.sub li a{
	font-size: 11px;
    color: #666;
}

.corte .icon.icon-corte{
	width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: rgba(63,81,181,.2);
    margin: 50px auto 0;
}
.corte .icon.icon-corte img{
	width: 60%;
    display: inline-block;

}
.corte .icon.icon-corte span{
    font-size: 32px;
    color: #FFFF;
    font-weight: bolder;
	text-shadow: 2px 1px 6px #666666;
	margin-top: 9px;

}
.corte p{
	color: #666;
    font-size: 14px;
    line-height: 18px;
    margin: 5px auto;
	max-width: 100%;
}
.corte h3{
    font-size: 28px;
	font-weight: bolder;
	text-align: center;
    line-height: 18px;
    margin: 15px auto;
}
.cifras .icon.icon-cifras{
	width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: rgba(63,81,181,.2);
    margin: 50px auto 0;
}
.cifras .icon.icon-cifras img{
	width: 60%;
    display: inline-block;
    margin-top: -20%;
}
.cifras .icon.icon-cifras span{
    font-size: 32px;
    color: #FFFF;
    font-weight: bold;
	text-shadow: 2px 1px 6px #666666;

}
.cifras p{
	color: #666;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin: 5px auto;
    max-width: 100px;
}
</style>
