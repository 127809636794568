<template>
  <div>
    <div v-if="Cargando">
      <zingchart ref="chart" :data="chartData"  />
    </div>
    <div v-if="!Cargando"><br>
      <div class="col-md-2"></div>
        <div class="col-md-8">
          <img src="@/assets/img/warning.png" width="200px" height="200px" style="opacity: 0.5">
          Los datos del año seleccionado no se encuentran disponibles.
        </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    top: {
      type: Number,
      default: 10,
    },
    title: {
      type: String,
    },
    total: {
      type: Number,
    },
    total2: {
      type: Number,
    },
    total3: {
      type: Number,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    leyenda: {
      type: Array,
      default: () => [],
    },
    tipGraf: {
      type: String,
    },
  },
  mounted() {
    console.clear();
  },
  computed: {
    chartData() {
      console.clear();
      return {
        type: 'ring',
        title: {
          text: '',
          align: 'right',
          fontColor: '#616161',
        },
        legend: {
          text: '%t<br>',
          borderWidth: '0px',
          header: {
            align: 'right',
            bold: true,
            fontColor: '#616161',
            fontSize: '13px',
          },
          footer: {
            text: 'Total ' + this.leyenda[1] + ': ' + Math.round(this.total3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '<br>Total ' + this.leyenda[0] + ': ' + Math.round(this.total2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '<br>Total: ' + Math.round(this.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
            align: 'right',
            bold: true,
            fontColor: '#616161',
            fontSize: '13px',
            align: 'right',
            padding: '10px',
            borderRadius: '3px',
            backgroundColor: '#2196f3',
            fontColor: '#fff',
            width: '175px',
          },
          item: {
            align: 'right',
            padding: '10px',
            borderRadius: '3px',
            backgroundColor: '#2196f3',
            fontColor: '#fff',
            width: '225px',
          },
          markerOff: {
            alpha: 0.2,
          },
          toggleAction: 'remove',
          verticalAlign: 'middle',
        },
        plot: {
          valueBox: {
            text: '%npv%',
            decimals: 2,
            fontColor: '#fff',
            placement: 'in',
          },
          animation: {
            effect: 'ANIMATION_EXPAND_VERTICAL',
            method: 'ANIMATION_BACK_EASE_OUT',
            onLegendToggle: false,
            sequence: 'ANIMATION_BY_PLOT',
          },
          decimals: 0,
          detach: false,
          refAngle: 270,
          thousandsSeparator: '.',
          dataYear: this.leyenda,
          sliceStart: '40%',
        },
        scale: {
          sizeFactor: 0.85,
        },
        tooltip: {
          text: '%t<br>%data-year<br>%v',
          align: 'right',
          bold: true,
          borderRadius: '3px',
          fontColor: '#fff',
          placement: 'node:out',
        },
        series: this.series,
      };
    },
    Cargando() {
      this.loading = true;
      if (Array.isArray(this.series) && this.series.length) {
        this.loading = true;
      } else {
        this.loading = false;
      }
      return this.loading;
      console.clear();
    },
  },
  data() {
    return {
      loading: true,
      t: '',
      c: [],
      s: [],
      seriesaux: [],
    };
  },
};
</script>
