<template>
<div>
<div class="box_mode content_wrapper">
    <div class="home_area">
      <div class="row align-items-center">
        <div class="col-md-1"></div>
        <div class="col-md-5">
          <div class="row">
            <p class="mb-text" style="white-space: pre-line;" v-html="notas"></p>
          </div>
        </div>
        <div class="col-md-5">
          <img :src="require('@/assets/img/temp/'+icono)" class="img-fluid" alt="">
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
</div>
</div>
</template>
<script>
import {mapState, mapActions} from 'vuex';
export default {
  props: {
    tipo: {
      type: String,
    },
    anio: {
      type: String,
    },
    cgraf: {
      type: Array,
      default: () => [],
    },
    cort: {
      type: String,
    },
    trib: {
      type: String,
    },
    comp: {
      type: String,
    },
    texto: {
      type: Array,
      default: () => [],
    },
    notas: {
      type: String,
    },
    icono: {
      type: String,
    },
  },
  methods: {
    ...mapActions([
      'actubicacionMaps',
      'categoriasM',
      'seriesM',
      'actcantgraficos',
    ]),
  },
  mounted() {
    this.loading = true;
    this.categorias = [];
    this.categorias2 = [];
    this.series = [];
    this.series2 = [];
    this.errCant = 0;
  },
  components: {},
  data() {
    return {
      loading: false,
      download: false,
      calendario: 1,
      categorias: [],
      txt_sel: '',
      series: [],
      sec: [],
      titulo: [],
      tipGraf: '',
      totalG: 0,
      cantG: 0,
      promG: 0,
      errCant: 0,
      seccionG: [],
      anios_select: [],
      viewEvent: {
        type: '',
        percentInView: 0,
        percentTop: 0,
        percentCenter: 0,
      },
    };
  },
  computed: {
    ...mapState([
      'ubicacionmaps',
      'categoriasC',
      'seriesC',
      'cantGraficos',
      'ipapi',
      'ipact',
      'anioI',
    ]),
  },
};
</script>
