<template>
  <div>
    <MapsCifras></MapsCifras>
  </div>
</template>
<script>
import MapsCifras from '@/components/MapsCifras.vue';
import {
  mapState,
  mapActions,
} from 'vuex';
export default {
  methods: {
    ...mapActions([
      'actubicacionMaps',
      'actjuzgados',
      'actcortes',
      'cifras',
      'actkeyNum',
    ]),
    UbiTerrMaps: function(a) {
      this.partes = a.split('-');
      this.ubicacion = this.partes[1];
    },
  },
  mounted() {
    // this.actubicacionMaps(this.$route.params.ubicacion.split('-'))
  },
  components: {
    MapsCifras,
  },
  data() {
    return {
      ubicacion: 0,
      x: [],
      selectedComponent: 'DosGraficos',
    };
  },
  computed: {
    ...mapState([
      'ubicacionmaps',
      'juzgados',
      'cortes',
      'cifras',
      'keyNum',
    ]),
  },
};
</script>
<style src="vue-slim-tabs/themes/default.css"></style>
<style>.header {
  background-color: #bfcdee;
  padding: 8px 10px;
  border-radius: 50px;
  margin-bottom: 20px;
}

h4 {
  color: #3f51b5;
  font-size: 22px;
  margin-bottom: 0;
}

select {
  border: 2px solid #3f51b5;
  background-color: #FFF;
  color: #666;
  border-radius: 100px;
}
</style>
