<template>
  <div>
    <div class="box_mode content_wrapper">
      <br>
      <br>
      <br>
      <br>
      <div class="home_area">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section_title animated a_fu">
                <div class="h1">Corporación Administrativa del Poder Judicial</div>
                <p class="text-center">Es una institución al servicio de los tribunales de justicia, administrando los recursos humanos, físicos, financieros y tecnológicos del Poder Judicial.</p>
              </div>
            </div>
          </div>
                    <div class="row">
            <div class="col-md-2 text-left" style="">
                <div class="feature_dsc animated a_fu">
                    <div class="clearfix">
                        <h4><span style="font-weight: 100; color: #00bcd4; font-style: italic;">Visión</span></h4>
                    </div>
                    <div class="feature_txt">
                        <p>Organización de personas dedicadas a brindar un servicio de excelencia a los tribunales de justicia.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-8 text-center" style="">
              <div class="corte row justify-content-center">
                <div class="col-md-12">
                  <div class="col-md-3"></div>
                  <div class="col-md-3 text-center" v-if="cifprinc[0]">
                    <div class="icon icon-corte">
                      <img :src="cifprinc[0].ICONO" class="img" style="width:95px; height:95px; margin-top: -20%;">
                        <h4>{{ cifprinc[0].TEXTO }}</h4>
                    </div>
                    <p>{{ cifprinc[0].VALOR }}</p>
                  </div>
                  <div class="col-md-3 text-center" v-if="cifprinc[1]">
                    <div class="icon icon-corte">
                      <img :src="cifprinc[1].ICONO" class="img" style="width:130px; height:95px; margin-top: -20%;">
                        <a class="numerogrande">
                          <countTo :startVal='0' :endVal='parseInt(cifprinc[1].VALOR)' :duration='2500'  separator="."></countTo>
                        </a>
                        <h4>{{ cifprinc[1].TEXTO }}</h4>
                    </div>
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </div><br>
              <div class="cifras row justify-content-center">
                <div class="col-md-2"></div>
                <div class="col-md-8" v-if="cifras && cifras.length">
                  <div v-for="cifra of cifras" v-bind:key="cifra + '' + cifra.TEXTO">
                    <div :class="cifra.CLASE + ' text-center'">
                      <div class="circulo3 feature_img animated a_fu">
                        <img :src="cifra.ICONO"  alt="" style="display: inline-block;margin-top: -15%;"><br>
                        <a class="numerogrande"><span v-if="cifra.TEXTO == 'Ley de Presupuestos'">M$ </span>
                          <countTo :startVal='0' :endVal='parseInt(cifra.VALOR)' :duration='2500'  separator="."></countTo>
                        </a>
                      </div>
                      <h3>{{ cifra.TEXTO }}</h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-2"></div>
              </div>
              <div class="col-md-12">
                <div class="single_feature">
                    <div class="clearfix">
                        <h4><span style="font-weight: 100; color: #00bcd4; font-style: italic;">Valores</span></h4>
                    </div>
                    <div class="feature_txt">
                        <div class="feature_txt">
                          <p>Respeto por las personas.</p>
                          <p>Integridad y transparencia.</p>
                          <p>Eficiencia.</p>
                          <p>Gestión orientada a las necesidades del Poder Judicial y la comunidad.</p>
                          <p>Innovación y sustentabilidad.</p>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 animated a_fu text-right" style="">
                <div class="single_feature">
                    <div class="clearfix">
                        <h4><span style="font-weight: 100; color: #00bcd4; font-style: italic;">Misión</span></h4>
                    </div>
                    <div class="sf_inner">
                        <p>Ser una organización técnica, conocida, validada y legitimada para el Poder Judicial y la comunidad.</p>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class=" mt-4">
            <div class="mapa d-flex">
              <div class="sector" v-bind:class="{'active':(ubmaps[0] =='Finanzas')}" v-on:click="UbiCapj('Finanzas')">
                <span class="name">Finanzas y Presupuestos</span>
                <span class="icon icon-cobranza"></span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[0] =='RRHH')}" v-on:click="UbiCapj('RRHH')">
                <span class="name">Recursos Humanos</span>
                <span class="icon icon-rrhh"></span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[0] =='Administracion')}" v-on:click="UbiCapj('Administracion')">
                <span class="name">Administración y Servicios</span>
                <span class="icon icon-admin"></span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[0] =='Apoyo')}" v-on:click="UbiCapj('Apoyo')">
                <span class="name">Unidades de Apoyo a los Tribunales</span>
                <span class="icon icon-apoyo"></span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[0] =='Infraestructura')}" v-on:click="UbiCapj('Infraestructura')">
                <span class="name">Infraestructura</span>
                <span class="icon icon-infra"></span>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  <div class="brand_area sp100 animated a_fu" id="territorio-result">
    <CorteApeTerr
      :tabs="tabs"
      :cgraf="cgraf"
      :secc="secc"
      :cort="'0'"
      :comp="ubicacionmaps[0]"
      :texto="texto"
      :titulo="titulo"
      :subtitulo="'Seleccione el ítem para revisar las cifras de ' + titulo"
      :key="keyCapj"
    >
    </CorteApeTerr>
    <br>
    <br>
  </div>
  <input v-model="ubicacionmaps" style="display: none">
</div>
</template>
<script>
import countTo from 'vue-count-to';
import CorteApeTerr from '@/components/CorteApeTerr.vue';
import {
  mapState,
  mapActions,
} from 'vuex';
export default {
  methods: {
    ...mapActions([
      'actubicacionMaps',
      'actcifras',
      'acttexto',
      'actmodulos',
      'actanioi',
    ]),
    UbiCapj: function(a) {
      this.titulo = (a == 'RRHH') ? 'Recursos Humanos' : (a == 'Administracion') ? 'Administración y Servicios' : (a == 'Apoyo') ? 'Unidades de Apoyo a los Tribunales' : (a == 'Finanzas') ? 'Finanzas y Presupuestos' : (a == 'Infraestructura') ? 'Infraestructura' : a;
      if (typeof a === 'object') {
        this.ubmaps = [];
        this.ubmaps = a;
      } else {
        this.ubmaps = [];
        this.partes = a.split('-');
        this.ubmaps = this.partes;
      }
      this.keyCapj += 1;
      this.cgraf = [];
      this.axios.get(this.ipapi + 'modulos/0/' + this.ubmaps[0] + '/0')
          .then((response) => {
            this.m = [];
            this.tabs = [];
            this.secc = [];
            this.m.push(JSON.parse(response.data[0].GRAFICOS));
            this.tabs = this.m[0].TEXTO;
            this.secc = this.m[0].SECCION;
            for (const i in this.secc) {
              this.cgraf = [];
              this.axios.get(this.ipapi + 'textos/capj/' + this.secc[i] + '/' + this.ubmaps[0])
                  .then((response) => {
                    this.cgraf.push(response.data);
                  })
                  .catch(function(error) {
                    console.log(error);
                  });
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      this.axios.get(this.ipapi + 'textos/capj/' + this.ubmaps[0] + '/0')
          .then((response) => {
            this.acttexto(response.data);
          })
          .catch(function(error) {
            console.log(error);
          });
    },
  },
  components: {countTo, CorteApeTerr},
  mounted() {
    this.$watch(
    	() => {
    	},
        (newVal, oldVal) => {
        },
    );
    console.log('Component mounted. test');
    this.ubmaps = [];
    // this.ubmaps = this.$route.params.ubicacion.split('-')
    this.ubmaps[0] = 'Infraestructura';
    this.axios.get(this.ipapi + 'cifras_clave/capj/principal/' + (this.anioI === '' ? (new Date().getFullYear()) - 1 : this.anioI))
        .then((response) => {
          this.cifprinc = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    this.axios.get(this.ipapi + 'cifras_clave/capj/datos/' + (this.anioI === '' ? (new Date().getFullYear()) - 1 : this.anioI))
        .then((response) => {
          this.actcifras(response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
  },
  watch: {
    'deep': true,
  	'ubicacionmaps': function(newVal, oldVal) {
      this.UbiCapj(newVal);
    },
  },
  data() {
    return {
      tabs: [],
      cgraf: [],
      secc: [],
      cifprinc: [],

      ubmaps: [],
      keyCapj: 0,
      titulo: '',
    };
  },
  computed: {
    ...mapState([
      'ubicacionmaps',
      'cifras',
      'texto',
      'modulos',
      'anioI',
      'ipapi',
    ]),
  },
};
</script>
