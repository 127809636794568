<template>
  <div>
    <zingchart ref="chart" :data="chartData"  />
  </div>
</template>

<script>
export default {
  props: {
    top: {
      type: Number,
      default: 10,
    },
    title: {
      type: String,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    categories2: {
      type: Array,
      default: () => [],
    },
    series2: {
      type: Array,
      default: () => [],
    },
    categories3: {
      type: Array,
      default: () => [],
    },
    series3: {
      type: Array,
      default: () => [],
    },
    categories4: {
      type: Array,
      default: () => [],
    },
    series4: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Array,
      default: () => [],
    },
    seccionG: {
      type: String,
    },
    tipGraf: {
      type: String,
    },
  },
  mounted() {
    console.clear();
  },
  computed: {
    chartData() {
      if (this.seccionG == 'becas') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.t = 'Cantidad de Becas ' + this.categories[0] + '<br> %v';
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.t1 = 'Cantidad de Becas ' + this.categories2[0] + '<br> %v';
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.d = 0;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.t = 'Porcentaje ' + this.categories[0] + '<br> %v %';
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.t1 = 'Porcentaje ' + this.categories2[0] + '<br> %v %';
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.d = 2;
      }
      console.clear();
      return {
        type: 'mixed',
        title: {
          text: this.title,
        },
        plotarea: {
          'margin': 'dynamic',
          'margin-top': '50px',
        },
        plot: {
          'barsOverlap': '50%',
          'overlap': false,
          'alpha': 0.4,
          'backgroundColor': '#fc9c3b',
          'valueBox': {
            'overlap': false,
            'fontColor': '#000',
            'angle': '-45',
            'fontSize': '13px',
            'placement': 'top',
            'thousands-separator': '.',
            'decimals-separator': ',',
            'decimals': 0,
          },
          'animation': {
            delay: '2000',
            effect: '4',
            method: '5',
            speed: 'ANIMATION_FAST',
            sequence: 'ANIMATION_BY_PLOT',
          },
          'borderRadius': '0px 0px 0px 0px',
        },
        scaleX: {
          'item': {
            fontSize: '14px',
          },
          // eslint-disable-next-line max-len
          'labels': [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
          'lineColor': '#DDD',
          'items-overlap': true,
          'tick': {
            visible: true,
          },
          'max-items': 999,
          'thousands-separator': '.',
          'decimals': 0,
        },
        scaleX2: {
          // eslint-disable-next-line max-len
          'labels': [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
          'thousands-separator': '.',
          'decimals': 0,
        },
        scaleY: {
          'values': '0:' + this.series[5] * 1.50 + ':' + this.series[5] / 5,
          'guide': {
            visible: false,
          },
          'item': {
            padding: '5px',
            fontColor: '#333',
          },
          'lineColor': '#db0a0a',
          'lineWidth': '3px',
          'tick': {
            lineColor: '#db0a0a',
            lineWidth: '3px',
            placement: 'outer',
            size: '8px',
          },
          'thousands-separator': '.',
          'decimals': 0,
        },
        scaleY2: {
          'thousands-separator': '.',
          'decimals': 0,
        },
        tooltip: {
          text: '%kl : %v',
          padding: '10px',
          backgroundColor: '#fff',
          borderColor: '#333',
          borderRadius: '5px',
          borderWidth: '1px',
          fontColor: '#444',
          fontSize: '14px',
        },
        series: [
          {
            type: 'bar',
            text: '',
            values: this.series,
            tooltip: {
              'text': this.categories[0] + '<br>%v',
              'thousands-separator': '.',
              'decimals': 0,
            },
            backgroundColor: '#595959 #777',
            hoverState: {
              visible: false,
            },
            zIndex: 1,
          },
          {
            type: 'bar',
            text: '',
            values: this.series2,
            tooltip: {
              'text': this.categories2[0] + '<br>%v',
              'thousands-separator': '.',
              'decimals': 0,
            },
            backgroundColor: '#db0a0a #ed0909',
            hoverState: {
              visible: false,
            },
            zIndex: 2,
          },
          {
            'type': 'line',
            'line-width': 5,
            'text': '',
            'values': this.series3,
            'tooltip': {
              text: this.t,
            },
            'valueBox': {
              'fontColor': '#000',
              'fontSize': '13px',
              'placement': 'bottom',
              'thousands-separator': '.',
              'decimals-separator': ',',
              'decimals': this.d,
            },
            'aspect': 'spline',
            'lineColor': '#07be07',
            'marker': {
              backgroundColor: '#07be07',
              borderColor: 'fff',
              borderWidth: '5px',
              size: '6px',
            },
            'scales': 'scale-x-2, scale-y-2',
            'zIndex': 4,
          },
          {
            'type': 'line',
            'line-width': 5,
            'text': '',
            'values': this.series4,
            'tooltip': {
              text: this.t1,
            },
            'valueBox': {
              'fontColor': '#000',
              'fontSize': '13px',
              'placement': 'bottom',
              'thousands-separator': '.',
              'decimals-separator': ',',
              'decimals': this.d,
            },
            'aspect': 'spline',
            'lineColor': '#0790be',
            'marker': {
              backgroundColor: '#0790be',
              borderColor: 'fff',
              borderWidth: '5px',
              size: '6px',
            },
            'scales': 'scale-x-2, scale-y-2',
          },
        ],
      };
    },
  },
  data() {
    return {
      t: '',
      t1: '',
      d: 0,
      c: [],
      s: [],
      seriesaux: [],
    };
  },
};
</script>
