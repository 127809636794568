<template>
<div class="box_mode content_wrapper">
      <header class="header-area fixed_header">
          <div class="container">
              <div class="row">
                  <div class="col-md-2 col-xs-3">
                      <a href="/Inicio" class="logo">
                          <img src="@/assets/img/logo.png" height="70px" alt="Poder Judicial">
                      </a>
                  </div>
                  <div class="col-md-10 col-xs-9">
                      <nav class="navbar">
                        <!-- <div class="container-fluid showup-xs">
                          <div class="navbar-header">
                            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                              <span class="icon-bar"></span>
                              <span class="icon-bar"></span>
                              <span class="icon-bar"></span>
                            </button>
                          </div>
                          <div class="collapse navbar-collapse" id="myNavbar">
                            <ul class="nav navbar-nav">
                              <li v-for="anio_sel in anios_select" v-bind:key="anio_sel.VALOR" v-on:click="aniov(anio_sel.VALOR)"><router-link to="/Inicio"><span>{{ anio_sel.VALOR }}</span></router-link></li>
                            </ul>
                          </div>
                        </div> -->
                          <div class="menu showup-xs" style="text-align: center;">
                              <a class="dropdown-toggle" data-toggle="dropdown" href="#"><span v-on:click="aniov(max_anio, max_text_anio)">Periodo {{max_text_anio}}</span>
                              <span class="caret"></span></a>
                              <ul class="dropdown-menu">
                                <li v-for="anio_sel in anios_select" v-bind:key="anio_sel.VALOR" v-on:click="aniov(anio_sel.VALOR, anio_sel.TEXTO)"><router-link to="/Inicio"><span>{{ anio_sel.TEXTO }}</span></router-link></li>
                              </ul>
                          </div>
                          <ul class="menu hidden-xs hidden-sm">
                              <li>
                                  <router-link :to="'/Inicio#periodo' + max_text_anio"> <span v-on:click="aniov(max_anio, max_text_anio)">Periodo {{max_text_anio}}</span></router-link >
                                  <ul class="sub-menu">
                                      <li v-for="anio_sel in anios_select" v-bind:key="anio_sel.VALOR" v-on:click="aniov(anio_sel.VALOR, anio_sel.TEXTO)"><router-link to="/Inicio"><span>{{ anio_sel.TEXTO }}</span></router-link></li>
                                  </ul>
                              </li>

                              <li>
                                <router-link :to="{ name: 'CorteSuprema'}">
                                  Corte Suprema
                                </router-link>
                              </li>
                              <li><a class="sub-siblings">Territorios</a>
                                  <ul class="sub-menu">
                                      <li v-on:click="ubimaps('10-Arica')"><router-link to="/Territorio/10-Arica"><span>Arica</span></router-link></li>
                                      <li v-on:click="ubimaps('11-Iquique')"><router-link to="/Territorio/11-Iquique"><span>Iquique</span></router-link></li>
                                      <li v-on:click="ubimaps('15-Antofagasta')"><router-link to="/Territorio/15-Antofagasta"><span>Antofagasta</span></router-link></li>
                                      <li v-on:click="ubimaps('20-Copiapó')"><router-link to="/Territorio/20-Copiapó"><span>Copiapó</span></router-link></li>
                                      <li v-on:click="ubimaps('25-La Serena')"><router-link to="/Territorio/25-La serena"><span>La Serena</span></router-link></li>
                                      <li v-on:click="ubimaps('30-Valparaíso')"><router-link to="/Territorio/30-Valparaíso"><span>Valparaíso</span></router-link></li>
                                      <li v-on:click="ubimaps('90-Santiago')"><router-link to="/Territorio/90-Santiago"><span>Santiago</span></router-link></li>
                                      <li v-on:click="ubimaps('91-San Miguel')"><router-link to="/Territorio/91-San Miguel"><span>San Miguel</span></router-link></li>
                                      <li v-on:click="ubimaps('35-Rancagua')"><router-link to="/Territorio/35-Rancagua"><span>Rancagua</span></router-link></li>
                                      <li v-on:click="ubimaps('40-Talca')"><router-link to="/Territorio/40-Talca"><span>Talca</span></router-link></li>
                                      <li v-on:click="ubimaps('45-Chillán')"><router-link to="/Territorio/45-Chillán"><span>Chillán</span></router-link></li>
                                      <li v-on:click="ubimaps('46-Concepción')"><router-link to="/Territorio/46-Concepción"><span>Concepción</span></router-link></li>
                                      <li v-on:click="ubimaps('50-Temuco')"><router-link to="/Territorio/50-Temuco"><span>Temuco</span></router-link></li>
                                      <li v-on:click="ubimaps('55-Valdivia')"><router-link to="/Territorio/55-Valdivia"><span>Valdivia</span></router-link></li>
                                      <li v-on:click="ubimaps('56-Puerto Montt')"><router-link to="/Territorio/56-Puerto Montt"><span>Puerto Montt</span></router-link></li>
                                      <li v-on:click="ubimaps('60-Coyhaique')"><router-link to="/Territorio/60-Coyhaique"><span>Coyhaique</span></router-link></li>
                                      <li v-on:click="ubimaps('61-Punta Arenas')"><router-link to="/Territorio/61-Punta Arenas"><span>Punta Arenas</span></router-link></li>
                                  </ul>
                              </li>
                              <li><a class="sub-siblings">Materias</a>
                                  <ul class="sub-menu">
                                      <li v-on:click="UbiCompt('Familia')"><router-link to="/Competencias/Familia"><span>Familia</span></router-link></li>
                                      <li v-on:click="UbiCompt('Civil')"><router-link to="/Competencias/Civil"><span>Civil</span></router-link></li>
                                      <li v-on:click="UbiCompt('Laboral')"><router-link to="/Competencias/Laboral"><span>Laboral</span></router-link></li>
                                      <li v-on:click="UbiCompt('Cobranza')"><router-link to="/Competencias/Cobranza"><span>Cobranza</span></router-link></li>
                                      <li v-on:click="UbiCompt('Garantia')"><router-link to="/Competencias/Garantia"><span>Garantía</span></router-link></li>
                                      <li v-on:click="UbiCompt('Top')"><router-link to="/Competencias/Top"><span>Oral en lo Penal</span></router-link></li>
                                  </ul>
                              </li>
                              <li><a class="sub-siblings">Datos de Interés</a>
                                  <ul class="sub-menu">
                                      <li v-on:click="UbiEst('Sentencias')"><router-link to="/Estadisticas/Sentencias"><span>Sentencias, Penas y Beneficios</span></router-link></li>
                                      <li v-on:click="UbiEst('Expulsiones')"><router-link to="/Estadisticas/Expulsiones"><span>Expulsiones</span></router-link></li>
                                      <li v-on:click="UbiEst('Sanciones')"><router-link to="/Estadisticas/Sanciones"><span>Sanciones RPA</span></router-link></li>
                                      <li v-on:click="UbiEst('Boletines')"><router-link to="/Estadisticas/Boletines"><span>Boletines Estadísticos</span></router-link></li>
                                  </ul>
                              </li>
                              <li><a class="sub-siblings">CAPJ</a >
                                  <ul class="sub-menu">
                                      <li v-on:click="UbiCapj('Finanzas')"><router-link to="/Capj/Finanzas"><span>Finanzas</span></router-link></li>
                                      <li v-on:click="UbiCapj('RRHH')"><router-link to="/Capj/RRHH"><span>Recursos Humanos</span></router-link></li>
                                      <li v-on:click="UbiCapj('Administracion')"><router-link to="/Capj/Administracion"><span>Administración</span></router-link></li>
                                      <li v-on:click="UbiCapj('Apoyo')"><router-link to="/Capj/Apoyo"><span>Unidades de Apoyo</span></router-link></li>
                                      <li v-on:click="UbiCapj('Infraestructura')"><router-link to="/Capj/Infraestructura"><span>Infraestructura</span></router-link></li>
                                  </ul>
                              </li>
                              <li class="nav-item nav-item-icon" style="width: 48px; height: 43.5px;">
                                <router-link to="/Descargas" class="nav-link nav-link-icon">
                                  <img src="@/assets/img/descargas.png" class="img" alt="Descargas" title="Descargas">
                                </router-link>
                              </li>
                              <li class="nav-item nav-item-icon" style="margin-left:-20px; width: 48px; height: 43.5px;">
                                <router-link to="/Legales">
                                  <img src="@/assets/img/glosario.png" class="img" alt="Textos Legales e Información" title="Textos Legales e Información">
                                </router-link>
                              </li>
                              <li></li>
                          </ul>
                      </nav>
                  </div>
              </div>
          </div>
      </header>
    </div>
</template>

<script>
import {
  mapActions,
  mapState,
} from 'vuex';
export default {
  methods: {
    ...mapActions([
      'actjuzgados',
      'actcortes',
      'actubicacionMaps',
      'actanioi',
    ]),
    UbiCompt: function(a) {
      this.partes = a.split('-');
      this.actubicacionMaps(this.partes);
      console.clear();
    },
    UbiCapj: function(a) {
      this.partes = a.split('-');
      this.actubicacionMaps(this.partes);
      console.clear();
    },
    UbiEst: function(a) {
      this.partes = a.split('-');
      this.actubicacionMaps(this.partes);
      console.clear();
    },
    ubimaps: function(a) {
      this.partes = a.split('-');
      this.actubicacionMaps(this.partes);
      console.clear();
    },
    aniov: function(a, b) {
      if (a >= 2018) {
        this.actanioi(a);
        this.max_text_anio = b;
        this.axios.get(this.ipapi + 'cifras_clave/portada/juzgados/' + a)
            .then((response) => {
              this.actjuzgados(response.data);
            })
            .catch(function(error) {
              console.log(error);
            });

        this.axios.get(this.ipapi + 'cifras_clave/portada/organigrama/' + a)
            .then((response) => {
              this.actcortes(response.data);
            })
            .catch(function(error) {
              console.log(error);
            });
      } else {
        window.open('https://www2.pjud.cl/poder-judicial-en-numeros', '_blank');
      }
      console.clear();
    },
    Descarga_glo: function() {
      this.axios.get(this.ipact + '/descargas/glosario.pdf', {responseType: 'blob'})
          .then(({data}) => {
            const blob = new Blob([data], {type: 'application/pdf'});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'glosario.pdf';
            link.click();
          })
          .catch(function(error) {
            console.log(error);
          });
      console.clear();
    },
  },
  components: {},
  name: 'MenuElement',
  computed: {
    ...mapState([
      'anioI',
      'ubicacionmaps',
      'ipapi',
      'ipact',
    ]),
  },
  mounted() {
    // alert(this.$router.currentRoute.name)
    this.axios.get(this.ipapi + 'select_anios/Principal')
        .then((response) => {
          this.anios_select = response.data;
          this.max_anio = this.anios_select[0].VALOR;
          this.max_text_anio = this.anios_select[0].TEXTO;
        })
        .catch(function(error) {
        });
    console.clear();
  },
  data() {
    return {
      anios_select: [],
      max_anio: 0,
      max_text_anio: '',
      base_url: window.location.protocol + '//' + window.location.host,
      modulo: this.$router.currentRoute.name,
    };
  },

};
</script>
<style>
li.nav-item.nav-item-icon{
padding: 0;
background-color: #2196f3;
vertical-align: middle;
}
li.nav-item.nav-item-icon a.nav-link.nav-link-icon{
    padding: 6px 10px;
}
ul.navbar-nav li.nav-item.nav-item-icon a.nav-link.nav-link-icon img{
    height: 18px;
    width: 20px;
}
</style>
