<template>
  <div>
    <zingchart ref="chart" :data="chartData"  />
  </div>
</template>

<script>
export default {
  props: {
    top: {
      type: Number,
      default: 10,
    },
    title: {
      type: String,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    categories2: {
      type: Array,
      default: () => [],
    },
    series2: {
      type: Array,
      default: () => [],
    },
    categories3: {
      type: Array,
      default: () => [],
    },
    series3: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Array,
      default: () => [],
    },
    tipGraf: {
      type: String,
    },
  },
  mounted() {
    console.clear();
  },
  computed: {
    chartData() {
      console.clear();
      return {
        type: 'mixed',
        title: {
          text: this.title,
        },
        plotarea: {
          'margin': 'dynamic',
          'margin-top': '50px',
        },
        plot: {
          'barsOverlap': '20%',
          'overlap': false,
          'alpha': 0.4,
          'backgroundColor': '#fc9c3b',
          'valueBox': {
            'overlap': true,
            'fontColor': '#000',
            'angle': '-45',
            'fontSize': '13px',
            'placement': 'top-in',
            'thousands-separator': '.',
            'decimals-separator': ',',
            'decimals': 0,
          },
          'animation': {
            delay: '2000',
            effect: '4',
            method: '5',
            speed: 'ANIMATION_FAST',
            sequence: 'ANIMATION_BY_PLOT',
          },
          'borderRadius': '0px 0px 0px 0px',
        },
        scaleX: {
          'item': {
            fontSize: '14px',
          },
          'labels': [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
          'lineColor': '#DDD',
          'items-overlap': false,
          'tick': {
            visible: true,
          },
          'max-items': 999,
        },
        scaleX2: {labels: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023]},
        scaleY: {
          'values': '0:' + this.series[3] * 1.50 + ':' + this.series[3] / 5,
          'guide': {
            visible: false,
          },
          'item': {
            padding: '5px',
            fontColor: '#333',
          },
          'lineColor': '#db0a0a',
          'lineWidth': '3px',
          'tick': {
            lineColor: '#db0a0a',
            lineWidth: '3px',
            placement: 'outer',
            size: '8px',
          },
          'thousands-separator': '.',
          'decimals': 0,
        },
        scaleY2: { },
        tooltip: {
          text: '%kl : %v',
          padding: '10px',
          backgroundColor: '#fff',
          borderColor: '#333',
          borderRadius: '5px',
          borderWidth: '1px',
          fontColor: '#444',
          fontSize: '14px',
        },
        series: [
          {
            type: 'bar',
            text: '',
            values: this.series,
            tooltip: {
              'text': this.categories[0] + '<br>%v',
              'thousands-separator': '.',
              'decimals': 0,
            },
            backgroundColor: '#595959 #777',
            hoverState: {
              visible: false,
            },
            zIndex: 1,
          },
          {
            type: 'bar',
            text: '',
            values: this.series2,
            tooltip: {
              'text': this.categories2[0] + '<br>%v',
              'thousands-separator': '.',
              'decimals': 0,
            },
            backgroundColor: '#db0a0a #ed0909',
            hoverState: {
              visible: false,
            },
            zIndex: 2,
          },
          {
            'type': 'line',
            'line-width': 5,
            'text': '',
            'values': this.series3,
            'tooltip': {
              text: 'Porcentaje ' + this.categories2[0] + '<br> %v %',
            },
            'valueBox': {
              'fontColor': '#000',
              'fontSize': '13px',
              'placement': 'bottom',
              'thousands-separator': '.',
              'decimals-separator': ',',
              'decimals': 2,
            },
            'aspect': 'spline',
            'lineColor': '#07be07',
            'marker': {
              backgroundColor: '#07be07',
              borderColor: 'fff',
              borderWidth: '5px',
              size: '6px',
            },
            'scales': 'scale-x-2, scale-y-2',
          },
        ],
      };
    },
  },
  data() {
    return {
      t: '',
      c: [],
      s: [],
      seriesaux: [],
    };
  },
};
</script>
