<template>
<div class="animated a_fu" id="fact">
                <div class="hero-area">
                    <div class="row">
                        <div class="col-md-3 counter_col clearfix funfact_area1">
                            <div class="single_fact">
                                <div class="numerogrande">Corte Suprema</div>
                                <div class="text-center">
                                    <p>Conoce las cifras relevantes de recursos que se tramitan en la Corte Suprema</p>
                                    <br>
                                </div>
                                <div class="default_btn">
                                    <router-link to="/CorteSuprema" class="ama-btn">Ver Corte Suprema</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 counter_col clearfix funfact_area2">
                            <div class="fun_content">
                                <div class="numerogrande">Territorios</div>
                                <p>Conoce las cifras relevantes de recursos que se tramitan en las Cortes de Apelaciones y Tribunales de 1ª Instancia del país</p>
                                <div class="default_btn" v-on:click="ubimaps('10-Arica')">
                                    <router-link to="/Territorio/10-Arica" class="ama-btn">Ver Territorios</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 counter_col clearfix funfact_area3">
                            <div class="fun_content">
                                <div class="numerogrande">Materias</div>
                                <p>Conoce las cifras relevantes de las causas que se tramitan ante los Tribunales de 1ª Instancia, desagregados por Cortes de Apelaciones</p>
                                <div class="default_btn" v-on:click="UbiCompt('Familia')">
                                    <router-link to="/Competencias/Familia" class="ama-btn">Ver Materias</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 counter_col clearfix funfact_area4">
                            <div class="fun_content">
                                <div class="numerogrande">CAPJ</div>
                                <p>Conoce las cifras relevantes de la Corporación Administrativa del Poder Judicial</p>
                                <br>
                                <div class="default_btn" v-on:click="UbiCapj('Finanzas')">
                                    <router-link to="/Capj/Finanzas" class="ama-btn">Ver CAPJ</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 <a href="javascript:" id="return-to-top"><img src="@/assets/img/chevron-up.png" width="30" style="margin-top:15px;margin-left:10px"></a>
            </div>
</template>
<script>
import {
  mapActions,
  mapState,
} from 'vuex';
export default {
  methods: {
    ...mapActions([
      'actjuzgados',
      'actcortes',
      'actubicacionMaps',
      'actanioi',
    ]),
    UbiCompt: function(a) {
      this.partes = a.split('-');
      this.actubicacionMaps(this.partes);
    },
    UbiCapj: function(a) {
      this.partes = a.split('-');
      this.actubicacionMaps(this.partes);
    },
    ubimaps: function(a) {
      this.partes = a.split('-');
      this.actubicacionMaps(this.partes);
    },
  },
  name: 'FooterElement',

};
</script>
<style>
.default_btn a{
    font-size: 15px;
}
</style>
