import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store.js';
import zingchartVue from 'zingchart-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import checkView from 'vue-check-view';
import * as Tabs from 'vue-slim-tabs';
import XLSX from 'xlsx';
import ZingGrid from 'zinggrid';
import VueAnalytics from 'vue-analytics';
import VueMeta from 'vue-meta';

import './assets/css/font-awesome.min.css';
import './assets/css/hover.min.css';
import './assets/css/pretty.photo.css';
import './assets/css/owl.carousel.css';
import './assets/css/icofont.css';
import './assets/css/animate.css';
import './assets/css/bootstrap.min.css';
import './assets/css/custom/style.css';
import './assets/css/custom/update_style.css';
import './assets/css/style.css';

Vue.component('zingchart', zingchartVue);
Vue.component('Zinggrid', ZingGrid);
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(checkView);
Vue.use(Tabs);
Vue.use(XLSX);
Vue.use(VueAnalytics, {
  id: 'UA-175201319-1',
  router,
});
Vue.use(VueMeta);

const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

window.vm = vm;

export default {
  metaInfo() {
    return {
      title: 'Poder Judicial en Números',
      meta: [
        {name: 'description', content: 'La iniciativa “Poder Judicial en Números”, corresponde a una plataforma de publicación de datos estadísticos, cuyo objeto es apertura la información pública del Poder Judicial, en formato legible, de modo de establecer una estrategia efectiva para la creación de valor público a través del análisis de información, tanto dentro como fuera de la institución. La apertura y transparencia judicial, ayudarán a mejorar el servicio de justicia, lo que tiene un resultado positivo en la vida cotidiana de las personas. Además, puede repercutir en la responsabilidad y la capacidad de las distintas unidades judiciales, para responder por sus resultados ante la sociedad y los demás poderes del Estado. En la plataforma, podrá encontrar información de la labor jurisdiccional a lo largo del país, en formato de Datos Abiertos, para el periodo comprendido entre el año 2015 – 2021. Las actualizaciones de información se realizarán de forma semestral'},
        {name: 'robots', content: 'index,follow'},
      ],
    };
  },
};

