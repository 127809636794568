<template>
  <div>
    <zing-grid
      header-auto-format="disabled"
      layout="row"
      gridlines="both"
      layout-controls="disabled"
      viewport-stop
      pager
      column-resizable-max-width=800
      column-resizable-persistent
      column-resizable
      page-size="15"
      theme="android"
      :data="chartData"
      :key="nuevo"
    >
    </zing-grid>
  </div>
</template>

<script>
export default {
  props: {
    top: {
      type: Number,
      default: 10,
    },
    title: {
      type: String,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      nuevo: [],
    };
  },
  mounted() {
    console.clear();
    this.nuevo = JSON.stringify(this.series);
  },
  computed: {
    chartData() {
      console.clear();
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.nuevo = [];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.nuevo = JSON.stringify(this.series);
      return this.nuevo;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
zg-caption h1 { font-size: 2rem; }
zg-caption p { font-size: 1rem; }
zg-head { font-size: 1.3rem; display: inline-block;}

zing-grid {
  display: inline-block;
  width: 100%;
}

td {
  text-align: center;
}
</style>
