<template>
    <div>
        <div class="container" id="features2">
            <div class="row">
                <div class="col-md-12">
                    <div class="header text-center" v-if="flag===0">
                        <h3 class="mt-2 mb-3">{{ titulo }}</h3>
                    </div>
                    <div class="container">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="feature_txt" v-if="texto && texto.length && secc[0] !== 'extracto'">
                            <p>{{ texto[0].TEXTO }} </p>
                          </div>
                        </div>
                      </div>
                      <br>
                      <div class="row features">
                        <div class="col-md-2"></div>
                        <div class="col-md-8" v-if="cifras && cifras.length">
                          <div v-for="cifra of cifras" v-bind:key="cifra + '' + cifra.TEXTO">
                            <div :class="cifra.CLASE + ' text-center'">
                              <div class="circulo3 feature_img animated a_fu">
                                <a :href="'#'+cifra.LINK" v-on:click="selectTab(cifra.LINK)">
                                  <img :src="cifra.ICONO"  alt="" style="width: 60%;display: inline-block;margin-top: -15%;"><br>
                                  <a class="numerogrande">
                                    <countTo :startVal='0' :endVal='parseInt(cifra.VALOR)' :duration='2500'  separator="."></countTo>
                                  </a>
                                </a>
                              </div>
                              <h3><a :href="'#'+cifra.LINK" v-on:click="selectTab(cifra.LINK)">{{ cifra.TEXTO }}</a></h3>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2"></div>
                      </div>
                    </div><br>
                    <p class="text-center small" v-if="flag===0"><i>{{ subtitulo }}</i></p>
                    <input v-model="CargaTabs" style="display: none">
                    <div v-if="tabulados && tabulados.length" class="box_mode content_wrapper">
                        <tabs>
                            <tab v-for="(tabulado, index) in tabulados" v-bind:key="tabulado" :title="tabulado" :id="tabulado">
                                <div class="row align-items-center">
                                  <br>
                                  <div class="col-md-1"></div>
                                  <div v-if="listaOrden[index]" class="col-md-10">
                                    <p class="mb-text" style="white-space: pre-line">
                                      {{ listaOrden[index][0].TEXTO }}
                                    </p>
                                  </div>
                                  <div class="col-md-1"></div>
                                </div>
                                <div v-if="listaOrden[index] && listaOrden[index][0].CANT_GRAFICO == '1' && listaOrden[index][0].SECCION == secc[index]">
                                    <div v-if="listaOrden[index] && listaOrden[index][0].GRAFICO_A == 'bar' || listaOrden[index][0].GRAFICO_A == 'hbar'">
                                      <Graficos
                                        :tipo="secc[index]"
                                        :anio="anioI"
                                        :cgraf="cgraf"
                                        :cort="cort"
                                        :trib="trib"
                                        :comp="comp"
                                        :notas="listaOrden[index][0].NOTAS_A"
                                        :key="keyGraficos">
                                      </Graficos>
                                    </div>
                                    <div v-if="listaOrden[index] && listaOrden[index][0].GRAFICO_A == 'tabla'">
                                      <Tablas
                                        :tipo="secc[index]"
                                        :anio="anioI"
                                        :cgraf="cgraf"
                                        :cort="cort"
                                        :trib="trib"
                                        :comp="comp"
                                        :notas="listaOrden[index][0].NOTAS_A"
                                        :key="keyGraficos">
                                      </Tablas>
                                    </div>
                                    <div v-if="listaOrden[index] && listaOrden[index][0].GRAFICO_A == 'mixed'">
                                      <Mixtos
                                        :tipo="secc[index]"
                                        :anio="anioI"
                                        :cgraf="cgraf"
                                        :cort="cort"
                                        :trib="trib"
                                        :comp="comp"
                                        :notas="listaOrden[index][0].NOTAS_A"
                                        :key="keyGraficos">
                                      </Mixtos>
                                    </div>
                                    <div v-if="listaOrden[index] && listaOrden[index][0].GRAFICO_A == 'piemix'">
                                      <TortaMix
                                        :tipo="secc[index]"
                                        :anio="anioI"
                                        :cgraf="cgraf"
                                        :cort="cort"
                                        :trib="trib"
                                        :comp="comp"
                                        :notas="listaOrden[index][0].NOTAS_A"
                                        :key="keyGraficos">
                                      </TortaMix>
                                    </div>
                                    <div v-if="listaOrden[index] && listaOrden[index][0].GRAFICO_A == 'doblepie'">
                                      <DobleTorta
                                        :tipo="secc[index]"
                                        :anio="anioI"
                                        :cgraf="cgraf"
                                        :cort="cort"
                                        :trib="trib"
                                        :comp="comp"
                                        :notas="listaOrden[index][0].NOTAS_A"
                                        :key="keyGraficos">
                                      </DobleTorta>
                                    </div>
                                    <div v-if="listaOrden[index] && listaOrden[index][0].GRAFICO_A == 'extracto' || listaOrden[index][0].GRAFICO_A == 'resumen'">
                                      <Textos
                                        :tipo="secc[index]"
                                        :anio="anioI"
                                        :cgraf="cgraf"
                                        :cort="cort"
                                        :trib="trib"
                                        :comp="comp"
                                        :notas="listaOrden[index][0].NOTAS_A"
                                        :key="keyGraficos"
                                        :icono="listaOrden[index][0].IMG_A">
                                      </Textos>
                                    </div>
                                </div>
                                <div v-if="listaOrden[index] && listaOrden[index][0].CANT_GRAFICO == '2' && listaOrden[index][0].SECCION == secc[index]">
                                    <DosGraficos
                                      :tipo="secc[index]"
                                      :anio="anioI"
                                      :cgraf="cgraf"
                                      :cort="cort"
                                      :trib="trib"
                                      :comp="comp"
                                      :notas="listaOrden[index][0].NOTAS_A"
                                      :key="keyDosGraficos">
                                    </DosGraficos>
                                </div>
                                <div v-if="listaOrden[index] && listaOrden[index][0].CANT_GRAFICO == '3' && listaOrden[index][0].SECCION == secc[index]">
                                    <TresGraficos
                                      :tipo="secc[index]"
                                      :anio="anioI"
                                      :cgraf="cgraf"
                                      :cort="cort"
                                      :trib="trib"
                                      :comp="comp"
                                      :indice="index"
                                      :listaOrden="listaOrden"
                                      :key="keyDosGraficos">
                                    </TresGraficos>
                                </div>
                            </tab>
                        </tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState, mapActions} from 'vuex';
import countTo from 'vue-count-to';
import DosGraficos from '@/components/DosGraficos.vue';
import TresGraficos from '@/components/TresGraficos.vue';
import Graficos from '@/components/Graficos.vue';
import Tablas from '@/components/Tablas.vue';
import Mixtos from '@/components/Mixtos.vue';
import TortaMix from '@/components/TortaMix.vue';
import DobleTorta from '@/components/DobleTorta.vue';
import Textos from '@/components/Textos.vue';

export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    cgraf: {
      type: Array,
      default: () => [],
    },
    secc: {
      type: Array,
      default: () => [],
    },
    cort: {
      type: String,
      default: '0',
    },
    trib: {
      type: String,
      default: '0',
    },
    comp: {
      type: String,
      default: '',
    },
    texto: {
      type: Array,
      default: () => [],
    },
    cifras: {
      type: Array,
      default: () => [],
    },
    titulo: {
      type: String,
      default: '',
    },
    subtitulo: {
      type: String,
      default: '',
    },
  },
  name: 'CorteApeTerr',
  methods: {
    ...mapActions([
      'categoriasM',
      'seriesM',
      'actubicacionMaps',
      'actsecciones',
      'acttabulaciones',
      'actcantgraficos',
    ]),
    selectTab: function(a) {
      if (a !== undefined) {
        $('.vue-tab').each(function() {
          const $this = $(this);
          if ($this.html() == a) {
            $this.trigger('click');
          }
        });
      }
      console.clear();
    },
    SelAnio: function(a) {
      this.anioI = event.target.value;
      this.keyGraficos += 1;
      this.keyDosGraficos += 1;
      console.clear();
    },
  },
  mounted() {
    if (this.$route.name == 'Competencias') {
      this.flag = 1;
    } else {
      this.flag = 0;
    }
    console.clear();
  },
  components: {countTo, DosGraficos, Graficos, Tablas, Mixtos, TortaMix, DobleTorta, TresGraficos, Textos},
  data() {
    console.clear();
    return {
      lista: [],
      keyDosGraficos: 0,
      keyGraficos: 0,
      selectedComponent: 'DosGraficos',
      flag: 0,
    };
  },
  computed: {
    ...mapState([
      'categoriasC',
      'seriesC',
      'ubicacionmaps',
      'secciones',
      'tabulaciones',
      'cantGraficos',
      'anioI',
    ]),
    CargaTabs() {
      this.tabulados = [];
      this.tabulados = this.tabs;
      console.clear();
    },
    listaOrden() {
      this.lista = [];
      const n = this.cgraf;
      const sortedButtons = Object.keys(n).sort(function(keyA, keyB) {
        return n[keyA][0].ORDEN - n[keyB][0].ORDEN;
      });
      for (var i in n) {
        this.lista.findIndex((x) => x[0].ORDEN == n[sortedButtons[i]][0].ORDEN) == -1 ? this.lista.push(n[sortedButtons[i]]) : console.log('');
      }
      console.clear();
      return this.lista;
    },
  },
};
</script>
