<template>
  <div>
    <CompCifras></CompCifras>
  </div>
</template>
<script>
import CompCifras from '@/components/CompCifras.vue';

import {
  mapState,
  mapActions,
} from 'vuex';
export default {
  methods: {
    ...mapActions([
      'actjuzgados',
      'actcortes',
    ]),
    UbiTerrMaps: function(a) {
      this.partes = a.split('-');
      this.ubicacion = this.partes[0];
    },
  },
  mounted() {
    console.log('Component mounted.');
    // this.partes = this.$route.params.ubicacion.split('-')
    // this.ubicacion = this.partes[0]
  },
  components: {
    CompCifras,
  },
  data() {
    return {
      ubicacion: 0,
      selectedComponent: 'DosGraficos',
    };
  },
  computed: {
    ...mapState([
      'juzgados',
      'cortes',

    ]),
  },
};
</script>
<style src="vue-slim-tabs/themes/default.css"></style>
<style>.header {
  background-color: #bfcdee;
  padding: 8px 10px;
  border-radius: 50px;
  margin-bottom: 20px;
}

h4 {
  color: #3f51b5;
  font-size: 22px;
  margin-bottom: 0;
}

select {
  border: 2px solid #3f51b5;
  background-color: #FFF;
  color: #666;
  border-radius: 100px;
}
</style>
