<template>
  <div>
    <div v-if="loading"> 
      <zingchart ref="chart" :data="chartData" height= '550' />
    </div>
    <div v-if="!loading"><br>
      <div class="col-md-2"></div>
        <div class="col-md-8">
          <img src="@/assets/img/warning.png" width="200px" height="200px" style="opacity: 0.5">
          Los datos del año seleccionado no se encuentran disponibles.
        </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    top: {
      type: Number,
      default: 10,
    },
    title: {
      type: String,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Array,
      default: () => [],
    },
    tipGraf: {
      type: String,
    },
    totalG: {
      type: Number,
      default: 0,
    },
    seccionG: {
      type: String,
    },
    cantG: {
      type: Number,
      default: 0,
    },
    promG: {
      type: Number,
      default: 0,
    },
    calendario: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    if (Array.isArray(this.series) && this.series.length) {
      if (Array.isArray(this.series[0].values) && this.series[0].values.length && this.series[0].values != '0' ) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
    console.clear();
  },
  computed: {
    chartData() {
      this.categorias = []
      this.categorias = this.categories
      if (this.tipGraf == 'hbar' && this.contador < 2){  
        this.categorias.push("");
        this.contador = this.contador + 1;
      }
      if (this.seccionG == 'duracion' || this.seccionG == 'duracionaud') {
        if (this.promG != 0) {
          this.t = 'Promedio: ' + parseInt(Math.round(this.promG)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        } else {
          this.t = 'Promedio: ' + parseInt((this.totalG / this.cantG)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        }
      } else if (this.seccionG == 'dotacion' || (this.seccionG == 'graficos' && this.totalG == 0) || (this.seccionG == 'graficos' && this.calendario == 0) || (this.seccionG == 'tramitacion' && this.calendario == 0) || (this.seccionG == 'ingresos' && this.calendario == 0) || (this.seccionG == 'terminos' && this.calendario == 0)) {
        this.t = '';
      } else {
        this.t = 'Total: ' + parseInt(this.totalG).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      if (screen.width < 992) {
        this.x = '10%';
        this.y = '80%';
        this.tamanoFuenteX = '0px';
        this.tamanoFuenteIn = '13px';
        this.TamanoOffset = screen.width - 150;
        this.Posicion = 'bottom-in';
      } else {
        this.x = '70%';
        this.y = '90%';
        this.tamanoFuenteX = '14px';
        this.tamanoFuenteIn = '0px';
        this.TamanoOffset = 0;
        this.Posicion = 'top';
      }
      console.clear();
      return {
        type: this.tipGraf,
        title: {
          text: this.title,
        },
        plotarea: {
          'margin': 'dynamic',
          'margin-top': '-50px',
          'margin-right': '-35px',
        },
        plot: {
          'alpha': 0.4,
          'backgroundColor': '#009DD9',
          'valueBox': [{
            'fontColor': '#000',
            'fontSize': '13px',
            'placement': this.Posicion,
            'offset-x': this.TamanoOffset,
            'font-weight': '900',
            'decimals': 0,
            'thousands-separator': '.',
          }, {
            'text': '%kv',
            'fontColor': '#808080',
            'fontSize': this.tamanoFuenteIn,
            'font-weight': 'lighter',
            'placement': 'bottom-in',
          }],
          'animation': {
            delay: '2000',
            effect: '4',
            method: '5',
            speed: 'ANIMATION_FAST',
            sequence: 'ANIMATION_BY_PLOT',
          },
          'borderRadius': '0px 5px 5px 0px',
        },
        scaleX: {
          'item': {
            fontSize: this.tamanoFuenteX,
          },
          'values': this.categorias,
          'lineColor': '#DDD',
          'items-overlap': true,
          'tick': {
            visible: false,
          },
          'max-items': 999,
        },
        scaleY: {
          'decimals': 0,
          'thousands-separator': '.',
        },
        legend: {
          align: 'center',
          layout: 'h',
          shadow: false,
          toggleAction: 'remove',
          verticalAlign: 'bottom',
        },
        labels: [
          {
            'text': this.t,
            'font-size': '30',
            'x': this.x,
            'y': this.y,
          },
        ],
        tooltip: {
          'text': '%kl : %v',
          'thousands-separator': '.',
          'decimals': 0,
          'padding': '10px',
          'backgroundColor': '#fff',
          'borderColor': '#333',
          'borderRadius': '5px',
          'borderWidth': '1px',
          'fontColor': '#444',
          'fontSize': '14px',
        },
        series: this.series,
      };
    },
  },
  data() {
    return {
      loading: false,
      t: '',
      c: [],
      s: [],
      x: [],
      y: [],
      categorias: [],
      tamanoFuenteX: '',
      tamanoFuenteIn: '',
      TamanoOffset: 0,
      contador: 0,
      Posicion: '',
      categ: [],
      seriesaux: [],
    };
  },
};
</script>
