import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    juzgados: [],
    cortes: [],
    cifras: [],
    texto: [],
    modulos: [],
    secciones: [],
    tabulaciones: [],
    cantGraficos: [],
    anioI: '2024',
    SerieChart1: [],
    SerieChart2: [],
    SerieChart3: [],
    CatChart1: [],
    CatChart2: [],
    CatChart3: [],
    ubicacionmaps: [],
    keyNum: 0,
    ipapi: 'https://estadisticaservices.pjud.cl/pjen/',
    // ipapi: 'http://10.13.142.195:8000/pjen/',
    // ipact: location.protocol + '//' + location.host
    // ipact: 'https://sie.pjud.cl',
    ipact: 'https://estadisticaservices.pjud.cl/descargas',
  },
  mutations: {
    setJuzgados(state, juzgados) {
      state.juzgados = juzgados;
    },
    setCortes(state, cortes) {
      state.cortes = cortes;
    },
    setCifras(state, cifras) {
      state.cifras = cifras;
    },
    setTexto(state, texto) {
      state.texto = texto;
    },
    setModulos(state, modulos) {
      state.modulos = modulos;
    },
    setSecciones(state, secciones) {
      state.secciones = secciones;
    },
    setTabulaciones(state, tabulaciones) {
      state.tabulaciones = tabulaciones;
    },
    setCantGraficos(state, cantGraficos) {
      state.cantGraficos.push(cantGraficos);
    },
    delCantGraficos(state, cantGraficos) {
      state.cantGraficos = [];
    },
    setAnioI(state, anioI) {
      state.anioI = anioI;
    },
    setSerieChart1(state, serie, cate) {
      state.SerieChart1 = serie;
      state.CatChart1 = cate;
    },
    setSerieChart2(state, serie, cate) {
      state.SerieChart2 = serie;
      state.CatChart2 = cate;
    },
    setSerieChart3(state, serie, cate) {
      state.SerieChart3 = serie;
      state.CatChart3 = cate;
    },
    setubicacionMaps(state, ubi) {
      state.ubicacionmaps = ubi;
    },
    setkeynum(state, kn) {
      state.keyNum = kn;
    },
  },
  actions: {
    test(commit) {
      console.log('test');
    },
    actjuzgados(context, data) {
      context.commit('setJuzgados', data);
    },
    actcortes(context, data) {
      context.commit('setCortes', data);
    },
    actcifras(context, data) {
      context.commit('setCifras', data);
    },
    acttexto(context, data) {
      context.commit('setTexto', data);
    },
    actmodulos(context, data) {
      context.commit('setModulos', data);
    },
    actsecciones(context, data) {
      context.commit('setSecciones', data);
    },
    acttabulaciones(context, data) {
      context.commit('setTabulaciones', data);
    },
    actcantgraficos(context, data) {
      context.commit('setCantGraficos', data);
    },
    delcantgraficos(context, data) {
      context.commit('delCantGraficos', data);
    },
    actanioi(context, data) {
      context.commit('setAnioI', data);
    },
    actSerieChart1(context, data, cate) {
      context.commit('setSerieChart1', data, cate);
    },
    actSerieChart2(context, data, cate) {
      context.commit('setSerieChart2', data, cate);
    },
    actSerieChart3(context, data, cate) {
      context.commit('setSerieChart3', data, cate);
    },
    actubicacionMaps(context, ubi) {
      context.commit('setubicacionMaps', ubi);
    },
    actkeyNum(context, kn) {
      context.commit('setkeynum', kn);
    },

  },
  getters: {
    getJuzgados(state) {
      return state.juzgados;
    },
    getCortes(state) {
      return state.cortes;
    },
    getCifras(state) {
      return state.cifras;
    },
    getTexto(state) {
      return state.texto;
    },
    getModulos(state) {
      return state.modulos;
    },
    getSecciones(state) {
      return state.secciones;
    },
    getTabulaciones(state) {
      return state.tabulaciones;
    },
    getCantGraficos(state) {
      return state.cantGraficos;
    },
    getAnioI(state) {
      return state.anioI;
    },
    getubicacionMaps(state) {
      return state.ubicacionmaps;
    },
    getipapi(state) {
      return state.ipapi;
    },
    getkeynum(state) {
      return state.keyNum;
    },
  },
});
