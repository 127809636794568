import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home';
import Inicio from './views/Inicio';
import Territorio from './views/Territorio';
import Competencias from './views/Competencias';
import Estadisticas from './views/Estadisticas';
import Capj from './views/Capj';
import CorteSuprema from './views/CorteSuprema';
import FuncCorteSuprema from './views/FuncCorteSuprema';
import PerUnidCorteSup from './views/PerUnidCorteSup';
import Descargas from './views/Descargas';
import Legales from './views/Legales';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/Inicio',
      name: 'home',
      component: Home,
      children: [{
        path: '/Inicio',
        name: 'Inicio',
        component: Inicio,
      },
      {
        path: '/CorteSuprema',
        name: 'CorteSuprema',
        component: CorteSuprema,
      },
      {
        path: '/CorteSuprema/Funcionamiento',
        name: 'Funcionamiento',
        component: FuncCorteSuprema,
      },
      {
        path: '/CorteSuprema/PersonalyUnidades',
        name: 'PersonalyUnidades',
        component: PerUnidCorteSup,
      },
      {
        path: '/Territorio',
        name: 'Territorio',
        component: Territorio,
      },
      {
        path: '/Territorio/:ubicacion',
        redirect: '/Territorio',
        name: 'TerritorioUbicacion',
        component: Territorio,
      },
      {
        path: '/Competencias',
        name: 'Competencias',
        component: Competencias,
      },
      {
        path: '/Competencias/:ubicacion',
        name: 'CompetenciasUbicacion',
        component: Competencias,
      },
      {
        path: '/Estadisticas/:ubicacion',
        name: 'EstadisticasUbicacion',
        component: Estadisticas,
      },
      {
        path: '/Capj',
        name: 'Capj',
        component: Capj,
      },
      {
        path: '/Capj/:ubicacion',
        redirect: '/Capj',
        name: 'CapjUbicacion',
        component: Capj,
      },
      {
        path: '/Descargas',
        name: 'Descargas',
        component: Descargas,
      },
      {
        path: '/Legales',
        name: 'Legales',
        component: Legales,
      },
      ],
    },
  ],
});
