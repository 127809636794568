<template>
<div>
<div class="box_mode content_wrapper">
    <div class="home_area">
      <div class="row align-items-center">
        <div class="col-md-6"></div>
        <div class="col-md-2">
          <div class="tribunales" v-show="this.calendario == 1">
            <ul class="sub">
              <li v-for="(anio_sel, index) in anios_select" v-bind:key="anio_sel.VALOR"><a class="link" v-on:click="SelAnio(anio_sel.VALOR, index)">{{ anio_sel.TEXTO }}</a></li>
            </ul>
            <a class="btn" href="#">{{ txt_sel }}</a>
          </div>
        </div>
        <div class="col-md-2">
          <div class="tribunales">
            <a class="btn" href="#">Descargar</a>
            <ul class="sub">
              <li><a class="link" v-on:click="Descargas('JSON', 0)">JSON</a></li>
              <li><a class="link" v-on:click="Descargas('CSV', 0)">CSV</a></li>
              <li><a class="link" v-on:click="Descargas('XLSX', 0)">Excel</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <br>
      <br>
      <div class="row">
          <div class="col-md-12">
              <DoublePieChart
              title = ''
              :total = 'total'
              :total2 = 'total2'
              :total3 = 'total3'
              :series = 'series'
              :categories = 'categorias'
              :leyenda = 'leyenda'
              />
          </div>
      </div>
      <div class="row">
        <pre class="notas"><i>{{ notas }}</i></pre>
      </div>
    </div>
</div>
</div>
</template>
<script>
import {mapState, mapActions} from 'vuex';
import DoublePieChart from '@/components/Charts/DoublePieChart';
import XLSX from 'xlsx';
export default {
  props: {
    tipo: {
      type: String,
    },
    anio: {
      type: String,
      default: '2018',
    },
    cgraf: {
      type: Array,
      default: () => [],
    },
    cort: {
      type: String,
    },
    trib: {
      type: String,
    },
    comp: {
      type: String,
    },
    notas: {
      type: String,
    },
  },
  methods: {
    ...mapActions([
      'actubicacionMaps',
      'categoriasM',
      'seriesM',
      'actcantgraficos',
    ]),
    SelAnio: function(a, index) {
      this.anio = a;
      this.txt_sel = this.anios_select[index].TEXTO;
      for (const i in this.cgraf) {
        if (this.cgraf[i][0].SECCION == this.tipo.toLowerCase()) {
          this.sec.push(this.cgraf[i][0].API_A);
          this.titulo.push(this.cgraf[i][0].SUBTITULO_A);
          this.calendario = this.cgraf[i][0].CAL_A;
        }
      }
      if (this.calendario == 1) {
        this.loading = true;
        this.categorias = [];
        this.series = [];
        this.axios.get(this.ipapi + this.sec[0] + '/' + this.cort + '/' + this.anio)
            .then((response) => {
              this.leyenda = [];
              this.categorias = [];
              this.valor = [];
              this.total = 0;
              this.total2 = 0;
              this.total3 = 0;
              for (var i in response.data) {
                this.categorias.push(response.data[i].key);
                this.leyenda.push(response.data[i].text);
              }
              this.categorias = [...new Set(this.categorias)];
              this.leyenda = [...new Set(this.leyenda)];
              let valor = '[';
              for (var i in this.categorias) {
                valor = valor + '{ ';
                valor = valor + '"text": "' + this.categorias[i] + '", ';
                valor = valor + '"values": [';
                for (var j in response.data) {
                  if (response.data[j].key == this.categorias[i]) {
                    valor = valor + response.data[j].value + ', ';
                    this.total = this.total + response.data[j].value;
                  }
                }
                valor = valor.substring(0, valor.length - 2);
                valor = valor + ' ]';
                valor = valor + '}, ';
              }
              for (var i in this.leyenda) {
                for (var j in response.data) {
                  if (response.data[j].text == this.leyenda[i]) {
                    if (i == 0) {
                      this.total2 = this.total2 + response.data[j].value;
                    } else {
                      this.total3 = this.total3 + response.data[j].value;
                    }
                  }
                }
              }
              valor = valor.substring(0, valor.length - 2);
              valor = valor + ']';
              this.series = JSON.parse(valor);
            })
            .catch(function(error) {
              // console.log(error)
            });
        this.axios.get(this.ipapi + this.sec[0] + '/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
            .then((response) => {
              this.categorias = [];
              this.valor = [];
              this.total = 0;
              let valor = '';
              for (const i in response.data) {
                this.categorias.push(response.data[i].key);
                valor = valor + response.data[i].value + ',';
                this.total += response.data[i].value;
              }
              valor = valor.substring(0, valor.length - 1);
              valor = '[ { "values": [' + valor + '] } ]';
              this.series.push(valor);
              this.series = JSON.parse(this.series);
            })
            .catch(function(error) {
              // // console.log(error)
            });
      }
    },
    convertToCSV: function(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      const header = Object.keys(array[0]);
      let str = '';
      let linea = '';
      for (let i = 0; i < header.length; i++) {
        linea += header[i] + '\t';
      }
      str = linea.substring(0, linea.length - 1) + '\r\n';
      for (let i = 0; i < array.length; i++) {
        let line = '';
        for (const index in array[i]) {
          if (line != '') line += '\t';
          line += array[i][index];
        }
        str += line + '\r\n';
      }
      return str;
    },
    Descargas: function(tipo, pes) {
      if (tipo == 'JSON') {
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.anio)
            .then((response) => {
              const data = JSON.stringify(response.data);
              const blob = new Blob([data], {type: 'text/plain'});
              const e = document.createEvent('MouseEvents');
              const a = document.createElement('a');
              a.download = 'datos.csv';
              a.href = window.URL.createObjectURL(blob);
              a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
              e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
              a.dispatchEvent(e);
            })
            .catch(function(error) {
              // console.log(error)
            });
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
            .then((response) => {
              const data = JSON.stringify(response.data);
              const blob = new Blob([data], {type: 'text/plain'});
              const e = document.createEvent('MouseEvents');
              const a = document.createElement('a');
              a.download = 'datos.csv';
              a.href = window.URL.createObjectURL(blob);
              a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
              e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
              a.dispatchEvent(e);
            })
            .catch(function(error) {
              // console.log(error)
            });
      }
      if (tipo == 'CSV') {
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.anio)
            .then((response) => {
              const data = this.convertToCSV(response.data);
              const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
              const e = document.createEvent('MouseEvents');
              const a = document.createElement('a');
              a.download = 'datos.csv';
              a.href = window.URL.createObjectURL(blob);
              a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
              e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
              a.dispatchEvent(e);
            })
            .catch(function(error) {
              // console.log(error)
            });
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
            .then((response) => {
              const data = this.convertToCSV(response.data);
              const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
              const e = document.createEvent('MouseEvents');
              const a = document.createElement('a');
              a.download = 'datos.csv';
              a.href = window.URL.createObjectURL(blob);
              a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
              e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
              a.dispatchEvent(e);
            })
            .catch(function(error) {
              // console.log(error)
            });
      }
      if (tipo == 'XLSX') {
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.anio)
            .then((response) => {
              const data = XLSX.utils.json_to_sheet(response.data);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, data, this.comp);
              XLSX.writeFile(wb, 'datos.xlsx');
            })
            .catch(function(error) {
              // console.log(error)
            });
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
            .then((response) => {
              const data = XLSX.utils.json_to_sheet(response.data);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, data, this.comp);
              XLSX.writeFile(wb, 'datos.xlsx');
            })
            .catch(function(error) {
              // console.log(error)
            });
      }
    },
  },
  mounted() {
    this.categorias = [];
    this.series = [];
    for (const i in this.cgraf) {
      if (this.cgraf[i][0].SECCION == this.tipo.toLowerCase()) {
        this.sec.push(this.cgraf[i][0].API_A);
        this.titulo.push(this.cgraf[i][0].SUBTITULO_A);
        this.calendario = this.cgraf[i][0].CAL_A;
      }
    }
    this.axios.get(this.ipapi + 'select_anios/Select')
        .then((response) => {
          this.anios_select = response.data;
          for (const i in this.anios_select) {
            // if (this.anios_select[i].SELECCIONADO) {
            if (this.anios_select[i].VALOR == this.anioI) {
              this.txt_sel = this.anios_select[i].TEXTO;
            }
          }
        })
        .catch(function(error) {
          // console.log(error)
        });
    this.axios.get(this.ipapi + this.sec[0] + '/' + this.cort + '/' + this.anio)
        .then((response) => {
          this.leyenda = [];
          this.categorias = [];
          this.valor = [];
          this.total = 0;
          this.total2 = 0;
          this.total3 = 0;
          for (var i in response.data) {
            this.categorias.push(response.data[i].key);
            this.leyenda.push(response.data[i].text);
          }
          this.categorias = [...new Set(this.categorias)];
          this.leyenda = [...new Set(this.leyenda)];
          let valor = '[';
          for (var i in this.categorias) {
            valor = valor + '{ ';
            valor = valor + '"text": "' + this.categorias[i] + '", ';
            valor = valor + '"values": [';
            for (var j in response.data) {
              if (response.data[j].key == this.categorias[i]) {
                valor = valor + response.data[j].value + ', ';
                this.total = this.total + response.data[j].value;
              }
            }
            valor = valor.substring(0, valor.length - 2);
            valor = valor + ' ]';
            valor = valor + '}, ';
          }
          for (var i in this.leyenda) {
            for (var j in response.data) {
              if (response.data[j].text == this.leyenda[i]) {
                if (i == 0) {
                  this.total2 = this.total2 + response.data[j].value;
                } else {
                  this.total3 = this.total3 + response.data[j].value;
                }
              }
            }
          }
          // console.log(valor)
          valor = valor.substring(0, valor.length - 2);
          valor = valor + ']';
          this.series = JSON.parse(valor);
        })
        .catch(function(error) {
          // console.log(error)
        });
    this.axios.get(this.ipapi + this.sec[0] + '/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
        .then((response) => {
          this.categorias = [];
          this.valor = [];
          this.total = 0;
          let valor = '';
          for (const i in response.data) {
            this.categorias.push(response.data[i].key);
            valor = valor + response.data[i].value + ',';
            this.total += response.data[i].value;
          }
          valor = valor.substring(0, valor.length - 1);
          valor = '[ { "values": [' + valor + '] } ]';
          this.series.push(valor);
          this.series = JSON.parse(this.series);
        })
        .catch(function(error) {
          // console.log(error)
        });
  },
  components: {DoublePieChart},
  data() {
    return {
      calendario: 1,
      categorias: [],
      series: [],
      leyenda: [],
      sec: [],
      titulo: [],
      anios_select: [],
      txt_sel: '',
      total: 0,
      total2: 0,
      total3: 0,
      viewEvent: {
        type: '',
        percentInView: 0,
        percentTop: 0,
        percentCenter: 0,
      },
    };
  },
  computed: {
    ...mapState([
      'ubicacionmaps',
      'categoriasC',
      'seriesC',
      'cantGraficos',
      'ipapi',
      'anioI',
    ]),
  },
};
</script>
