<template>
<div>
<div class="box_mode content_wrapper">

    <div class="home_area">
        <div class="container">
            <div class="col-md-12">
            <div class="row">
                <div class="col-md-6 home_col" id="welcome">
                    <div class="home_img relative text-left"><br><br><br>
                        <img class="animated a_fr" data-delay=".3s" src="@/assets/img/ilu_portada.svg" alt="" width="500">
                    </div>
                    <div class="round_animate_wrap">
                        <div class="round_animate">
                            <div class="shape_1">
                                <span class="r_1"></span>
                                <span class="r_2"></span>
                                <span class="r_3"></span>
                                <span class="r_4"></span>
                            </div>
                            <div class="shape_2">
                                <span class="r_1"></span>
                                <span class="r_2"></span>
                                <span class="r_3"></span>
                                <span class="r_4"></span>
                            </div>
                            <div class="shape_3">
                                <span class="r_1"></span>
                                <span class="r_2"></span>
                                <span class="r_3"></span>
                                <span class="r_4"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 home_col text-right">
                    <div class="home_content" style="margin-top:40px">
                        <div class="col-md-2"></div>
                        <!-- <div class="col-md-10">
                            <agile :dots="false" :nav-buttons="false" :autoplay-speed="5000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay">
                                <div class="slide rojo-btn-slide">
                                    <div class="h2" style="text-align:center; font-weight: bold;">X.XXX.XXX</div>
                                    <div class="h4" style="text-align:center">Total Causas ingresadas</div>
                                </div>
                                <div class="slide rojo-btn-slide">
                                    <div class="h2" style="text-align:center; font-weight: bold;">X.XXX.XXX</div>
                                    <div class="h4" style="text-align:center">Causas en Tramitación</div>
                                </div>
                                <div class="slide rojo-btn-slide ">
                                    <div class="h2" style="text-align:center; font-weight: bold;">X.XXX.XXX</div>
                                    <div class="h4" style="text-align:center">Otro Dato</div>
                                </div>
                            </agile>
                        </div> -->
                        <div class="h1" style="margin-top:160px">Poder Judicial</div>
                        <div class="h1"><span style="font-weight: 100; color: #00bcd4;">en números</span></div>
                        <div class="home_btn">
                            <a href="#features" class="home_btn rojo-btn hvr-shutter-out-horizontal">Ver cifras clave &nbsp;<i class="icofont icofont-long-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="section_title animated a_fu">
                        <a href="#features">
                            <svg width="40px" height="100%" viewBox="0 0 247 390" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">
                                <path id="wheel" d="M123.359,79.775l0,72.843" style="fill:none;stroke:#2196f3;stroke-width:20px;" />
                                <path id="mouse" d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z" style="fill:none;stroke:#2196f3;stroke-width:20px;" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="brand_area sp100 animated" :id="'periodo' + anioI" >
    <div class="container" id="features">
        <div class="row">
            <div class="col-md-12">
                <div class="section_title animated a_fu">
                    <div class="h1">Cifras <span style="font-weight: 100; color: #00bcd4; font-style: italic;">clave </span> {{ anioI }}</div>
                </div>
                <div class="section_title animated a_fu" v-view.once="onceHandler">
                    <div class="h3"><span style="font-weight: 900; color: #3f51b5; font-style: italic;">¿Quiénes somos?</span></div>
                </div>
            </div>
        </div>
        <div class="row featuresA" id="featuresA" v-if="cortes && cortes.length">
          <div class="ninicio col-md-12">
            <div class="col-md-2 text-left" style="max-height: 320px !important;">
                <div class="feature_dsc animated a_fu">
                    <div class="clearfix">
                        <h4><span style="font-weight: 100; color: #00bcd4; font-style: italic;">Misión</span></h4>
                    </div>
                    <div class="feature_txt">
                        <p>Solucionar los asuntos de su competencia de manera clara, oportuna y efectiva, con plena vigencia de todos los derechos de las personas, contribuyendo así a la paz social y al fortalecimiento de la democracia.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-8 text-center" style="max-height: 320px !important;"></div>
            <div class="col-md-2 animated a_fu text-right" style="max-height: 320px !important;">
                <div class="single_feature">
                    <div class="sf_inner">
                        <h4><span style="font-weight: 100; color: #00bcd4; font-style: italic;">Visión</span></h4>
                        <p>Ser reconocido por la comunidad como la vía de solución de los asuntos de su competencia, de manera pronta, justa y confiable.</p>
                    </div>
                </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="col-md-2 text-left" style="max-height: 320px !important;"></div>
            <div class="col-md-8 text-center" style="max-height: 320px !important;">
                <div class="circulo1 text-center feature_img animated a_fu fadeInUp"><router-link :to="{ name: 'CorteSuprema'}"><br>
                    <img src="@/assets/img/01_cortesup.png" alt="" style="margin-top:-30px">
                    <h4>{{ cortes[0].TEXTO }}</h4>
                    <div class="table-bordered"></div>
                    <div class="lvertical"></div>
                    </router-link>
                </div>
                <div class="circulo2 text-center"><router-link to="/Territorio/10-Arica">
                    <img :src="cortes[1].ICONO" alt="">
                    <a class="numerogrande"><countTo :startVal='0' :endVal='parseInt(cortes[1].VALOR)' :duration='8000'  separator="."></countTo></a>
                    </router-link>
                </div>
                <h5><router-link to="/Territorio/10-Arica">{{ cortes[1].TEXTO }}</router-link></h5>
                <div class="table-bordered2"></div>
                <div class="lvertical2"></div>
                <div class="linearedonda">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 658 68" xml:space="preserve">
                        <path class="st0" d="M649.5,60V39c0-16.6-13.4-30-30-30h-580c-16.6,0-30,13.4-30,30v21" />
                    </svg>
                </div>
            </div>
            <div class="col-md-2 animated a_fu text-right" style="max-height: 320px !important;"></div>
          </div>
            <div class="col-md-12">
                <div class="col-md-2">&nbsp;</div>
                <div class="col-md-8 animated a_fu" v-if="juzgados && juzgados.length">
                    <div v-for="juzgado of juzgados" v-bind:key="juzgado + '' + juzgado.TEXTO">
                        <div :class="juzgado.CLASE + ' text-center'">
                            <div class="circulo2">
                                <div v-if="juzgado.LINK">
                                  <router-link :to="juzgado.LINK" v-on:click="UbiCompt(juzgado.COMPETENCIA)">
                                    <img :src="juzgado.ICONO"  alt="" style="width:52px; height:41px;">
                                    <a class="numerogrande">
                                      <countTo :startVal='0' :endVal='parseInt(juzgado.VALOR)' :duration='8000'  separator="."></countTo>
                                    </a>
                                  </router-link>
                                </div>
                                <div v-else>
                                  <img :src="juzgado.ICONO"  alt="" style="width:52px; height:41px;">
                                  <a class="numerogrande">
                                    <countTo :startVal='0' :endVal='parseInt(juzgado.VALOR)' :duration='8000'  separator="."></countTo>
                                  </a>
                                </div>
                            </div>
                                <h5>{{ juzgado.TEXTO }}</h5>
                        </div>
                    </div>
                    <div class="row">
                    <div class="linearedonda">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 644.9 79.5" style="enable-background:new 0 0 644.9 79.5;" xml:space="preserve">
                            <path class="st0" d="M3,0v20.9c0,16.5,13.4,29.8,30,29.8h289.5H613c16.6,0,30-13.3,30-29.8V0" />
                            <line class="st0" x1="322.5" y1="79.5" x2="322.5" y2="50.7" />
                        </svg>
                    </div>
                    <div class="col-md-12 text-center">
                        <div class="circulo2">
                            <img :src="cortes[2].ICONO" alt="">
                            <a class="numerogrande"><countTo :startVal='0' :endVal='parseInt(cortes[2].VALOR)' :duration='8000'  separator="."></countTo></a>
                        </div>
                        <h5>{{ cortes[2].TEXTO }}</h5>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</template>
<script>
import countTo from 'vue-count-to';
import {VueAgile} from 'vue-agile';
import {mapState, mapActions} from 'vuex';
import {page} from 'vue-analytics';
export default {
  methods: {
    ...mapActions([
      'actjuzgados',
      'actcortes',
      'actubicacionMaps',
      'actanioi',
    ]),
    track() {
      page('/');
    },
    UbiCompt: function(a) {
      this.partes = a.split('-');
      this.actubicacionMaps(this.partes);
      console.clear();
    },
    onceHandler: function(e) {
      console.log(e.type); // 'enter', 'exit', 'progress'
      if (e.type === 'enter') {
        this.axios.get(this.ipapi + 'cifras_clave/portada/juzgados/' + (this.anioI === '' ? (new Date().getFullYear()) - 2 : this.anioI))
            .then((response) => {
              this.actjuzgados(response.data);
            })
            .catch(function(error) {

            });

        this.axios.get(this.ipapi + 'cifras_clave/portada/organigrama/' + (this.anioI === '' ? (new Date().getFullYear()) - 2 : this.anioI))
            .then((response) => {
              this.actcortes(response.data);
            })
            .catch(function(error) {

            });
      }
      console.clear();
    },
  },
  mounted() {
    $('a').on('click', function(event) {
      if (this.hash != '' && this.hash) {
        // Prevent default anchor click behavior
        event.preventDefault();
        // Store hash
        const hash = this.hash;
        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
          scrollTop: $(hash).offset().top,
        }, 800, function() {
          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        });
      } else {
        $('body,html').animate({
          scrollTop: 0, // Scroll to top of body
        }, 500);
      } // End if
    });
    // ===== Scroll to Top ====
    $(window).scroll(function() {
      if ($(this).scrollTop() >= 50) { // If page is scrolled more than 50px
        $('#return-to-top').fadeIn(200); // Fade in the arrow
      } else {
        $('#return-to-top').fadeOut(200); // Else fade out the arrow
      }
    });
    $('#return-to-top').click(function() { // When arrow is clicked
      $('body,html').animate({
        scrollTop: 0, // Scroll to top of body
      }, 500);
    });

    $(window).on('scroll', function() {
      if ($(window).scrollTop() > 100) {
        $('header').addClass('sticky');
      } else {
        $('header').removeClass('sticky');
      }
    });
    console.clear();
  },
  components: {countTo, agile: VueAgile},
  data() {
    return {
      viewEvent: {
        type: '',
        percentInView: 0,
        percentTop: 0,
        percentCenter: 0,
      },
    };
  },
  computed: {
    ...mapState([
      'juzgados',
      'cortes',
      'anioI',
      'ipapi',
    ]),
  },
};
</script>
