<template>
  <div>
    <CapjCifras></CapjCifras>
  </div>
</template>
<script>
import CapjCifras from '@/components/CapjCifras.vue';
import CorteApeTerr from '@/components/CorteApeTerr.vue';

import {
  mapState,
  mapActions,
} from 'vuex';
export default {
  methods: {
    ...mapActions([
      'actjuzgados',
      'actcortes',
    ]),
    UbiTerrMaps: function(a) {
      this.partes = a.split('-');
      this.ubicacion = this.partes[1];
    },
  },
  mounted() {
    // this.partes = this.$route.params.ubicacion.split('-')
    // this.ubicacion = this.partes[1]
  },
  components: {
    CapjCifras,
    CorteApeTerr,
  },
  data() {
    return {
      ubicacion: 0,
      selectedComponent: 'DosGraficos',
    };
  },
  computed: {
    ...mapState([
      'juzgados',
      'cortes',

    ]),
  },
};
</script>
<style src="vue-slim-tabs/themes/default.css"></style>
<style>.header {
  background-color: #bfcdee;
  padding: 8px 10px;
  border-radius: 50px;
  margin-bottom: 20px;
}

h4 {
  color: #3f51b5;
  font-size: 22px;
  margin-bottom: 0;
}

select {
  border: 2px solid #3f51b5;
  background-color: #FFF;
  color: #666;
  border-radius: 100px;
}
</style>
