<template>
  <div>
    <div class="box_mode content_wrapper">
      <br>
      <br>
      <br>
      <br>
      <div class="home_area">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section_title animated a_fu">
                <div class="h1">Otros Estudios</div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class=" mt-4">
            <div class="mapa d-flex">
              <div v-for="t in titulosEstudios" v-bind:key="t.TITULO" class="sector" v-bind:class="{'active':(ubmaps[0] ==t.TITULO)}" v-on:click="ubiCompt(t.TITULO)">
                <span class="name">{{t.TITULO}}</span>
                <span :class="'icon ' + t.ICONO "></span>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  <div class="brand_area sp100 animated a_fu" id="territorio-result">
    <CorteApeTerr
      :tabs="tabs"
      :cgraf="cgraf"
      :secc="secc"
      :cort="'0'"
      :comp="ubmaps[0]"
      :texto="texto"
      :cifras="cifras"
      :titulo="comtit"
      :subtitulo="'Seleccione el ítem para revisar las cifras de la Materia ' + ubmaps[0]"
      :key3="keyCorteTerr"
    >
    </CorteApeTerr>
    <br>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h4 class="mt-2 mb-3">Actualizaciones</h4>
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="table-responsive">
                                    <table class="table">
                                        <tbody>
                                            <tr style="cursor: pointer" v-for="t in titulosHEstudios" v-bind:key="t.ORDEN" v-on:click="Descarga(t.SECCION, t.COMPETENCIA, t.TITULO)">
                                                <td><img src="@/assets/img/descargr.png" alt="Descargas" title="Descargas" width="30" height="30" ></td>
                                                <td>{{ t.TEXTO }}</td>
                                                <td>{{ t.COMPETENCIA }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
  </div>
  <input v-model="ubicacionmaps" style="display: none">
</div>
</template>
<script>
import CorteApeTerr from '@/components/CorteApeTerr.vue';
import {
  mapState,
  mapActions,
} from 'vuex';
export default {
  methods: {
    ...mapActions([
      'actubicacionMaps',
      'actcifras',
      'acttexto',
      'actmodulos',
      'actanioi',
    ]),
    ubiCompt: function(a) {
      if (typeof a === 'object') {
        this.ubmaps = a;
      } else {
        this.ubmaps = [];
        this.partes = a.split('-');
        this.ubmaps = this.partes;
      }

      if (this.ubmaps[0] == 'Garantia') {
        this.comtit = 'Garantía';
      } else if (this.ubmaps[0] == 'Top') {
        this.comtit = 'Oral en lo Penal';
      } else {
        this.comtit = this.ubmaps[0];
      }
      this.keyCorteTerr += 1;
      this.cgraf = [];
      this.axios.get(this.ipapi + 'modulos/0/' + this.ubmaps[0] + '/0')
          .then((response) => {
            this.m = [];
            this.m.push(JSON.parse(response.data[0].GRAFICOS));
            this.tabs = this.m[0].TEXTO;
            this.secc = this.m[0].SECCION;
            for (const i in this.secc) {
              this.axios.get(this.ipapi + 'textos/otrosest/' + this.secc[i] + '/' + this.ubmaps[0])
                  .then((response) => {
                    this.cgraf.push(response.data);
                  })
                  .catch(function(error) {
                    console.log(error);
                  });
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      this.axios.get(this.ipapi + 'cifras_clave/otrosest/' + this.ubmaps[0] + '/' + (this.anioI === '' ? (new Date().getFullYear()) - 2 : this.anioI))
          .then((response) => {
            this.actcifras(response.data);
          })
          .catch(function(error) {
            console.log(error);
          });
      this.axios.get(this.ipapi + 'textos/otrosest/' + this.ubmaps[0] + '/0')
          .then((response) => {
            this.acttexto(response.data);
          })
          .catch(function(error) {
            console.log(error);
          });
      this.axios.get(this.ipapi + 'textos/otrosestH/' + this.ubmaps[0] + '/0')
          .then((response) => {
            this.titulosHEstudios = [];
            Object.keys(response.data).forEach((key) => {
              this.titulosHEstudios.push(response.data[key]);
            });
          })
          .catch(function(error) {
            console.log(error);
          });
    },
  },
  components: {CorteApeTerr},
  mounted() {
    this.$watch(
        () => {
        },
        (newVal, oldVal) => {
        },
    );
    this.axios.get(this.ipapi + 'modulos/0/0/otrosest')
        .then((response) => {
          this.titulosEstudios = [];
          Object.keys(response.data).forEach((key) => {
            this.titulosEstudios.push(response.data[key]);
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    console.log('Component mounted. test');
    this.ubmaps = [];
    this.ubmaps = this.$route.params.ubicacion.split('-');
    this.ubiCompt(this.ubmaps);
  },
  watch: {
    'deep': true,
    'ubicacionmaps': function(newVal, oldVal) {
      this.ubiCompt(newVal);
    },
  },
  data() {
    return {
      tabs: [],
      cgraf: [],
      secc: [],
      ubmaps: [],
      titulosEstudios: [],
      titulosHEstudios: [],
      comtit: '',
      keyCorteTerr: 0,
    };
  },
  computed: {
    ...mapState([
      'ubicacionmaps',
      'cifras',
      'texto',
      'modulos',
      'ipapi',
      'anioI',
    ]),
  },
};
</script>
