<template>
<div>
  <tabs>
    <tab :title = titulo[0]>
      <div class="row align-items-center">
        <div class="col-md-6"></div>
        <div class="col-md-2" v-show="this.calendario == 1">
          <div class="tribunales">
            <ul class="sub">
              <li v-for="(anio_sel, index) in anios_select" v-bind:key="anio_sel.VALOR"><a class="link" v-on:click="SelAnio(anio_sel.VALOR, index)">{{ anio_sel.TEXTO }}</a></li>
            </ul>
            <a class="btn" href="#">{{ txt_sel }}</a>
          </div>
        </div>
        <div v-if="download" class="col-md-2">
          <img src="@/assets/img/download.gif">
          Preparando...
        </div>
        <div v-if="!download" class="col-md-2">
          <div class="tribunales">
            <a class="btn" href="#">Descargar</a>
            <ul class="sub">
              <li><a class="link" v-on:click="Descargas('JSON', 0)">JSON</a></li>
              <li><a class="link" v-on:click="Descargas('CSV', 0)">CSV</a></li>
              <li><a class="link" v-on:click="Descargas('XLSX', 0)">Excel</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row">
          <div v-if="loading" class="col-md-12">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <img src="@/assets/img/loading.gif">
              Cargando...
            </div>
            <div class="col-md-4"></div>
          </div>
          <div v-if="!loading" class="col-md-12">
            <BarChart
              title = ''
              :tipGraf = 'tipGraf'
              :categories = 'categorias'
              :series = 'series'
              :legend = 'leyenda'
              :totalG = 'totalG'
              :seccionG = 'tipo'
              :calendario = 'calendario'
            />
          </div>
      </div>
      <div class="row">
        <pre class="notas"><i>{{ notas }}</i></pre>
      </div>
    </tab>
    <tab :title = titulo[1]>
      <div class="row align-items-center">
        <div class="col-md-6"></div>
        <div class="col-md-2" v-show="this.calendario2 == 1">
          <div class="tribunales">
            <ul class="sub">
              <li v-for="(anio_sel, index) in anios_select" v-bind:key="anio_sel.VALOR"><a class="link" v-on:click="SelAnio(anio_sel.VALOR, index)">{{ anio_sel.TEXTO }}</a></li>
            </ul>
            <a class="btn" href="#">{{ txt_sel }}</a>
          </div>
        </div>
          <div v-if="download" class="col-md-2">
            <img src="@/assets/img/download.gif">
            Preparando...
          </div>
          <div v-if="!download" class="col-md-2">
            <div class="tribunales">
              <a class="btn" href="#">Descargar</a>
              <ul class="sub">
                <li><a class="link" v-on:click="Descargas('JSON', 1)">JSON</a></li>
                <li><a class="link" v-on:click="Descargas('CSV', 1)">CSV</a></li>
                <li><a class="link" v-on:click="Descargas('XLSX', 1)">Excel</a></li>
              </ul>
            </div>
          </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row">
          <div v-if="loading2" class="col-md-12">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <img src="@/assets/img/loading.gif">
              Cargando...
            </div>
            <div class="col-md-4"></div>
          </div>
          <div v-if="!loading2" class="col-md-12">
            <BarChart
              title = ''
              :tipGraf = 'tipGraf'
              :categories = 'categorias2'
              :series = 'series2'
              :legend = 'leyenda2'
              :totalG = 'totalG2'
			  :seccionG = 'tipo'
			  :calendario = 'calendario2'
            />
          </div>
      </div>
      <div class="row">
        <pre class="notas"><i>{{ notas }}</i></pre>
      </div>
    </tab>
  </tabs>
</div>
</template>
<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import BarChart from '@/components/Charts/BarChart';
import XLSX from 'xlsx';
export default {
  props: {
    tipo: {
      type: String,
    },
    anio: {
      type: String,
    },
    cgraf: {
      type: Array,
      default: () => [],
    },
    cort: {
      type: String,
    },
    trib: {
      type: String,
    },
    comp: {
      type: String,
    },
    notas: {
      type: String,
    },
  },
  methods: {
    ...mapActions([
      'actubicacionMaps',
      'actcantgraficos',
    ]),
    SelAnio: function(a, index) {
      this.anio = a;
      this.txt_sel = this.anios_select[index].TEXTO;
      for (const i in this.cgraf) {
        if (this.cgraf[i][0].SECCION == this.tipo.toLowerCase()) {
          this.sec.push(this.cgraf[i][0].API_A);
          this.sec.push(this.cgraf[i][0].API_B);
          this.titulo.push(this.cgraf[i][0].SUBTITULO_A);
          this.titulo.push(this.cgraf[i][0].SUBTITULO_B);
          this.tipGraf = this.cgraf[i][0].GRAFICO_A;
          this.tipGraf = this.cgraf[i][0].GRAFICO_B;
          this.calendario = this.cgraf[i][0].CAL_A;
          this.calendario2 = this.cgraf[i][0].CAL_B;
        }
      }
      if (this.calendario == 1) {
        this.loading = true;
        this.categorias = [];
        this.series = [];
        this.errCant = 0;
        this.axios.get(this.ipapi + this.sec[0] + '/' + this.cort + '/' + this.anio)
            .then((response) => {
              if (!response.data[0].text) {
                this.categorias = [];
                this.valor = [];
                var valor = '';
                this.totalG = 0;
                for (var i in response.data) {
                  this.categorias.push(response.data[i].key);
                  valor = valor + response.data[i].value + ',';
                  this.totalG = this.totalG + response.data[i].value;
                }
                valor = valor.substring(0, valor.length - 1);
                valor = '[ { "text": "' + this.titulo[0] + '", "values": [' + valor + '] } ]';
                this.series = [];
                this.series.push(valor);
                this.series = JSON.parse(this.series);
                this.loading = false;
              } else {
                const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
                this.categorias = [];
                const categoria = [];
                let text = [];
                let valores = '';
                this.totalG = 0;
                for (var i in response.data) {
                  categoria.push(response.data[i].key);
                  text.push(response.data[i].text);
                }
                this.categorias = [...new Set(categoria)];
                this.leyenda = text;
                text = [...new Set(text)];
                var valor = '[';
                for (var i in text) {
                  valor = valor + '{ ';
                  valor = valor + '"text": "' + text[i] + '", ';
                  valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                  valor = valor + '"values": [';
                  valores = '';
                  for (const j in response.data) {
                    if (text[i] == response.data[j].text) {
                      valores = valores + response.data[j].value + ',';
                      this.totalG = this.totalG + response.data[j].value;
                    }
                  }
                  valores = valores.substring(0, valores.length - 1);
                  valor = valor + valores + ']},';
                }
                valor = valor.substring(0, valor.length - 1);
                valor = valor + ']';
                this.series.push(valor);
                this.series = JSON.parse(this.series);
                this.loading = false;
              }
            })
            .catch((error) => {
              this.errCant += 1;
              if (this.errCant == 2) {
                this.loading = false;
              }
            });

        this.axios.get(this.ipapi + this.sec[0] + '/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
            .then((response) => {
              if (!response.data[0].text) {
                this.categorias = [];
                this.valor = [];
                var valor = '';
                this.totalG = 0;
                for (var i in response.data) {
                  this.categorias.push(response.data[i].key);
                  valor = valor + response.data[i].value + ',';
                  this.totalG = this.totalG + response.data[i].value;
                }
                valor = valor.substring(0, valor.length - 1);
                valor = '[ { "text": "' + this.titulo[0] + '", "values": [' + valor + '] } ]';
                this.series = [];
                this.series.push(valor);
                this.series = JSON.parse(this.series);
                this.loading = false;
              } else {
                const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
                this.categorias = [];
                const categoria = [];
                let text = [];
                let valores = '';
                this.totalG = 0;
                for (var i in response.data) {
                  categoria.push(response.data[i].key);
                  text.push(response.data[i].text);
                }
                this.categorias = [...new Set(categoria)];
                this.leyenda = text;
                text = [...new Set(text)];
                var valor = '[';
                for (var i in text) {
                  valor = valor + '{ ';
                  valor = valor + '"text": "' + text[i] + '", ';
                  valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                  valor = valor + '"values": [';
                  valores = '';
                  for (const j in response.data) {
                    if (text[i] == response.data[j].text) {
                      valores = valores + response.data[j].value + ',';
                      this.totalG = this.totalG + response.data[j].value;
                    }
                  }
                  valores = valores.substring(0, valores.length - 1);
                  valor = valor + valores + ']},';
                }
                valor = valor.substring(0, valor.length - 1);
                valor = valor + ']';
                this.series.push(valor);
                this.series = JSON.parse(this.series);
                this.loading = false;
              }
            })
            .catch((error) => {
              this.errCant += 1;
              if (this.errCant == 2) {
                this.loading = false;
              }
            });
      }
      if (this.calendario2 == 1) {
        this.loading2 = true;
        this.categorias2 = [];
        this.series2 = [];
        this.totalG2 = 0;
        this.errCant2 = 0;
        this.axios.get(this.ipapi + this.sec[1] + '/' + this.cort + '/' + this.anio)
            .then((response) => {
              if (!response.data[0].text) {
                var valor = '';
                this.categorias2 = [];
                for (var i in response.data) {
                  this.categorias2.push(response.data[i].key);
                  valor = valor + response.data[i].value + ',';
                  this.totalG2 = this.totalG2 + response.data[i].value;
                }
                valor = valor.substring(0, valor.length - 1);
                valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
                this.series2 = [];
                this.series2.push(valor);
                this.series2 = JSON.parse(this.series2);
                this.loading2 = false;
              } else {
                const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
                this.categorias2 = [];
                const categoria = [];
                let text = [];
                let valores = '';
                this.totalG2 = 0;
                for (var i in response.data) {
                  categoria.push(response.data[i].key);
                  text.push(response.data[i].text);
                }
                this.categorias2 = [...new Set(categoria)];
                this.leyenda = text;
                text = [...new Set(text)];
                var valor = '[';
                for (var i in text) {
                  valor = valor + '{ ';
                  valor = valor + '"text": "' + text[i] + '", ';
                  valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                  valor = valor + '"values": [';
                  valores = '';
                  for (const j in response.data) {
                    if (text[i] == response.data[j].text) {
                      valores = valores + response.data[j].value + ',';
                      this.totalG2 = this.totalG2 + response.data[j].value;
                    }
                  }
                  valores = valores.substring(0, valores.length - 1);
                  valor = valor + valores + ']},';
                }
                valor = valor.substring(0, valor.length - 1);
                valor = valor + ']';
                this.series2.push(valor);
                this.series2 = JSON.parse(this.series2);
                this.loading2 = false;
              }
            })
            .catch((error) => {
              this.errCant2 += 1;
              if (this.errCant2 == 2) {
                this.loading2 = false;
              }
            });
        this.axios.get(this.ipapi + this.sec[1] + '/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
            .then((response) => {
              if (!response.data[0].text) {
                var valor = '';
                this.categorias2 = [];
                this.totalG2 = 0;
                for (var i in response.data) {
                  this.categorias2.push(response.data[i].key);
                  valor = valor + response.data[i].value + ',';
                  this.totalG2 = this.totalG2 + response.data[i].value;
                }
                valor = valor.substring(0, valor.length - 1);
                valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
                this.series2 = [];
                this.series2.push(valor);
                this.series2 = JSON.parse(this.series2);
                this.loading2 = false;
              } else {
                const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
                this.categorias2 = [];
                const categoria = [];
                let text = [];
                let valores = '';
                this.totalG2 = 0;
                for (var i in response.data) {
                  categoria.push(response.data[i].key);
                  text.push(response.data[i].text);
                }
                this.categorias2 = [...new Set(categoria)];
                this.leyenda = text;
                text = [...new Set(text)];
                var valor = '[';
                for (var i in text) {
                  valor = valor + '{ ';
                  valor = valor + '"text": "' + text[i] + '", ';
                  valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                  valor = valor + '"values": [';
                  valores = '';
                  for (const j in response.data) {
                    if (text[i] == response.data[j].text) {
                      valores = valores + response.data[j].value + ',';
                      this.totalG2 = this.totalG2 + response.data[j].value;
                    }
                  }
                  valores = valores.substring(0, valores.length - 1);
                  valor = valor + valores + ']},';
                }
                valor = valor.substring(0, valor.length - 1);
                valor = valor + ']';
                this.series2.push(valor);
                this.series2 = JSON.parse(this.series2);
                this.loading2 = false;
              }
            })
            .catch((error) => {
              this.errCant2 += 1;
              if (this.errCant2 == 2) {
                this.loading2 = false;
              }
            });
      }
      this.keyGraficos += 1;
      this.keyDosGraficos += 1;
    },
    convertToCSV: function(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      const header = Object.keys(array[0]);
      let str = '';
      let linea = '';
      for (let i = 0; i < header.length; i++) {
        linea += header[i] + '\t';
      }
      str = linea.substring(0, linea.length - 1) + '\r\n';
      for (let i = 0; i < array.length; i++) {
        let line = '';
        for (const index in array[i]) {
          if (line != '') line += '\t';
          line += array[i][index];
        }
        str += line + '\r\n';
      }
      return str;
    },
    fileExiste: function(url) {
      const http = new XMLHttpRequest();
      try {
        http.open('HEAD', url, false);
        http.send();
        if (http.readyState == 4 && http.status == 200) {
          return true;
        }
        return false;
      }
      catch{
        return false
      }
    },
    Descargas: function(tipo, pes) {
      this.download = true;
      if (tipo == 'JSON') {
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.anio)
            .then((response) => {
              const data = JSON.stringify(response.data);
              const blob = new Blob([data], {type: 'text/plain'});
              const e = document.createEvent('MouseEvents');
              const a = document.createElement('a');
              a.download = 'datos.json';
              a.href = window.URL.createObjectURL(blob);
              a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
              e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
              a.dispatchEvent(e);
              this.download = false;
            })
            .catch(function(error) {
              
            });
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
            .then((response) => {
              const data = JSON.stringify(response.data);
              const blob = new Blob([data], {type: 'text/plain'});
              const e = document.createEvent('MouseEvents');
              const a = document.createElement('a');
              a.download = 'datos.json';
              a.href = window.URL.createObjectURL(blob);
              a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
              e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
              a.dispatchEvent(e);
              this.download = false;
            })
            .catch(function(error) {
              
            });
      }
      if (tipo == 'CSV') {
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.anio)
            .then((response) => {
              const data = this.convertToCSV(response.data);
              const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
              const e = document.createEvent('MouseEvents');
              const a = document.createElement('a');
              a.download = 'datos.csv';
              a.href = window.URL.createObjectURL(blob);
              a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
              e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
              a.dispatchEvent(e);
              this.download = false;
            })
            .catch(function(error) {
              
            });
        if (this.fileExiste(this.ipact + '/descargas/' + this.comp.toLowerCase() + '/Otros/csv/' + this.sec[pes] + '-' + this.anio + '.csv')) {
          this.axios.get(this.ipact + '/descargas/' + this.comp.toLowerCase() + '/Otros/csv/' + this.sec[pes] + '-' + this.anio + '.csv', {responseType: 'blob'})
              .then(({data}) => {
                const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'datos.csv';
                link.click();
                this.download = false;
              });
        } else if (this.fileExiste(this.ipact + '/descargas/' + this.comp.toLowerCase() + '/Otros/csv/' + this.sec[pes] + '.csv')) {
          this.axios.get(this.ipact + '/descargas/' + this.comp.toLowerCase() + '/Otros/csv/' + this.sec[pes] + '.csv', {responseType: 'blob'})
              .then(({data}) => {
                const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'datos.csv';
                link.click();
                this.download = false;
              });
        } else {
          this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
              .then((response) => {
                const data = this.convertToCSV(response.data);
                const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
                const e = document.createEvent('MouseEvents');
                const a = document.createElement('a');
                a.download = 'datos.csv';
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
                e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
                this.download = false;
              })
              .catch(function(error) {
                
              });
        }
        console.clear();
      }
      if (tipo == 'XLSX') {
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.anio)
            .then((response) => {
              const data = XLSX.utils.json_to_sheet(response.data);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, data, this.comp);
              XLSX.writeFile(wb, 'datos.xlsx');
              this.download = false;
            })
            .catch(function(error) {
              
            });
        if (this.fileExiste(this.ipact + '/descargas/' + this.comp.toLowerCase() + '/Otros/xlsx/' + this.sec[pes] + '-' + this.anio + '.xlsx')) {
          this.axios.get(this.ipact + '/descargas/' + this.comp.toLowerCase() + '/Otros/xlsx/' + this.sec[pes] + '-' + this.anio + '.xlsx', {responseType: 'blob'})
              .then(({data}) => {
                const blob = new Blob([data], {type: 'application/xlsx'});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'datos.xlsx';
                link.click();
                this.download = false;
              });
        } else if (this.fileExiste(this.ipact + '/descargas/' + this.comp.toLowerCase() + '/Otros/xlsx/' + this.sec[pes] + '.xlsx')) {
          this.axios.get(this.ipact + '/descargas/' + this.comp.toLowerCase() + '/Otros/xlsx/' + this.sec[pes] + '.xlsx', {responseType: 'blob'})
              .then(({data}) => {
                const blob = new Blob([data], {type: 'application/xlsx'});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'datos.xlsx';
                link.click();
                this.download = false;
              });
        } else {
          this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
              .then((response) => {
                const data = XLSX.utils.json_to_sheet(response.data);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, data, this.comp);
                XLSX.writeFile(wb, 'datos.xlsx');
                this.download = false;
              })
              .catch(function(error) {
                
              });
        }
        console.clear();
      }
    },
  },
  mounted() {
    this.loading = true;
    this.loading2 = true;
    this.categorias = [];
    this.categorias2 = [];
    this.series = [];
    this.series2 = [];
    this.errCant = 0;
    this.errCant2 = 0;
    for (const i in this.cgraf) {
      if (this.cgraf[i][0].SECCION == this.tipo.toLowerCase()) {
        this.sec.push(this.cgraf[i][0].API_A);
        this.sec.push(this.cgraf[i][0].API_B);
        this.titulo.push(this.cgraf[i][0].SUBTITULO_A);
        this.titulo.push(this.cgraf[i][0].SUBTITULO_B);
        this.tipGraf = this.cgraf[i][0].GRAFICO_A;
        this.tipGraf = this.cgraf[i][0].GRAFICO_B;
        this.calendario = this.cgraf[i][0].CAL_A;
        this.calendario2 = this.cgraf[i][0].CAL_B;
      }
    }
    this.axios.get(this.ipapi + 'select_anios/Select')
        .then((response) => {
          this.anios_select = response.data;
          for (const i in this.anios_select) {
            // if (this.anios_select[i].SELECCIONADO) {
            if (this.anios_select[i].VALOR == this.anioI) {
              this.txt_sel = this.anios_select[i].TEXTO;
            }
          }
        })
        .catch(function(error) {
          
        });
    this.axios.get(this.ipapi + this.sec[0] + '/' + this.cort + '/' + this.anioI)
        .then((response) => {
          if (!response.data[0].text) {
            this.categorias = [];
            this.valor = [];
            var valor = '';
            this.totalG = 0;
            for (var i in response.data) {
              this.categorias.push(response.data[i].key);
              valor = valor + response.data[i].value + ',';
              this.totalG = this.totalG + response.data[i].value;
            }
            valor = valor.substring(0, valor.length - 1);
            valor = '[ { "text": "' + this.titulo[0] + '", "values": [' + valor + '] } ]';
            this.series = [];
            this.series.push(valor);
            this.series = JSON.parse(this.series);
            this.loading = false;
          } else {
            const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
            this.categorias = [];
            const categoria = [];
            let text = [];
            let valores = '';
            this.totalG = 0;
            for (var i in response.data) {
              categoria.push(response.data[i].key);
              text.push(response.data[i].text);
            }
            this.categorias = [...new Set(categoria)];
            this.leyenda = text;
            text = [...new Set(text)];
            var valor = '[';
            for (var i in text) {
              valor = valor + '{ ';
              valor = valor + '"text": "' + text[i] + '", ';
              valor = valor + '"backgroundColor": "' + colores[i] + '", ';
              valor = valor + '"values": [';
              valores = '';
              for (const j in response.data) {
                if (text[i] == response.data[j].text) {
                  valores = valores + response.data[j].value + ',';
                  this.totalG = this.totalG + response.data[j].value;
                }
              }
              valores = valores.substring(0, valores.length - 1);
              valor = valor + valores + ']},';
            }
            valor = valor.substring(0, valor.length - 1);
            valor = valor + ']';
            this.series.push(valor);
            this.series = JSON.parse(this.series);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.errCant += 1;
          if (this.errCant == 2) {
            this.loading = false;
          }
        });

    this.axios.get(this.ipapi + this.sec[0] + '/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anioI)
        .then((response) => {
          if (!response.data[0].text) {
            this.categorias = [];
            this.valor = [];
            var valor = '';
            this.totalG = 0;
            for (var i in response.data) {
              this.categorias.push(response.data[i].key);
              valor = valor + response.data[i].value + ',';
              this.totalG = this.totalG + response.data[i].value;
            }
            valor = valor.substring(0, valor.length - 1);
            valor = '[ { "text": "' + this.titulo[0] + '", "values": [' + valor + '] } ]';
            this.series = [];
            this.series.push(valor);
            this.series = JSON.parse(this.series);
            this.loading = false;
          } else {
            const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
            this.categorias = [];
            const categoria = [];
            let text = [];
            let valores = '';
            this.totalG = 0;
            for (var i in response.data) {
              categoria.push(response.data[i].key);
              text.push(response.data[i].text);
            }
            this.categorias = [...new Set(categoria)];
            this.leyenda = text;
            text = [...new Set(text)];
            var valor = '[';
            for (var i in text) {
              valor = valor + '{ ';
              valor = valor + '"text": "' + text[i] + '", ';
              valor = valor + '"backgroundColor": "' + colores[i] + '", ';
              valor = valor + '"values": [';
              valores = '';
              for (const j in response.data) {
                if (text[i] == response.data[j].text) {
                  valores = valores + response.data[j].value + ',';
                  this.totalG = this.totalG + response.data[j].value;
                }
              }
              valores = valores.substring(0, valores.length - 1);
              valor = valor + valores + ']},';
            }
            valor = valor.substring(0, valor.length - 1);
            valor = valor + ']';
            this.series.push(valor);
            this.series = JSON.parse(this.series);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.errCant += 1;
          if (this.errCant == 2) {
            this.loading = false;
          }
        });

    this.axios.get(this.ipapi + this.sec[1] + '/' + this.cort + '/' + this.anioI)
        .then((response) => {
          if (!response.data[0].text) {
            var valor = '';
            this.categorias2 = [];
            this.totalG2 = 0;
            for (var i in response.data) {
              this.categorias2.push(response.data[i].key);
              valor = valor + response.data[i].value + ',';
              this.totalG2 = this.totalG2 + response.data[i].value;
            }
            valor = valor.substring(0, valor.length - 1);
            valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
            this.series2 = [];
            this.series2.push(valor);
            this.series2 = JSON.parse(this.series2);
            this.loading2 = false;
          } else {
            const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
            this.categorias2 = [];
            const categoria = [];
            let text = [];
            let valores = '';
            this.totalG2 = 0;
            for (var i in response.data) {
              categoria.push(response.data[i].key);
              text.push(response.data[i].text);
            }
            this.categorias2 = [...new Set(categoria)];
            this.leyenda = text;
            text = [...new Set(text)];
            var valor = '[';
            for (var i in text) {
              valor = valor + '{ ';
              valor = valor + '"text": "' + text[i] + '", ';
              valor = valor + '"backgroundColor": "' + colores[i] + '", ';
              valor = valor + '"values": [';
              valores = '';
              for (const j in response.data) {
                if (text[i] == response.data[j].text) {
                  valores = valores + response.data[j].value + ',';
                  this.totalG2 = this.totalG2 + response.data[j].value;
                }
              }
              valores = valores.substring(0, valores.length - 1);
              valor = valor + valores + ']},';
            }
            valor = valor.substring(0, valor.length - 1);
            valor = valor + ']';
            this.series2.push(valor);
            this.series2 = JSON.parse(this.series2);
            this.loading2 = false;
          }
        })
        .catch((error) => {
          this.errCant2 += 1;
          if (this.errCant2 == 2) {
            this.loading2 = false;
          }
        });
    this.axios.get(this.ipapi + this.sec[1] + '/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anioI)
        .then((response) => {
          if (!response.data[0].text) {
            var valor = '';
            this.categorias2 = [];
            this.totalG2 = 0;
            for (var i in response.data) {
              this.categorias2.push(response.data[i].key);
              valor = valor + response.data[i].value + ',';
              this.totalG2 = this.totalG2 + response.data[i].value;
            }
            valor = valor.substring(0, valor.length - 1);
            valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
            this.series2 = [];
            this.series2.push(valor);
            this.series2 = JSON.parse(this.series2);
            this.loading2 = false;
          } else {
            const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
            this.categorias2 = [];
            const categoria = [];
            let text = [];
            let valores = '';
            this.totalG2 = 0;
            for (var i in response.data) {
              categoria.push(response.data[i].key);
              text.push(response.data[i].text);
            }
            this.categorias2 = [...new Set(categoria)];
            this.leyenda = text;
            text = [...new Set(text)];
            var valor = '[';
            for (var i in text) {
              valor = valor + '{ ';
              valor = valor + '"text": "' + text[i] + '", ';
              valor = valor + '"backgroundColor": "' + colores[i] + '", ';
              valor = valor + '"values": [';
              valores = '';
              for (const j in response.data) {
                if (text[i] == response.data[j].text) {
                  valores = valores + response.data[j].value + ',';
                  this.totalG2 = this.totalG2 + response.data[j].value;
                }
              }
              valores = valores.substring(0, valores.length - 1);
              valor = valor + valores + ']},';
            }
            valor = valor.substring(0, valor.length - 1);
            valor = valor + ']';
            this.series2.push(valor);
            this.series2 = JSON.parse(this.series2);
            this.loading2 = false;
          }
        })
        .catch((error) => {
          this.errCant2 += 1;
          if (this.errCant2 == 2) {
            this.loading2 = false;
          }
        });
  },
  components: {BarChart},
  data() {
    return {
      loading: false,
      loading2: false,
      download: false,
      errCant: 0,
      errCant2: 0,
      calendario: 1,
      categorias: [],
      series: [],
      leyenda: [],
      calendario2: 1,
      categorias2: [],
      series2: [],
      leyenda2: [],
      sec: [],
      titulo: [],
      anios_select: [],
      totalG: 0,
      totalG2: 0,
      txt_sel: '',
      tipGraf: '',
      viewEvent: {
        type: '',
        percentInView: 0,
        percentTop: 0,
        percentCenter: 0,
      },
    };
  },
  computed: {
    ...mapState([
      'ubicacionmaps',
      'cantGraficos',
      'ipapi',
      'ipact',
      'anioI',
    ]),
  },
};
</script>
