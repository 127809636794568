<template>
<div>
  <tabs>
    <tab :title = titulo[0]>
      <div class="row align-items-center">
        <div class="col-md-6"></div>
        <div class="col-md-2">
          <div class="tribunales" v-show="this.calendario == 1">
            <ul class="sub">
              <li v-for="(anio_sel, index) in anios_select" v-bind:key="anio_sel.VALOR"><a class="link" v-on:click="SelAnio(anio_sel.VALOR, index)">{{ anio_sel.TEXTO }}</a></li>
            </ul>
            <a class="btn" href="#">{{ txt_sel }}</a>
          </div>
        </div>
        <div v-if="download" class="col-md-2">
          <img src="@/assets/img/download.gif">
          Preparando...
        </div>
        <div v-if="!download" class="col-md-2">
          <div class="tribunales">
            <a class="btn" href="#">Descargar</a>
            <ul class="sub">
              <li><a class="link" v-on:click="Descargas('JSON', 0)">JSON</a></li>
              <li><a class="link" v-on:click="Descargas('CSV', 0)">CSV</a></li>
              <li><a class="link" v-on:click="Descargas('XLSX', 0)">Excel</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row">
          <div v-if="loading" class="col-md-12">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <img src="@/assets/img/loading.gif">
              Cargando...
            </div>
            <div class="col-md-4"></div>
          </div>
          <div v-if="!loading" class="col-md-12">
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_A == 'bar' || listaOrden[this.indice][0].GRAFICO_A == 'hbar'">
              <BarChart
                title = ''
                :tipGraf = 'tipGraf[0]'
                :categories = 'categorias'
                :series = 'series'
                :seccionG = 'tipo'
                :totalG = 'totalG'
                :calendario = 'calendario'
              />
            </div>
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_A == 'tabla'">
              <Table
                title = ''
                :series = 'series'
              />
            </div>
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_A == 'mixed'">
              <MixedChart
                title = ''
                :series = 'series'
                :series2 = 'seriesa'
                :series3 = 'seriesb'
                :categories = 'categorias'
                :categories2 = 'categoriasa'
                :categories3 = 'categoriasb'
              />
            </div>
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_A == 'mixed2'">
              <MixedChart2
                title = ''
                :series = 'series'
                :series2 = 'seriesa'
                :series3 = 'seriesb'
                :series4 = 'seriesc'
                :categories = 'categorias'
                :categories2 = 'categoriasa'
                :categories3 = 'categoriasb'
                :categories4 = 'categoriasc'
              />
            </div>
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_A == 'piemix'">
              <PieChart
                title = ''
                :series = 'series'
                :categories = 'categorias'
              />
            </div>
          </div>
      </div>
      <div class="row">
        <pre class="notas"><i>{{ txt_notas[0] }}</i></pre>
      </div>
    </tab>
    <tab :title = titulo[1]>
      <div class="row align-items-center">
        <div class="col-md-6"></div>
        <div class="col-md-2">
          <div class="tribunales" v-show="this.calendario2 == 1">
            <ul class="sub">
              <li v-for="(anio_sel, index) in anios_select" v-bind:key="anio_sel.VALOR"><a class="link" v-on:click="SelAnio(anio_sel.VALOR, index)">{{ anio_sel.TEXTO }}</a></li>
            </ul>
            <a class="btn" href="#">{{ txt_sel }}</a>
          </div>
        </div>
        <div class="col-md-2">
          <div class="tribunales">
            <a class="btn" href="#">Descargar</a>
            <ul class="sub">
              <li><a class="link" v-on:click="Descargas('JSON', 1)">JSON</a></li>
              <li><a class="link" v-on:click="Descargas('CSV', 1)">CSV</a></li>
              <li><a class="link" v-on:click="Descargas('XLSX', 1)">Excel</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row">
          <div v-if="loading" class="col-md-12">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <img src="@/assets/img/loading.gif">
              Cargando...
            </div>
            <div class="col-md-4"></div>
          </div>
          <div v-if="!loading" class="col-md-12">
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_B == 'bar' || listaOrden[this.indice][0].GRAFICO_B == 'hbar'">
              <BarChart
                title = ''
                :tipGraf = 'tipGraf[1]'
                :categories = 'categorias2'
                :series = 'series2'
                :seccionG = 'tipo'
                :totalG = 'totalG2'
                :calendario = 'calendario2'
              />
            </div>
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_B == 'tabla'">
              <Table
                title = ''
                :series = 'series2'
              />
            </div>
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_B == 'mixed'">
              <MixedChart
                title = ''
                :series = 'series2'
                :series2 = 'series2a'
                :series3 = 'series2b'
                :categories = 'categorias2'
                :categories2 = 'categorias2a'
                :categories3 = 'categorias2b'
              />
            </div>
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_B == 'mixed2'">
              <MixedChart2
                title = ''
                :series = 'series2'
                :series2 = 'series2a'
                :series3 = 'series2b'
                :series4 = 'series2c'
                :categories = 'categorias2'
                :categories2 = 'categorias2a'
                :categories3 = 'categorias2b'
                :categories4 = 'categorias2c'
              />
            </div>
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_B == 'piemix'">
              <PieChart
                title = ''
                :series = 'series2'
                :categories = 'categorias2'
              />
            </div>
          </div>
      </div>
      <div class="row">
        <pre class="notas"><i>{{ txt_notas[1] }}</i></pre>
      </div>
    </tab>
    <tab :title = titulo[2]>
      <div class="row align-items-center">
        <div class="col-md-6"></div>
        <div class="col-md-2">
          <div class="tribunales" v-show="this.calendario3 == 1">
            <ul class="sub">
              <li v-for="(anio_sel, index) in anios_select" v-bind:key="anio_sel.VALOR"><a class="link" v-on:click="SelAnio(anio_sel.VALOR, index)">{{ anio_sel.TEXTO }}</a></li>
            </ul>
            <a class="btn" href="#">{{ txt_sel }}</a>
          </div>
        </div>
        <div class="col-md-2">
          <div class="tribunales">
            <a class="btn" href="#">Descargar</a>
            <ul class="sub">
              <li><a class="link" v-on:click="Descargas('JSON', 2)">JSON</a></li>
              <li><a class="link" v-on:click="Descargas('CSV', 2)">CSV</a></li>
              <li><a class="link" v-on:click="Descargas('XLSX', 2)">Excel</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row">
          <div v-if="loading" class="col-md-12">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <img src="@/assets/img/loading.gif">
              Cargando...
            </div>
            <div class="col-md-4"></div>
          </div>
          <div v-if="!loading" class="col-md-12">
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_C == 'bar' || listaOrden[this.indice][0].GRAFICO_C == 'hbar'">
              <BarChart
                title = ''
                :tipGraf = 'tipGraf[2]'
                :categories = 'categorias3'
                :series = 'series3'
                :seccionG = 'tipo'
                :calendario = 'calendario3'
              />
            </div>
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_C == 'tabla'">
              <Table
                title = ''
                :series = 'series3'
              />
            </div>
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_C == 'mixed'">
              <MixedChart
                title = ''
                :series = 'series3'
                :series2 = 'series3a'
                :series3 = 'series3b'
                :categories = 'categorias3'
                :categories2 = 'categorias3a'
                :categories3 = 'categorias3b'
              />
            </div>
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_C == 'mixed2'">
              <MixedChart2
                title = ''
                :series = 'series3'
                :series2 = 'series3a'
                :series3 = 'series3b'
                :series4 = 'series3c'
                :categories = 'categorias3'
                :categories2 = 'categorias3a'
                :categories3 = 'categorias3b'
                :categories4 = 'categorias3c'
				:seccionG = 'seccionG'
              />
            </div>
            <div v-if="listaOrden[this.indice] && listaOrden[this.indice][0].GRAFICO_C == 'piemix'">
              <PieChart
                title = ''
                :series = 'series3'
                :categories = 'categorias3'
              />
            </div>
          </div>
      </div>
      <div class="row">
        <pre class="notas"><i>{{ txt_notas[2] }}</i></pre>
      </div>
    </tab>
  </tabs>
</div>
</template>
<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import BarChart from '@/components/Charts/BarChart';
import MixedChart from '@/components/Charts/MixedChart';
import MixedChart2 from '@/components/Charts/MixedChart2';
import XLSX from 'xlsx';
export default {
  props: {
    tipo: {
      type: String,
    },
    anio: {
      type: String
    },
    indice: {
      type: Number,
    },
    cgraf: {
      type: Array,
      default: () => [],
    },
    cort: {
      type: String,
    },
    trib: {
      type: String,
    },
    comp: {
      type: String,
    },
    listaOrden: {
      type: Array,
      default: () => [],
    },
    notas: {
      type: String,
    },
  },
  methods: {
    ...mapActions([
      'actubicacionMaps',
      'actcantgraficos',
    ]),
    SelAnio: function(a, index) {
      this.anio = a;
      this.txt_sel = this.anios_select[index].TEXTO;
      for (const i in this.cgraf) {
        if (this.cgraf[i][0].SECCION == this.tipo.toLowerCase()) {
          this.sec.push(this.cgraf[i][0].API_A);
          this.sec.push(this.cgraf[i][0].API_B);
          this.sec.push(this.cgraf[i][0].API_C);
          this.titulo.push(this.cgraf[i][0].SUBTITULO_A);
          this.titulo.push(this.cgraf[i][0].SUBTITULO_B);
          this.titulo.push(this.cgraf[i][0].SUBTITULO_C);
          this.tipGraf.push(this.cgraf[i][0].GRAFICO_A);
          this.tipGraf.push(this.cgraf[i][0].GRAFICO_B);
          this.tipGraf.push(this.cgraf[i][0].GRAFICO_C);
          this.calendario = this.cgraf[i][0].CAL_A;
          this.calendario2 = this.cgraf[i][0].CAL_B;
          this.calendario3 = this.cgraf[i][0].CAL_C;
        }
      }
      if (this.calendario == 1) {
        this.loading = true;
        this.axios.get(this.ipapi + this.sec[0] + '/' + this.cort + '/' + this.anio)
            .then((response) => {
              this.categorias = [];
              this.valor = [];
              var valor = '';
              if (this.listaOrden[this.indice][0].GRAFICO_A == 'bar' || this.listaOrden[this.indice][0].GRAFICO_A == 'hbar') {
                if (!response.data[0].text) {
                  var valor = '';
                  this.categorias = [];
                  for (var i in response.data) {
                    this.categorias.push(response.data[i].key);
                    valor = valor + response.data[i].value + ',';
                  }
                  this.totalG = 0;
                  for (var i in response.data) {
                    this.totalG = this.totalG + response.data[i].value;
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
                  this.series = [];
                  this.series.push(valor);
                  this.series = JSON.parse(this.series);
                  this.loading = false;
                } else {
                  const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
                  this.categorias = [];
                  const categoria = [];
                  let text = [];
                  this.series = [];
                  let valores = '';
                  for (var i in response.data) {
                    categoria.push(response.data[i].key);
                    text.push(response.data[i].text);
                  }
                  this.totalG = 0;
                  for (var i in response.data) {
                    this.totalG = this.totalG + response.data[i].value;
                  }
                  this.categorias = [...new Set(categoria)];
                  this.leyenda = text;
                  text = [...new Set(text)];
                  var valor = '[';
                  for (var i in text) {
                    valor = valor + '{ ';
                    valor = valor + '"text": "' + text[i] + '", ';
                    valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                    valor = valor + '"values": [';
                    valores = '';
                    for (const j in response.data) {
                      if (text[i] == response.data[j].text) {
                        valores = valores + response.data[j].value + ',';
                      }
                    }
                    valores = valores.substring(0, valores.length - 1);
                    valor = valor + valores + ']},';
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = valor + ']';
                  this.series.push(valor);
                  this.series = JSON.parse(this.series);
                  this.loading = false;
                }
              }
              if (this.listaOrden[this.indice][0].GRAFICO_A == 'tabla') {
                for (var i in response.data) {
                  this.categorias.push(response.data[i].key);
                  valor = valor + response.data[i].value + ',';
                }
                valor = valor.substring(0, valor.length - 1);
                valor = '[ { "values": [' + valor + '] } ]';
                this.series.push(valor);
                this.series = JSON.parse(this.series);
                this.loading = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_A == 'mixed') {
                for (var i in response.data) {
                  if (response.data[i].TIPO == 'valor1') {
                    this.series.push(response.data[i].MONTO);
                    this.categorias.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'valor2') {
                    this.seriesa.push(response.data[i].MONTO);
                    this.categoriasa.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje') {
                    this.seriesb.push(response.data[i].MONTO);
                    this.categoriasb.push(response.data[i].ITEM);
                  }
                }
                this.loading = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_A == 'mixed2') {
                for (var i in response.data) {
                  if (response.data[i].TIPO == 'valor1') {
                    this.series.push(response.data[i].MONTO);
                    this.categorias.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'valor2') {
                    this.seriesa.push(response.data[i].MONTO);
                    this.categoriasa.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje1') {
                    this.seriesb.push(response.data[i].MONTO);
                    this.categoriasb.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje2') {
                    this.seriesc.push(response.data[i].MONTO);
                    this.categoriasc.push(response.data[i].ITEM);
                  }
                }
                this.loading = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_A == 'piemix') {
                var valor = '[';
                for (var i in response.data) {
                  valor = valor + '{ ';
                  valor = valor + '"text": "' + response.data[i].key + '", ';
                  valor = valor + '"values": [' + response.data[i].value + ']';
                  valor = valor + ' }, ';
                }
                valor = valor.substring(0, valor.length - 2);
                valor = valor + ']';
                this.series = JSON.parse(valor);
                this.loading = false;
              }
            })
            .catch(function(error) {
              // console.log(error)
            });
        this.axios.get(this.ipapi + this.sec[0] + '/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
            .then((response) => {
              this.categorias = [];
              this.valor = [];
              var valor = '';
              if (this.listaOrden[this.indice][0].GRAFICO_A == 'bar' || this.listaOrden[this.indice][0].GRAFICO_A == 'hbar') {
                if (!response.data[0].text) {
                  var valor = '';
                  this.categorias = [];
                  for (var i in response.data) {
                    this.categorias.push(response.data[i].key);
                    valor = valor + response.data[i].value + ',';
                  }
                  this.totalG = 0;
                  for (var i in response.data) {
                    this.totalG = this.totalG + response.data[i].value;
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
                  this.series = [];
                  this.series.push(valor);
                  this.series = JSON.parse(this.series);
                  this.loading = false;
                } else {
                  const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
                  this.categorias = [];
                  const categoria = [];
                  let text = [];
                  this.series = [];
                  let valores = '';
                  for (var i in response.data) {
                    categoria.push(response.data[i].key);
                    text.push(response.data[i].text);
                  }
                  this.totalG = 0;
                  for (var i in response.data) {
                    this.totalG = this.totalG + response.data[i].value;
                  }
                  this.categorias = [...new Set(categoria)];
                  this.leyenda = text;
                  text = [...new Set(text)];
                  var valor = '[';
                  for (var i in text) {
                    valor = valor + '{ ';
                    valor = valor + '"text": "' + text[i] + '", ';
                    valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                    valor = valor + '"values": [';
                    valores = '';
                    for (const j in response.data) {
                      if (text[i] == response.data[j].text) {
                        valores = valores + response.data[j].value + ',';
                      }
                    }
                    valores = valores.substring(0, valores.length - 1);
                    valor = valor + valores + ']},';
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = valor + ']';
                  this.series.push(valor);
                  this.series = JSON.parse(this.series);
                  this.loading = false;
                }
              }
              if (this.listaOrden[this.indice][0].GRAFICO_A == 'tabla') {
                for (var i in response.data) {
                  this.categorias.push(response.data[i].key);
                  valor = valor + response.data[i].value + ',';
                }
                valor = valor.substring(0, valor.length - 1);
                valor = '[ { "values": [' + valor + '] } ]';
                this.series.push(valor);
                this.series = JSON.parse(this.series);
                this.loading = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_A == 'mixed') {
                for (var i in response.data) {
                  if (response.data[i].TIPO == 'valor1') {
                    this.series.push(response.data[i].MONTO);
                    this.categorias.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'valor2') {
                    this.seriesa.push(response.data[i].MONTO);
                    this.categoriasa.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje') {
                    this.seriesb.push(response.data[i].MONTO);
                    this.categoriasb.push(response.data[i].ITEM);
                  }
                }
                this.loading = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_A == 'mixed2') {
                for (var i in response.data) {
                  if (response.data[i].TIPO == 'valor1') {
                    this.series.push(response.data[i].MONTO);
                    this.categorias.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'valor2') {
                    this.seriesa.push(response.data[i].MONTO);
                    this.categoriasa.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje1') {
                    this.seriesb.push(response.data[i].MONTO);
                    this.categoriasb.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje2') {
                    this.seriesc.push(response.data[i].MONTO);
                    this.categoriasc.push(response.data[i].ITEM);
                  }
                }
                this.loading = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_A == 'piemix') {
                var valor = '[';
                for (var i in response.data) {
                  valor = valor + '{ ';
                  valor = valor + '"text": "' + response.data[i].key + '", ';
                  valor = valor + '"values": [' + response.data[i].value + ']';
                  valor = valor + ' }, ';
                }
                valor = valor.substring(0, valor.length - 2);
                valor = valor + ']';
                this.series = JSON.parse(valor);
                this.loading = false;
              }
            })
            .catch(function(error) {
              // console.log(error)
            });
      }
      if (this.calendario2 == 1) {
        this.loading2 = true;
        this.axios.get(this.ipapi + this.sec[1] + '/' + this.cort + '/' + this.anio)
            .then((response) => {
              this.categorias2 = [];
              this.valor = [];
              var valor = '';
              if (this.listaOrden[this.indice][0].GRAFICO_B == 'bar' || this.listaOrden[this.indice][0].GRAFICO_B == 'hbar') {
                if (!response.data[0].text) {
                  var valor = '';
                  this.categorias2 = [];
                  for (var i in response.data) {
                    this.categorias2.push(response.data[i].key);
                    valor = valor + response.data[i].value + ',';
                  }
                  this.totalG2 = 0;
                  for (var i in response.data) {
                    this.totalG2 = this.totalG2 + response.data[i].value;
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
                  this.series2 = [];
                  this.series2.push(valor);
                  this.series2 = JSON.parse(this.series2);
                  this.loading2 = false;
                } else {
                  const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
                  this.categorias2 = [];
                  const categoria = [];
                  let text = [];
                  this.series2 = [];
                  let valores = '';
                  for (var i in response.data) {
                    categoria.push(response.data[i].key);
                    text.push(response.data[i].text);
                  }
                  this.totalG2 = 0;
                  for (var i in response.data) {
                    this.totalG2 = this.totalG2 + response.data[i].value;
                  }
                  this.categorias2 = [...new Set(categoria)];
                  this.leyenda2 = text;
                  text = [...new Set(text)];
                  var valor = '[';
                  for (var i in text) {
                    valor = valor + '{ ';
                    valor = valor + '"text": "' + text[i] + '", ';
                    valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                    valor = valor + '"values": [';
                    valores = '';
                    for (const j in response.data) {
                      if (text[i] == response.data[j].text) {
                        valores = valores + response.data[j].value + ',';
                      }
                    }
                    valores = valores.substring(0, valores.length - 1);
                    valor = valor + valores + ']},';
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = valor + ']';
                  this.series2.push(valor);
                  this.series2 = JSON.parse(this.series2);
                  this.loading2 = false;
                }
              }
              if (this.listaOrden[this.indice][0].GRAFICO_B == 'tabla') {
                for (var i in response.data) {
                  this.categorias2.push(response.data[i].key);
                  valor = valor + response.data[i].value + ',';
                }
                valor = valor.substring(0, valor.length - 1);
                valor = '[ { "values": [' + valor + '] } ]';
                this.series2.push(valor);
                this.series2 = JSON.parse(this.series2);
                this.loading2 = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_B == 'mixed') {
                for (var i in response.data) {
                  if (response.data[i].TIPO == 'valor1') {
                    this.series2.push(response.data[i].MONTO);
                    this.categorias2.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'valor2') {
                    this.series2a.push(response.data[i].MONTO);
                    this.categorias2a.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje') {
                    this.series2b.push(response.data[i].MONTO);
                    this.categorias2b.push(response.data[i].ITEM);
                  }
                }
                this.loading2 = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_B == 'mixed2') {
                for (var i in response.data) {
                  if (response.data[i].TIPO == 'valor1') {
                    this.series2.push(response.data[i].MONTO);
                    this.categorias2.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'valor2') {
                    this.series2a.push(response.data[i].MONTO);
                    this.categorias2a.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje1') {
                    this.series2b.push(response.data[i].MONTO);
                    this.categorias2b.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje2') {
                    this.series2c.push(response.data[i].MONTO);
                    this.categorias2c.push(response.data[i].ITEM);
                  }
                }
                this.loading2 = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_B == 'piemix') {
                var valor = '[';
                for (var i in response.data) {
                  valor = valor + '{ ';
                  valor = valor + '"text": "' + response.data[i].key + '", ';
                  valor = valor + '"values": [' + response.data[i].value + ']';
                  valor = valor + ' }, ';
                }
                valor = valor.substring(0, valor.length - 2);
                valor = valor + ']';
                this.series2 = JSON.parse(valor);
                this.loading2 = false;
              }
            })
            .catch(function(error) {
              // console.log(error)
            });
        this.axios.get(this.ipapi + this.sec[1] + '/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
            .then((response) => {
              this.categorias2 = [];
              this.valor = [];
              var valor = '';
              if (this.listaOrden[this.indice][0].GRAFICO_B == 'bar' || this.listaOrden[this.indice][0].GRAFICO_B == 'hbar') {
                if (!response.data[0].text) {
                  var valor = '';
                  this.categorias2 = [];
                  for (var i in response.data) {
                    this.categorias2.push(response.data[i].key);
                    valor = valor + response.data[i].value + ',';
                  }
                  this.totalG2 = 0;
                  for (var i in response.data) {
                    this.totalG2 = this.totalG2 + response.data[i].value;
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
                  this.series2 = [];
                  this.series2.push(valor);
                  this.series2 = JSON.parse(this.series2);
                  this.loading2 = false;
                } else {
                  const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
                  this.categorias2 = [];
                  this.series2 = [];
                  const categoria = [];
                  let text = [];
                  let valores = '';
                  for (var i in response.data) {
                    categoria.push(response.data[i].key);
                    text.push(response.data[i].text);
                  }
                  this.totalG2 = 0;
                  for (var i in response.data) {
                    this.totalG2 = this.totalG2 + response.data[i].value;
                  }
                  this.categorias2 = [...new Set(categoria)];
                  this.leyenda2 = text;
                  text = [...new Set(text)];
                  var valor = '[';
                  for (var i in text) {
                    valor = valor + '{ ';
                    valor = valor + '"text": "' + text[i] + '", ';
                    valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                    valor = valor + '"values": [';
                    valores = '';
                    for (const j in response.data) {
                      if (text[i] == response.data[j].text) {
                        valores = valores + response.data[j].value + ',';
                      }
                    }
                    valores = valores.substring(0, valores.length - 1);
                    valor = valor + valores + ']},';
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = valor + ']';
                  this.series2.push(valor);
                  this.series2 = JSON.parse(this.series2);
                  this.loading2 = false;
                }
              }
              if (this.listaOrden[this.indice][0].GRAFICO_B == 'tabla') {
                for (var i in response.data) {
                  this.categorias2.push(response.data[i].key);
                  valor = valor + response.data[i].value + ',';
                }
                valor = valor.substring(0, valor.length - 1);
                valor = '[ { "values": [' + valor + '] } ]';
                this.series2.push(valor);
                this.series2 = JSON.parse(this.series2);
                this.loading2 = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_B == 'mixed') {
                for (var i in response.data) {
                  if (response.data[i].TIPO == 'valor1') {
                    this.series2.push(response.data[i].MONTO);
                    this.categorias2.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'valor2') {
                    this.series2a.push(response.data[i].MONTO);
                    this.categorias2a.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje') {
                    this.series2b.push(response.data[i].MONTO);
                    this.categorias2b.push(response.data[i].ITEM);
                  }
                }
                this.loading2 = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_B == 'mixed2') {
                for (var i in response.data) {
                  if (response.data[i].TIPO == 'valor1') {
                    this.series2.push(response.data[i].MONTO);
                    this.categorias2.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'valor2') {
                    this.series2a.push(response.data[i].MONTO);
                    this.categorias2a.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje1') {
                    this.series2b.push(response.data[i].MONTO);
                    this.categorias2b.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje2') {
                    this.series2c.push(response.data[i].MONTO);
                    this.categorias2c.push(response.data[i].ITEM);
                  }
                }
                this.loading2 = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_B == 'piemix') {
                var valor = '[';
                for (var i in response.data) {
                  valor = valor + '{ ';
                  valor = valor + '"text": "' + response.data[i].key + '", ';
                  valor = valor + '"values": [' + response.data[i].value + ']';
                  valor = valor + ' }, ';
                }
                valor = valor.substring(0, valor.length - 2);
                valor = valor + ']';
                this.series2 = JSON.parse(valor);
                this.loading2 = false;
              }
            })
            .catch(function(error) {
              // console.log(error)
            });
      }
      if (this.calendario3 == 1) {
        this.loading3 = true;
        this.axios.get(this.ipapi + this.sec[2] + '/' + this.cort + '/' + this.anio)
            .then((response) => {
              this.categorias3 = [];
              this.valor = [];
              var valor = '';
              if (this.listaOrden[this.indice][0].GRAFICO_C == 'bar' || this.listaOrden[this.indice][0].GRAFICO_C == 'hbar') {
                if (!response.data[0].text) {
                  var valor = '';
                  this.categorias3 = [];
                  for (var i in response.data) {
                    this.categorias3.push(response.data[i].key);
                    valor = valor + response.data[i].value + ',';
                  }
                  this.totalG3 = 0;
                  for (var i in response.data) {
                    this.totalG3 = this.totalG3 + response.data[i].value;
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
                  this.series3 = [];
                  this.series3.push(valor);
                  this.series3 = JSON.parse(this.series3);
                  this.loading3 = false;
                } else {
                  const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
                  this.categorias3 = [];
                  const categoria = [];
                  let text = [];
                  this.series3 = [];
                  let valores = '';
                  for (var i in response.data) {
                    categoria.push(response.data[i].key);
                    text.push(response.data[i].text);
                  }
                  this.totalG3 = 0;
                  for (var i in response.data) {
                    this.totalG3 = this.totalG3 + response.data[i].value;
                  }
                  this.categorias3 = [...new Set(categoria)];
                  this.leyenda3 = text;
                  text = [...new Set(text)];
                  var valor = '[';
                  for (var i in text) {
                    valor = valor + '{ ';
                    valor = valor + '"text": "' + text[i] + '", ';
                    valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                    valor = valor + '"values": [';
                    valores = '';
                    for (const j in response.data) {
                      if (text[i] == response.data[j].text) {
                        valores = valores + response.data[j].value + ',';
                      }
                    }
                    valores = valores.substring(0, valores.length - 1);
                    valor = valor + valores + ']},';
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = valor + ']';
                  this.series3.push(valor);
                  this.series3 = JSON.parse(this.series3);
                  this.loading3 = false;
                }
              }
              if (this.listaOrden[this.indice][0].GRAFICO_C == 'tabla') {
                for (var i in response.data) {
                  this.categorias3.push(response.data[i].key);
                  valor = valor + response.data[i].value + ',';
                }
                valor = valor.substring(0, valor.length - 1);
                valor = '[ { "text": "' + this.titulo[2] + '", "values": [' + valor + '] } ]';
                this.series3.push(valor);
                this.series3 = JSON.parse(this.series3);
                this.loading3 = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_C == 'mixed') {
                for (var i in response.data) {
                  if (response.data[i].TIPO == 'valor1') {
                    this.series3.push(response.data[i].MONTO);
                    this.categorias3.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'valor2') {
                    this.series3a.push(response.data[i].MONTO);
                    this.categorias3a.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje') {
                    this.series3b.push(response.data[i].MONTO);
                    this.categorias3b.push(response.data[i].ITEM);
                  }
                }
                this.loading3 = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_C == 'mixed2') {
                this.seccionG = this.sec[2];
                for (var i in response.data) {
                  if (response.data[i].TIPO == 'valor1') {
                    this.series3.push(response.data[i].MONTO);
                    this.categorias3.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'valor2') {
                    this.series3a.push(response.data[i].MONTO);
                    this.categorias3a.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje1') {
                    this.series3b.push(response.data[i].MONTO);
                    this.categorias3b.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje2') {
                    this.series3c.push(response.data[i].MONTO);
                    this.categorias3c.push(response.data[i].ITEM);
                  }
                }
                this.loading3 = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_C == 'piemix') {
                var valor = '[';
                for (var i in response.data) {
                  valor = valor + '{ ';
                  valor = valor + '"text": "' + response.data[i].key + '", ';
                  valor = valor + '"values": [' + response.data[i].value + ']';
                  valor = valor + ' }, ';
                }
                valor = valor.substring(0, valor.length - 2);
                valor = valor + ']';
                this.series3 = JSON.parse(valor);
                this.loading3 = false;
              }
            })
            .catch(function(error) {
              // console.log(error)
            });
        this.axios.get(this.ipapi + this.sec[2] + '/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
            .then((response) => {
              this.categorias3 = [];
              this.valor = [];
              var valor = '';
              if (this.listaOrden[this.indice][0].GRAFICO_C == 'bar' || this.listaOrden[this.indice][0].GRAFICO_C == 'hbar') {
                if (!response.data[0].text) {
                  var valor = '';
                  this.categorias3 = [];
                  for (var i in response.data) {
                    this.categorias3.push(response.data[i].key);
                    valor = valor + response.data[i].value + ',';
                  }
                  this.totalG3 = 0;
                  for (var i in response.data) {
                    this.totalG3 = this.totalG3 + response.data[i].value;
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
                  this.series3 = [];
                  this.series3.push(valor);
                  this.series3 = JSON.parse(this.series3);
                  this.loading3 = false;
                } else {
                  const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
                  this.categorias3 = [];
                  const categoria = [];
                  let text = [];
                  this.series3 = [];
                  let valores = '';
                  for (var i in response.data) {
                    categoria.push(response.data[i].key);
                    text.push(response.data[i].text);
                  }
                  this.totalG3 = 0;
                  for (var i in response.data) {
                    this.totalG3 = this.totalG3 + response.data[i].value;
                  }
                  this.categorias3 = [...new Set(categoria)];
                  this.leyenda3 = text;
                  text = [...new Set(text)];
                  var valor = '[';
                  for (var i in text) {
                    valor = valor + '{ ';
                    valor = valor + '"text": "' + text[i] + '", ';
                    valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                    valor = valor + '"values": [';
                    valores = '';
                    for (const j in response.data) {
                      if (text[i] == response.data[j].text) {
                        valores = valores + response.data[j].value + ',';
                      }
                    }
                    valores = valores.substring(0, valores.length - 1);
                    valor = valor + valores + ']},';
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = valor + ']';
                  this.series3.push(valor);
                  this.series3 = JSON.parse(this.series3);
                  this.loading3 = false;
                }
              }
              if (this.listaOrden[this.indice][0].GRAFICO_C == 'tabla') {
                for (var i in response.data) {
                  this.categorias3.push(response.data[i].key);
                  valor = valor + response.data[i].value + ',';
                }
                valor = valor.substring(0, valor.length - 1);
                valor = '[ { "text": "' + this.titulo[2] + '", "values": [' + valor + '] } ]';
                this.series3.push(valor);
                this.series3 = JSON.parse(this.series3);
                this.loading3 = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_C == 'mixed') {
                series3 = [];
                series3a = [];
                series3b = [];
                categorias3 = [];
                categorias3a = [];
                categorias3b = [];
                for (var i in response.data) {
                  if (response.data[i].TIPO == 'valor1') {
                    this.series3.push(response.data[i].MONTO);
                    this.categorias3.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'valor2') {
                    this.series3a.push(response.data[i].MONTO);
                    this.categorias3a.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje') {
                    this.series3b.push(response.data[i].MONTO);
                    this.categorias3b.push(response.data[i].ITEM);
                  }
                }
                this.loading3 = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_C == 'mixed2') {
                series3 = [];
                series3a = [];
                series3b = [];
                series3c = [];
                categorias3 = [];
                categorias3a = [];
                categorias3b = [];
                categorias3c = [];
                this.seccionG = this.sec[2];
                for (var i in response.data) {
                  if (response.data[i].TIPO == 'valor1') {
                    this.series3.push(response.data[i].MONTO);
                    this.categorias3.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'valor2') {
                    this.series3a.push(response.data[i].MONTO);
                    this.categorias3a.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje1') {
                    this.series3b.push(response.data[i].MONTO);
                    this.categorias3b.push(response.data[i].ITEM);
                  }
                  if (response.data[i].TIPO == 'porcentaje2') {
                    this.series3c.push(response.data[i].MONTO);
                    this.categorias3c.push(response.data[i].ITEM);
                  }
                }
                this.loading3 = false;
              }
              if (this.listaOrden[this.indice][0].GRAFICO_C == 'piemix') {
                var valor = '[';
                for (var i in response.data) {
                  valor = valor + '{ ';
                  valor = valor + '"text": "' + response.data[i].key + '", ';
                  valor = valor + '"values": [' + response.data[i].value + ']';
                  valor = valor + ' }, ';
                }
                valor = valor.substring(0, valor.length - 2);
                valor = valor + ']';
                this.series3 = JSON.parse(valor);
                this.loading3 = false;
              }
            })
            .catch(function(error) {
              // console.log(error)
            });
      }
    },
    convertToCSV: function(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      const header = Object.keys(array[0]);
      let str = '';
      let linea = '';
      for (let i = 0; i < header.length; i++) {
        linea += header[i] + '\t';
      }
      str = linea.substring(0, linea.length - 1) + '\r\n';
      for (let i = 0; i < array.length; i++) {
        let line = '';
        for (const index in array[i]) {
          if (line != '') line += '\t';
          line += array[i][index];
        }
        str += line + '\r\n';
      }
      return str;
    },
    Descargas: function(tipo, pes) {
      this.download = true;
      if (tipo == 'JSON') {
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.anio)
            .then((response) => {
              const data = JSON.stringify(response.data);
              const blob = new Blob([data], {type: 'text/plain'});
              const e = document.createEvent('MouseEvents');
              const a = document.createElement('a');
              a.download = 'datos.json';
              a.href = window.URL.createObjectURL(blob);
              a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
              e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
              a.dispatchEvent(e);
              this.download = false;
            })
            .catch(function(error) {
              // console.log(error)
            });
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
            .then((response) => {
              const data = JSON.stringify(response.data);
              const blob = new Blob([data], {type: 'text/plain'});
              const e = document.createEvent('MouseEvents');
              const a = document.createElement('a');
              a.download = 'datos.json';
              a.href = window.URL.createObjectURL(blob);
              a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
              e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
              a.dispatchEvent(e);
              this.download = false;
            })
            .catch(function(error) {
              // console.log(error)
            });
      }
      if (tipo == 'CSV') {
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.anio)
            .then((response) => {
              const data = this.convertToCSV(response.data);
              const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
              const e = document.createEvent('MouseEvents');
              const a = document.createElement('a');
              a.download = 'datos.csv';
              a.href = window.URL.createObjectURL(blob);
              a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
              e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
              a.dispatchEvent(e);
              this.download = false;
            })
            .catch(function(error) {
              // console.log(error)
            });
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
            .then((response) => {
              const data = this.convertToCSV(response.data);
              const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
              const e = document.createEvent('MouseEvents');
              const a = document.createElement('a');
              a.download = 'datos.csv';
              a.href = window.URL.createObjectURL(blob);
              a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
              e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
              a.dispatchEvent(e);
              this.download = false;
            })
            .catch(function(error) {
              // console.log(error)
            });
      }
      if (tipo == 'XLSX') {
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.anio)
            .then((response) => {
              const data = XLSX.utils.json_to_sheet(response.data);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, data, this.comp);
              XLSX.writeFile(wb, 'datos.xlsx');
              this.download = false;
            })
            .catch(function(error) {
              // console.log(error)
            });
        this.axios.get(this.ipapi + this.sec[pes] + '_detalle/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anio)
            .then((response) => {
              const data = XLSX.utils.json_to_sheet(response.data);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, data, this.comp);
              XLSX.writeFile(wb, 'datos.xlsx');
              this.download = false;
            })
            .catch(function(error) {
              // console.log(error)
            });
      }
    },
  },
  mounted() {
    this.loading = true;
    this.categorias = [];
    this.categorias2 = [];
    this.categorias3 = [];
    this.series = [];
    this.series2 = [];
    this.series3 = [];
    for (const i in this.cgraf) {
      if (this.cgraf[i][0].SECCION == this.tipo.toLowerCase()) {
        this.sec.push(this.cgraf[i][0].API_A);
        this.sec.push(this.cgraf[i][0].API_B);
        this.sec.push(this.cgraf[i][0].API_C);
        this.titulo.push(this.cgraf[i][0].SUBTITULO_A);
        this.titulo.push(this.cgraf[i][0].SUBTITULO_B);
        this.titulo.push(this.cgraf[i][0].SUBTITULO_C);
        this.tipGraf.push(this.cgraf[i][0].GRAFICO_A);
        this.tipGraf.push(this.cgraf[i][0].GRAFICO_B);
        this.tipGraf.push(this.cgraf[i][0].GRAFICO_C);
        this.calendario = this.cgraf[i][0].CAL_A;
        this.calendario2 = this.cgraf[i][0].CAL_B;
        this.calendario3 = this.cgraf[i][0].CAL_C;
        this.txt_notas.push(this.cgraf[i][0].NOTAS_A);
        this.txt_notas.push(this.cgraf[i][0].NOTAS_B);
        this.txt_notas.push(this.cgraf[i][0].NOTAS_C);
      }
    }
    this.axios.get(this.ipapi + 'select_anios/Select')
        .then((response) => {
          this.anios_select = response.data;
          for (const i in this.anios_select) {
            // if (this.anios_select[i].SELECCIONADO) {
            if (this.anios_select[i].VALOR == this.anioI) {
              this.txt_sel = this.anios_select[i].TEXTO;
            }
          }
        })
        .catch(function(error) {
          // console.log(error)
        });
    this.axios.get(this.ipapi + this.sec[0] + '/' + this.cort + '/' + this.anioI)
        .then((response) => {
          this.categorias = [];
          this.valor = [];
          var valor = '';
          if (this.listaOrden[this.indice][0].GRAFICO_A == 'bar' || this.listaOrden[this.indice][0].GRAFICO_A == 'hbar') {
            if (!response.data[0].text) {
                  var valor = '';
                  this.categorias = [];
                  for (var i in response.data) {
                    this.categorias.push(response.data[i].key);
                    valor = valor + response.data[i].value + ',';
                  }
                  this.totalG = 0;
                  for (var i in response.data) {
                    this.totalG = this.totalG + response.data[i].value;
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
                  this.series = [];
                  this.series.push(valor);
                  this.series = JSON.parse(this.series);
                  this.loading = false;
                } else {
                  const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
                  this.categorias = [];
                  const categoria = [];
                  let text = [];
                  this.series = [];
                  let valores = '';
                  for (var i in response.data) {
                    categoria.push(response.data[i].key);
                    text.push(response.data[i].text);
                  }
                  this.totalG = 0;
                  for (var i in response.data) {
                    this.totalG = this.totalG + response.data[i].value;
                  }
                  this.categorias = [...new Set(categoria)];
                  this.leyenda = text;
                  text = [...new Set(text)];
                  var valor = '[';
                  for (var i in text) {
                    valor = valor + '{ ';
                    valor = valor + '"text": "' + text[i] + '", ';
                    valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                    valor = valor + '"values": [';
                    valores = '';
                    for (const j in response.data) {
                      if (text[i] == response.data[j].text) {
                        valores = valores + response.data[j].value + ',';
                      }
                    }
                    valores = valores.substring(0, valores.length - 1);
                    valor = valor + valores + ']},';
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = valor + ']';
                  this.series.push(valor);
                  this.series = JSON.parse(this.series);
                  this.loading = false;
                }
          }
          if (this.listaOrden[this.indice][0].GRAFICO_A == 'tabla') {
            for (var i in response.data) {
              this.categorias.push(response.data[i].key);
              valor = valor + response.data[i].value + ',';
            }
            valor = valor.substring(0, valor.length - 1);
            valor = '[ { "values": [' + valor + '] } ]';
            this.series.push(valor);
            this.series = JSON.parse(this.series);
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_A == 'mixed') {
            for (var i in response.data) {
              if (response.data[i].TIPO == 'valor1') {
                this.series.push(response.data[i].MONTO);
                this.categorias.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'valor2') {
                this.seriesa.push(response.data[i].MONTO);
                this.categoriasa.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje') {
                this.seriesb.push(response.data[i].MONTO);
                this.categoriasb.push(response.data[i].ITEM);
              }
            }
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_A == 'mixed2') {
            for (var i in response.data) {
              if (response.data[i].TIPO == 'valor1') {
                this.series.push(response.data[i].MONTO);
                this.categorias.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'valor2') {
                this.seriesa.push(response.data[i].MONTO);
                this.categoriasa.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje1') {
                this.seriesb.push(response.data[i].MONTO);
                this.categoriasb.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje2') {
                this.seriesc.push(response.data[i].MONTO);
                this.categoriasc.push(response.data[i].ITEM);
              }
            }
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_A == 'piemix') {
            var valor = '[';
            for (var i in response.data) {
              valor = valor + '{ ';
              valor = valor + '"text": "' + response.data[i].key + '", ';
              valor = valor + '"values": [' + response.data[i].value + ']';
              valor = valor + ' }, ';
            }
            valor = valor.substring(0, valor.length - 2);
            valor = valor + ']';
            this.series = JSON.parse(valor);
            this.loading = false;
          }
        })
        .catch(function(error) {
          // console.log(error)
        });
    this.axios.get(this.ipapi + this.sec[0] + '/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anioI)
        .then((response) => {
          this.categorias = [];
          this.valor = [];
          var valor = '';
          if (this.listaOrden[this.indice][0].GRAFICO_A == 'bar' || this.listaOrden[this.indice][0].GRAFICO_A == 'hbar') {
            for (var i in response.data) {
              this.categorias.push(response.data[i].key);
              valor = valor + response.data[i].value + ',';
            }
            this.totalG = 0;
            for (var i in response.data) {
              this.totalG = this.totalG + response.data[i].value;
            }
            valor = valor.substring(0, valor.length - 1);
            valor = '[ { "text": "' + this.titulo[0] + '", "values": [' + valor + '] } ]';
            this.series = [];
            this.series.push(valor);
            this.series = JSON.parse(this.series);
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_A == 'tabla') {
            for (var i in response.data) {
              this.categorias.push(response.data[i].key);
              valor = valor + response.data[i].value + ',';
            }
            valor = valor.substring(0, valor.length - 1);
            valor = '[ { "values": [' + valor + '] } ]';
            this.series.push(valor);
            this.series = JSON.parse(this.series);
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_A == 'mixed') {
            for (var i in response.data) {
              if (response.data[i].TIPO == 'valor1') {
                this.series.push(response.data[i].MONTO);
                this.categorias.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'valor2') {
                this.seriesa.push(response.data[i].MONTO);
                this.categoriasa.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje') {
                this.seriesb.push(response.data[i].MONTO);
                this.categoriasb.push(response.data[i].ITEM);
              }
            }
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_A == 'mixed2') {
            for (var i in response.data) {
              if (response.data[i].TIPO == 'valor1') {
                this.series.push(response.data[i].MONTO);
                this.categorias.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'valor2') {
                this.seriesa.push(response.data[i].MONTO);
                this.categoriasa.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje1') {
                this.seriesb.push(response.data[i].MONTO);
                this.categoriasb.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje2') {
                this.seriesc.push(response.data[i].MONTO);
                this.categoriasc.push(response.data[i].ITEM);
              }
            }
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_A == 'piemix') {
            var valor = '[';
            for (var i in response.data) {
              valor = valor + '{ ';
              valor = valor + '"text": "' + response.data[i].key + '", ';
              valor = valor + '"values": [' + response.data[i].value + ']';
              valor = valor + ' }, ';
            }
            valor = valor.substring(0, valor.length - 2);
            valor = valor + ']';
            this.series = JSON.parse(valor);
            this.loading = false;
          }
        })
        .catch(function(error) {
          // console.log(error)
        });

    this.axios.get(this.ipapi + this.sec[1] + '/' + this.cort + '/' + this.anioI)
        .then((response) => {
          this.categorias2 = [];
          this.valor = [];
          var valor = '';
          if (this.listaOrden[this.indice][0].GRAFICO_B == 'bar' || this.listaOrden[this.indice][0].GRAFICO_B == 'hbar') {
            if (!response.data[0].text) {
              var valor = '';
              this.categorias2 = [];
              for (var i in response.data) {
                this.categorias2.push(response.data[i].key);
                valor = valor + response.data[i].value + ',';
              }
              this.totalG2 = 0;
                  for (var i in response.data) {
                    this.totalG2 = this.totalG2 + response.data[i].value;
                  }
              valor = valor.substring(0, valor.length - 1);
              valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
              this.series2 = [];
              this.series2.push(valor);
              this.series2 = JSON.parse(this.series2);
              this.loading2 = false;
            } else {
              const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
              this.categorias2 = [];
              const categoria = [];
              let text = [];
              this.series2 = [];
              let valores = '';
              for (var i in response.data) {
                categoria.push(response.data[i].key);
                text.push(response.data[i].text);
              }
              this.totalG2 = 0;
              for (var i in response.data) {
                this.totalG2 = this.totalG2 + response.data[i].value;
              }
              this.categorias2 = [...new Set(categoria)];
              this.leyenda2 = text;
              text = [...new Set(text)];
              var valor = '[';
              for (var i in text) {
                valor = valor + '{ ';
                valor = valor + '"text": "' + text[i] + '", ';
                valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                valor = valor + '"values": [';
                valores = '';
                for (const j in response.data) {
                  if (text[i] == response.data[j].text) {
                    valores = valores + response.data[j].value + ',';
                  }
                }
                valores = valores.substring(0, valores.length - 1);
                valor = valor + valores + ']},';
              }
              valor = valor.substring(0, valor.length - 1);
              valor = valor + ']';
              this.series2.push(valor);
              this.series2 = JSON.parse(this.series2);
              this.loading2 = false;
            }
          }
          if (this.listaOrden[this.indice][0].GRAFICO_B == 'tabla') {
            for (var i in response.data) {
              this.categorias2.push(response.data[i].key);
              valor = valor + response.data[i].value + ',';
            }
            valor = valor.substring(0, valor.length - 1);
            valor = '[ { "values": [' + valor + '] } ]';
            this.series2.push(valor);
            this.series2 = JSON.parse(this.series2);
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_B == 'mixed') {
            for (var i in response.data) {
              if (response.data[i].TIPO == 'valor1') {
                this.series2.push(response.data[i].MONTO);
                this.categorias2.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'valor2') {
                this.series2a.push(response.data[i].MONTO);
                this.categorias2a.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje') {
                this.series2b.push(response.data[i].MONTO);
                this.categorias2b.push(response.data[i].ITEM);
              }
            }
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_B == 'mixed2') {
            for (var i in response.data) {
              if (response.data[i].TIPO == 'valor1') {
                this.series2.push(response.data[i].MONTO);
                this.categorias2.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'valor2') {
                this.series2a.push(response.data[i].MONTO);
                this.categorias2a.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje1') {
                this.series2b.push(response.data[i].MONTO);
                this.categorias2b.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje2') {
                this.series2c.push(response.data[i].MONTO);
                this.categorias2c.push(response.data[i].ITEM);
              }
            }
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_B == 'piemix') {
            var valor = '[';
            for (var i in response.data) {
              valor = valor + '{ ';
              valor = valor + '"text": "' + response.data[i].key + '", ';
              valor = valor + '"values": [' + response.data[i].value + ']';
              valor = valor + ' }, ';
            }
            valor = valor.substring(0, valor.length - 2);
            valor = valor + ']';
            this.series2 = JSON.parse(valor);
            this.loading = false;
          }
        })
        .catch(function(error) {
          // console.log(error)
        });
    this.axios.get(this.ipapi + this.sec[1] + '/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anioI)
        .then((response) => {
          this.categorias2 = [];
          this.valor = [];
          var valor = '';
          if (this.listaOrden[this.indice][0].GRAFICO_B == 'bar' || this.listaOrden[this.indice][0].GRAFICO_B == 'hbar') {
            if (!response.data[0].text) {
              var valor = '';
              this.categorias2 = [];
              for (var i in response.data) {
                this.categorias2.push(response.data[i].key);
                valor = valor + response.data[i].value + ',';
              }
              this.totalG2 = 0;
              for (var i in response.data) {
                this.totalG2 = this.totalG2 + response.data[i].value;
              }
              valor = valor.substring(0, valor.length - 1);
              valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
              this.series2 = [];
              this.series2.push(valor);
              this.series2 = JSON.parse(this.series2);
              this.loading2 = false;
            } else {
              const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
              this.categorias2 = [];
              const categoria = [];
              let text = [];
              this.series2 = [];
              let valores = '';
              for (var i in response.data) {
                categoria.push(response.data[i].key);
                text.push(response.data[i].text);
              }
              this.totalG2 = 0;
              for (var i in response.data) {
                this.totalG2 = this.totalG2 + response.data[i].value;
              }
              this.categorias2 = [...new Set(categoria)];
              this.leyenda2 = text;
              text = [...new Set(text)];
              var valor = '[';
              for (var i in text) {
                valor = valor + '{ ';
                valor = valor + '"text": "' + text[i] + '", ';
                valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                valor = valor + '"values": [';
                valores = '';
                for (const j in response.data) {
                  if (text[i] == response.data[j].text) {
                    valores = valores + response.data[j].value + ',';
                  }
                }
                valores = valores.substring(0, valores.length - 1);
                valor = valor + valores + ']},';
              }
              valor = valor.substring(0, valor.length - 1);
              valor = valor + ']';
              this.series2.push(valor);
              this.series2 = JSON.parse(this.series2);
              this.loading2 = false;
            }
          }
          if (this.listaOrden[this.indice][0].GRAFICO_B == 'tabla') {
            for (var i in response.data) {
              this.categorias2.push(response.data[i].key);
              valor = valor + response.data[i].value + ',';
            }
            valor = valor.substring(0, valor.length - 1);
            valor = '[ { "values": [' + valor + '] } ]';
            this.series2.push(valor);
            this.series2 = JSON.parse(this.series2);
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_B == 'mixed') {
            for (var i in response.data) {
              if (response.data[i].TIPO == 'valor1') {
                this.series2.push(response.data[i].MONTO);
                this.categorias2.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'valor2') {
                this.series2a.push(response.data[i].MONTO);
                this.categorias2a.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje') {
                this.series2b.push(response.data[i].MONTO);
                this.categorias2b.push(response.data[i].ITEM);
              }
            }
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_B == 'mixed2') {
            for (var i in response.data) {
              if (response.data[i].TIPO == 'valor1') {
                this.series2.push(response.data[i].MONTO);
                this.categorias2.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'valor2') {
                this.series2a.push(response.data[i].MONTO);
                this.categorias2a.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje1') {
                this.series2b.push(response.data[i].MONTO);
                this.categorias2b.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje2') {
                this.series2c.push(response.data[i].MONTO);
                this.categorias2c.push(response.data[i].ITEM);
              }
            }
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_B == 'piemix') {
            var valor = '[';
            for (var i in response.data) {
              valor = valor + '{ ';
              valor = valor + '"text": "' + response.data[i].key + '", ';
              valor = valor + '"values": [' + response.data[i].value + ']';
              valor = valor + ' }, ';
            }
            valor = valor.substring(0, valor.length - 2);
            valor = valor + ']';
            this.series2 = JSON.parse(valor);
            this.loading = false;
          }
        })
        .catch(function(error) {
          // console.log(error)
        });

    this.axios.get(this.ipapi + this.sec[2] + '/' + this.cort + '/' + this.anioI)
        .then((response) => {
          this.categorias3 = [];
          this.valor = [];
          var valor = '';
          if (this.listaOrden[this.indice][0].GRAFICO_C == 'bar' || this.listaOrden[this.indice][0].GRAFICO_C == 'hbar') {
            if (!response.data[0].text) {
                  var valor = '';
                  this.categorias3 = [];
                  for (var i in response.data) {
                    this.categorias3.push(response.data[i].key);
                    valor = valor + response.data[i].value + ',';
                  }
                  this.totalG3 = 0;
                  for (var i in response.data) {
                    this.totalG3 = this.totalG3 + response.data[i].value;
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
                  this.series3 = [];
                  this.series3.push(valor);
                  this.series3 = JSON.parse(this.series3);
                  this.loading3 = false;
                } else {
                  const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
                  this.categorias3 = [];
                  const categoria = [];
                  let text = [];
                  this.series3 = [];
                  let valores = '';
                  for (var i in response.data) {
                    categoria.push(response.data[i].key);
                    text.push(response.data[i].text);
                  }
                  this.totalG3 = 0;
                  for (var i in response.data) {
                    this.totalG3 = this.totalG3 + response.data[i].value;
                  }
                  this.categorias3 = [...new Set(categoria)];
                  this.leyenda3 = text;
                  text = [...new Set(text)];
                  var valor = '[';
                  for (var i in text) {
                    valor = valor + '{ ';
                    valor = valor + '"text": "' + text[i] + '", ';
                    valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                    valor = valor + '"values": [';
                    valores = '';
                    for (const j in response.data) {
                      if (text[i] == response.data[j].text) {
                        valores = valores + response.data[j].value + ',';
                      }
                    }
                    valores = valores.substring(0, valores.length - 1);
                    valor = valor + valores + ']},';
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = valor + ']';
                  this.series3.push(valor);
                  this.series3 = JSON.parse(this.series3);
                  this.loading3 = false;
                }
          }
          if (this.listaOrden[this.indice][0].GRAFICO_C == 'tabla') {
            for (var i in response.data) {
              this.categorias3.push(response.data[i].key);
              valor = valor + response.data[i].value + ',';
            }
            valor = valor.substring(0, valor.length - 1);
            valor = '[ { "values": [' + valor + '] } ]';
            this.series3.push(valor);
            this.series3 = JSON.parse(this.series3);
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_C == 'mixed') {
            for (var i in response.data) {
              if (response.data[i].TIPO == 'valor1') {
                this.series3.push(response.data[i].MONTO);
                this.categorias3.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'valor2') {
                this.series3a.push(response.data[i].MONTO);
                this.categorias3a.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje') {
                this.series3b.push(response.data[i].MONTO);
                this.categorias3b.push(response.data[i].ITEM);
              }
            }
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_C == 'mixed2') {
            this.seccionG = this.sec[2];
            for (var i in response.data) {
              if (response.data[i].TIPO == 'valor1') {
                this.series3.push(response.data[i].MONTO);
                this.categorias3.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'valor2') {
                this.series3a.push(response.data[i].MONTO);
                this.categorias3a.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje1') {
                this.series3b.push(response.data[i].MONTO);
                this.categorias3b.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje2') {
                this.series3c.push(response.data[i].MONTO);
                this.categorias3c.push(response.data[i].ITEM);
              }
            }
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_C == 'piemix') {
            var valor = '[';
            for (var i in response.data) {
              valor = valor + '{ ';
              valor = valor + '"text": "' + response.data[i].key + '", ';
              valor = valor + '"values": [' + response.data[i].value + ']';
              valor = valor + ' }, ';
            }
            valor = valor.substring(0, valor.length - 2);
            valor = valor + ']';
            this.series3 = JSON.parse(valor);
            this.loading = false;
          }
        })
        .catch(function(error) {
          // console.log(error)
        });
    this.axios.get(this.ipapi + this.sec[2] + '/' + this.cort + '/' + this.trib + '/' + this.comp + '/' + this.anioI)
        .then((response) => {
          this.categorias3 = [];
          this.valor = [];
          var valor = '';
          if (this.listaOrden[this.indice][0].GRAFICO_C == 'bar' || this.listaOrden[this.indice][0].GRAFICO_C == 'hbar') {
            if (!response.data[0].text) {
                  var valor = '';
                  this.categorias3 = [];
                  for (var i in response.data) {
                    this.categorias3.push(response.data[i].key);
                    valor = valor + response.data[i].value + ',';
                  }
                  this.totalG3 = 0;
                  for (var i in response.data) {
                    this.totalG3 = this.totalG3 + response.data[i].value;
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = '[ { "text": "' + this.titulo[1] + '", "values": [' + valor + '] } ]';
                  this.series3 = [];
                  this.series3.push(valor);
                  this.series3 = JSON.parse(this.series3);
                  this.loading3 = false;
                } else {
                  const colores = ['#fc9c3b', '#00838f', '#3f51b5', '#fc9c3b', '#00bcd4'];
                  this.categorias3 = [];
                  const categoria = [];
                  let text = [];
                  this.series3 = [];
                  let valores = '';
                  for (var i in response.data) {
                    categoria.push(response.data[i].key);
                    text.push(response.data[i].text);
                  }
                  this.totalG3 = 0;
                  for (var i in response.data) {
                    this.totalG3 = this.totalG3 + response.data[i].value;
                  }
                  this.categorias3 = [...new Set(categoria)];
                  this.leyenda3 = text;
                  text = [...new Set(text)];
                  var valor = '[';
                  for (var i in text) {
                    valor = valor + '{ ';
                    valor = valor + '"text": "' + text[i] + '", ';
                    valor = valor + '"backgroundColor": "' + colores[i] + '", ';
                    valor = valor + '"values": [';
                    valores = '';
                    for (const j in response.data) {
                      if (text[i] == response.data[j].text) {
                        valores = valores + response.data[j].value + ',';
                      }
                    }
                    valores = valores.substring(0, valores.length - 1);
                    valor = valor + valores + ']},';
                  }
                  valor = valor.substring(0, valor.length - 1);
                  valor = valor + ']';
                  this.series3.push(valor);
                  this.series3 = JSON.parse(this.series3);
                  this.loading3 = false;
                }
          }
          if (this.listaOrden[this.indice][0].GRAFICO_C == 'tabla') {
            for (var i in response.data) {
              this.categorias3.push(response.data[i].key);
              valor = valor + response.data[i].value + ',';
            }
            valor = valor.substring(0, valor.length - 1);
            valor = '[ { "values": [' + valor + '] } ]';
            this.series3.push(valor);
            this.series3 = JSON.parse(this.series3);
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_C == 'mixed') {
            series3 = [];
            series3a = [];
            series3b = [];
            categorias3 = [];
            categorias3a = [];
            categorias3b = [];
            for (var i in response.data) {
              if (response.data[i].TIPO == 'valor1') {
                this.series3.push(response.data[i].MONTO);
                this.categorias3.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'valor2') {
                this.series3a.push(response.data[i].MONTO);
                this.categorias3a.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje') {
                this.series3b.push(response.data[i].MONTO);
                this.categorias3b.push(response.data[i].ITEM);
              }
            }
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_C == 'mixed2') {
            series3 = [];
            series3a = [];
            series3b = [];
            series3c = [];
            categorias3 = [];
            categorias3a = [];
            categorias3b = [];
            categorias3c = [];
            this.seccionG = this.sec[2];
            for (var i in response.data) {
              if (response.data[i].TIPO == 'valor1') {
                this.series3.push(response.data[i].MONTO);
                this.categorias3.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'valor2') {
                this.series3a.push(response.data[i].MONTO);
                this.categorias3a.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje1') {
                this.series3b.push(response.data[i].MONTO);
                this.categorias3b.push(response.data[i].ITEM);
              }
              if (response.data[i].TIPO == 'porcentaje2') {
                this.series3c.push(response.data[i].MONTO);
                this.categorias3c.push(response.data[i].ITEM);
              }
            }
            this.loading = false;
          }
          if (this.listaOrden[this.indice][0].GRAFICO_C == 'piemix') {
            var valor = '[';
            for (var i in response.data) {
              valor = valor + '{ ';
              valor = valor + '"text": "' + response.data[i].key + '", ';
              valor = valor + '"values": [' + response.data[i].value + ']';
              valor = valor + ' }, ';
            }
            valor = valor.substring(0, valor.length - 2);
            valor = valor + ']';
            this.series3 = JSON.parse(valor);
            this.loading = false;
          }
        })
        .catch(function(error) {
          // console.log(error)
        });
  },
  components: {BarChart, MixedChart, MixedChart2},
  data() {
    return {
      loading: false,
      loading2: false,
      loading3: false,
      download: false,
      calendario: 1,
      calendario2: 1,
      calendario3: 1,
      categorias: [],
      series: [],
      categorias2: [],
      series2: [],
      categorias3: [],
      series3: [],
      seriesa: [],
      seriesb: [],
      seriesc: [],
      categoriasa: [],
      categoriasb: [],
      categoriasc: [],
      series2a: [],
      series2b: [],
      series2c: [],
      categorias2a: [],
      categorias2b: [],
      categorias2c: [],
      series3a: [],
      series3b: [],
      series3c: [],
      categorias3a: [],
      categorias3b: [],
      categorias3c: [],
      seccionG: '',
      totalG: 0,
      totalG2: 0,
      totalG3: 0,
      sec: [],
      titulo: [],
      tipGraf: [],
      anios_select: [],
      txt_notas: [],
      txt_sel: '',
      viewEvent: {
        type: '',
        percentInView: 0,
        percentTop: 0,
        percentCenter: 0,
      },
    };
  },
  computed: {
    ...mapState([
      'ubicacionmaps',
      'cantGraficos',
      'ipapi',
      'anioI',
    ]),
  },
};
</script>
