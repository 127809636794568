<template>
  <div>
   <div class="box_mode content_wrapper">
      <br>
      <br>
      <br>
      <br>
      <section id="territorios">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-md-2"></div>
					<div class="col-md-8">
						<h2 class="text-center">Personal, Unidades y Dirección de la Corte Suprema</h2>
						<p class="text-center"></p>
					</div>
					<div class="col-md-2"></div>
				</div>
			</div>
			<hr>
		</section><br><br>
		<section id="salas" class="bg-celeste"><hr><hr>
			<div class="container"><hr><hr>
				<div class="cifras row justify-content-center">
					<div class="col-md-2"></div>
					<div class="col-md-2 text-center">
						<div class="icon icon-personal">
						<img src="@/assets/img/1avatar.png" class="img">
						</div>
						<h3>Fiscal Judicial</h3>
					</div>
					<div class="col-md-2 text-center">
						<div class="icon icon-personal">
						<img src="@/assets/img/1avatar.png" class="img">
						</div>
						<h3>Secretario</h3>
					</div>
					<div class="col-md-2 text-center">
						<div class="icon icon-personal">
						<img src="@/assets/img/1avatar.png" class="img">
						</div>
						<h3>Prosecretario</h3>
					</div>
					<div class="col-md-2 text-center">
						<div class="icon icon-personal">
						<img src="@/assets/img/2avatar.png" class="img">
						</div>
						<h3>8 Relatores y Relatoras</h3>
					</div>
					<div class="col-md-2"></div>
				</div>
				<hr>
		<div class="row justify-content-center">
				<div class="col-md-2"></div>
				<div class="col-md-8">
						<p class="text-center">El personal de apoyo técnico y profesional se distribuye en unidades y direcciones: </p>
					</div>
				</div>
				<div class="col-md-2"></div>
				<hr>
			</div>

			<div class="container">
				<div class="row justify-content-center">
					<div class="col-md-2"></div>
					<div class="col-md-4 salas">
						<h4 class="text-center">Direcciones de la Corte Suprema</h4>

					<ul>
						<li>Dirección de Estudios, Análisis y Evaluación de la Corte Suprema</li>
						<li>Dirección de Bibliotecas y Centro Documental</li>
						<li>Dirección de Comunicaciones del Poder Judicial</li>
						<li>Dirección de Asuntos Internacionales y Derechos Humanos</li>
					</ul></div>
				<div class="col-md-4 salas">
						<h4 class="text-center">Unidades</h4>

					<ul>
						<li>Presidencia</li>
						<li>Secretaría</li>
						<li>Oficina de Título</li>
						<li>Oficina de Asuntos Administrativos y Oficina de Pleno</li>
						<li>Secretarios de Ministros de la Corte Suprema</li>
						<li>Secretaría Técnica de Igualdad de Género y No Discriminación</li>
					</ul></div>
					<div class="col-md-2"></div>
				</div>
				<hr>
			</div>
		</section>
	</div>
  </div>
</template>
<script>

import {
  mapState,
  mapActions,
} from 'vuex';
export default {
  methods: {
    ...mapActions([
      'actjuzgados',
      'actcortes',
    ]),
    UbiTerrMaps: function(a) {
      this.partes = a.split('-');
      this.ubicacion = this.partes[1];
    },
  },
  mounted() {
    // this.partes = this.$route.params.ubicacion.split('-')
    // this.ubicacion = this.partes[1]
  },
  components: { },
  data() {
    return {
      ubicacion: 0,
    };
  },
  computed: {
    ...mapState([
      'juzgados',
      'cortes',

    ]),
  },
};
</script>
<style src="vue-slim-tabs/themes/default.css"></style>
<style>
/* tribunales */
.tribunales2{
	position: relative;
}
.tribunales2 a.btn{
    background-color: #fc9c3b;
    color: #fff;
    border-radius: 100px;
    padding: 10px 15px;
    line-height: 14px;
    width: auto;
    text-align: center;
}

.tribunales2 a.btn:hover{
    color: #fff;
	background-color: #3f51b5;

}
.tribunales2.active a.btn{
	background-color: #3f51b5;
	color: #FFF;

}
.tribunales2 ul.sub{
	margin: 0;
    list-style: none;
    padding: 10px 20px;
    background-color: #bfcdee;
    border-radius: 20px;
    position: absolute;
    bottom: 50px;
    left: -16%;
    z-index: 99;
    display: none;
	max-height: 300px;
	overflow: scroll;
}
.tribunales2.active ul.sub{
	display: block;
}
.tribunales2 ul.sub li{
	text-align: left;
    padding: 0;
    white-space: nowrap;
    min-width: 280px;
}
.tribunales2 ul.sub li,
.tribunales2 ul.sub li a{
	font-size: 11px;
    color: #666;
}

section .corte{

}
section .corte .icon{
}
section .corte .icon.icon-corte{
	width: 180px;
    height: 180px;
    border-radius: 50%;
    background-color: rgba(63,81,181,.2);
    margin: 50px auto 0;
}
section .corte .icon.icon-corte img{
	width: 100%;
    display: inline-block;
	position: relative;
	margin-top: 25%;

}
section .corte .icon.icon-corte h3{
	font-size: 18px;
	margin-bottom: 20px;

}
section .corte .icon.icon-corte span{
    font-size: 32px;
    color: #FFFF;
    font-weight: bolder;
	text-shadow: 2px 1px 6px #666666;
	margin-top: 20px;

}
section .corte p{
	color: #666;
    font-size: 14px;
    line-height: 18px;
    margin: 5px auto;
	max-width: 100%;
}
section .corte h3{
    font-size: 28px;
	font-weight: bolder;
	text-align: center;
    line-height: 18px;
    margin: 15px auto;
}
section .cifras .icon.icon-personal{

}
section .cifras .icon.icon-personal img{
	width: 60%;
    display: inline-block;
	margin-bottom: 10px;
	text-shadow: 2px 1px 6px #666666;

}
section .cifras .icon.icon-personal span{
    font-size: 32px;
    color: #FFFF;
    font-weight: bold;
	text-shadow: 2px 1px 6px #666666;

}
section .cifras2 .icon{
z-index: 3;}

section .cifras2 .icon.icon-cifras2{
	width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: rgba(63,81,181,.2);
    margin: auto;
}
section .cifras2 .icon.icon-cifras2 img{
	width: auto;
	max-height: 40px;
    display: inline-block;
    margin-top: 0;
}
section .cifras2 .icon.icon-cifras2 h3{
	font-weight: bold;
	font-size: 14px;
	bottom: 0px;
}
section .cifras2 .icon.icon-cifras2 span{
    font-size: 28px;
    color: #FFFF;
    font-weight: bold;
	text-shadow: 2px 1px 6px #666666;
		padding: 20px 0 0 0;
	top: 0;

}
section .cifras2 p{
	color: #666;
    font-weight: normal;
	font-style: italic;
    font-size: 12px;
    line-height: 14px;
    margin: 5px auto;
    max-width: 100px;
}
section .cifras3 .icon{
z-index: 3;}

section .cifras3 .icon.icon-cifras3{
	width: 100px;
    height: 100px;
    margin: auto;
}
section .cifras3 .icon.icon-cifras3 img{
	width: auto;
	max-height: 40px;
    display: inline-block;
    margin-top: 0;
}
section .cifras3 .icon.icon-cifras3 h3{
	font-weight: bold;
	font-size: 14px;
	bottom: 0px;
}
section .cifras3 .icon.icon-cifras3 span{
    font-size: 18px;
    color: #fc4f5e;
    font-weight: bold;
	padding: 20px 0 0 0;
	top: 0;

}
section .cifras3 p{
	color: #666;
    font-weight: normal;
	font-style: italic;
    font-size: 12px;
    line-height: 14px;
    margin: 5px auto;
    max-width: 100px;
}
</style>
