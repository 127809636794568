<template>
  <div>
    <div class="box_mode content_wrapper">
      <br>
      <br>
      <br>
      <br>
      <div class="home_area">
        <div class="container" id="territorios">
          <div class="row">
            <div class="col-md-12">
              <div class="section_title animated a_fu">
                <div class="h1">Territorios</div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="mt-4 showup-xs">
            <select class="browser-default custom-select" v-on:change="UbiTerrMaps($event)" style="width: 90%">
              <option selected value="10-Arica">Arica</option>
              <option value="11-Iquique">Iquique</option>
              <option value="15-Antofagasta">Antofagasta</option>
              <option value="20-Copiapó">Copiapó</option>
              <option value="25-La Serena">La Serena</option>
              <option value="30-Valparaíso">Valparaíso</option>
              <option value="90-Santiago">Santiago</option>
              <option value="91-San Miguel">San Miguel</option>
              <option value="35-Rancagua">Rancagua</option>
              <option value="40-Talca">Talca</option>
              <option value="45-Chillán">Chillán</option>
              <option value="46-Concepción">Concepción</option>
              <option value="50-Temuco">Temuco</option>
              <option value="55-Valdivia">Valdivia</option>
              <option value="56-Puerto Montt">Puerto Montt</option>
              <option value="60-Coyhaique">Coyhaique</option>
              <option value="61-Punta Arenas">Punta Arenas</option>
            </select>
          </div>
          <div class="mt-4 hidden-xs hidden-sm">
            <div class="mapa d-flex">
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Arica')}" v-on:click="UbiTerrMaps('10-Arica')">
                <span class="name">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Arica&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span class="icon icon-arica"></span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Iquique')}" v-on:click="UbiTerrMaps('11-Iquique')">
                <span class="icon icon-iquique"></span>
                <span class="name">Iquique&nbsp;</span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Antofagasta')}" v-on:click="UbiTerrMaps('15-Antofagasta')">
                <span class="name">Antofagasta&nbsp;</span>
                <span class="icon icon-antofagasta"></span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Copiapó')}" v-on:click="UbiTerrMaps('20-Copiapó')">
                <span class="icon icon-copiapo"></span>
                <span class="name">&nbsp;&nbsp;&nbsp;Copiapó&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='La Serena')}" v-on:click="UbiTerrMaps('25-La Serena')">
                <span class="name">&nbsp;La Serena&nbsp;&nbsp;</span>
                <span class="icon icon-laserena"></span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Valparaíso')}" v-on:click="UbiTerrMaps('30-Valparaíso')">
                <span class="icon icon-valparaiso"></span>
                <span class="name">&nbsp;Valparaíso&nbsp;</span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Santiago')}" v-on:click="UbiTerrMaps('90-Santiago')">
                <span class="name">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Santiago&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span class="icon icon-santiago"></span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='San Miguel')}" v-on:click="UbiTerrMaps('91-San Miguel')">
                <span class="icon icon-sanmiguel"></span>
                <span class="name">&nbsp;San Miguel&nbsp;</span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Rancagua')}" v-on:click="UbiTerrMaps('35-Rancagua')">
                <span class="name">Rancagua</span>
                <span class="icon icon-rancagua"></span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Talca')}" v-on:click="UbiTerrMaps('40-Talca')">
                <span class="icon icon-talca"></span>
                <span class="name">&nbsp;&nbsp;&nbsp;Talca&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Chillán')}" v-on:click="UbiTerrMaps('45-Chillán')">
                <span class="name">&nbsp;&nbsp;&nbsp;&nbsp;Chillán&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span class="icon icon-chillan"></span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Concepción')}" v-on:click="UbiTerrMaps('46-Concepción')">
                <span class="icon icon-concepcion"></span>
                <span class="name">&nbsp;Concepción&nbsp;</span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Temuco')}" v-on:click="UbiTerrMaps('50-Temuco')">
                <span class="name">&nbsp;&nbsp;&nbsp;Temuco&nbsp;&nbsp;&nbsp;</span>
                <span class="icon icon-temuco"></span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Valdivia')}" v-on:click="UbiTerrMaps('55-Valdivia')">
                <span class="icon icon-valdivia"></span>
                <span class="name">&nbsp;&nbsp;Valdivia&nbsp;&nbsp;</span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Puerto Montt')}" v-on:click="UbiTerrMaps('56-Puerto Montt')">
                <span class="name">Puerto Montt</span>
                <span class="icon icon-puertomontt"></span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Coyhaique')}" v-on:click="UbiTerrMaps('60-Coyhaique')">
                <span class="icon icon-coyhaique"></span>
                <span class="name">&nbsp;Coyhaique&nbsp;&nbsp;</span>
              </div>
              <div class="sector" v-bind:class="{'active':(ubmaps[1] =='Punta Arenas')}" v-on:click="UbiTerrMaps('61-Punta Arenas')">
                <span class="name">Punta Arenas</span>
                <span class="icon icon-puntaarenas"></span>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  <div class="brand_area sp100 animated a_fu" id="territorio-result">
    <CorteApeTerr
      :tabs="tabs"
      :cgraf="cgraf"
      :secc="secc"
      :cort="ubmaps[0]"
      :texto="txtPrinc"
      :cifras="cifras"
      :titulo="'Corte de Apelaciones de ' + ubmaps[1]"
      :subtitulo="'Seleccione el ítem para revisar las cifras de la Corte de Apelaciones de ' + ubmaps[1]"
      :key="keyCorteApe"
    ></CorteApeTerr>
    <br>
    <br>
    <TribunalesTerr
      :cort="ubmaps[0]"
      :titulo="'Tribunales de 1ª Instancia de ' + ubmaps[1]"
    ></TribunalesTerr>
  </div>
  <input v-model="ubicacionmaps" style="display: none">
</div>
</template>
<script>
import countTo from 'vue-count-to';
import CorteApeTerr from '@/components/CorteApeTerr.vue';
import TribunalesTerr from '@/components/TribunalesTerr.vue';
import {
  mapState,
  mapActions,
} from 'vuex';
export default {
  methods: {
    ...mapActions([
      'actubicacionMaps',
      'actcifras',
      'acttexto',
      'actmodulos',
      'acttabulaciones',
      'actsecciones',
      'actcantgraficos',
      'delcantgraficos',
    ]),
    UbiTerrMaps: function(a) {
      if (event.target.value) {
        a = event.target.value;
      }
      console.log(a);
      this.cgraf = [];
      if (typeof a === 'object') {
        this.ubmaps = a;
      } else {
        this.ubmaps = [];
        this.partes = a.split('-');
        this.ubmaps = this.partes;
      }
      this.axios.get(this.ipapi + 'modulos/0/' + this.ubmaps[0] + '/0')
          .then((response) => {
            this.m = [];
            this.m.push(JSON.parse(response.data[0].GRAFICOS));
            this.tabs = this.m[0].TEXTO;
            this.secc = this.m[0].SECCION;
            this.axios.get(this.ipapi + 'modulos/' + this.ubmaps[0] + '/0/0')
                .then((response) => {
                  this.actmodulos(response.data);
                })
                .catch(function(error) {
                  console.log(error);
                });
            this.cgraf = [];
            for (const i in this.secc) {
              this.axios.get(this.ipapi + 'textos/apelaciones/' + this.secc[i] + '/0')
                  .then((response) => {
                    this.cgraf.push(response.data);
                  })
                  .catch(function(error) {
                    console.log(error);
                  });
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      this.axios.get(this.ipapi + 'cifras_clave/apelaciones/' + this.ubmaps[0] + '/' + (this.anioI === '' ? (new Date().getFullYear()) - 2 : this.anioI))
          .then((response) => {
            this.actcifras(response.data);
          })
          .catch(function(error) {
            console.log(error);
          });
      this.axios.get(this.ipapi + 'textos/apelaciones/' + this.ubmaps[0] + '/0')
          .then((response) => {
            this.txtPrinc = response.data;
            console.log('TEXTOS');
            console.log(this.txtPrinc);
          })
          .catch(function(error) {
            console.log(error);
          });
      this.keyCorteApe += 1;
    },
  },
  components: {
    countTo,
    CorteApeTerr,
    TribunalesTerr,
  },
  mounted() {
    this.$watch(
    	() => {
    	},
        (newVal, oldVal) => {
        },
    );
    console.log('Component mounted. test');
    this.ubmaps = [];
    // if (this.$route.params.ubicacion !== undefined){
    // this.ubmaps = this.$route.params.ubicacion.split('-')
    // }else{
    //  this.ubmaps[0] = '10'
    //  this.ubmaps[1] = 'Arica'
    //  this.keyCorteApe += 1
    // }
  },
  watch: {
    'deep': true,
  	'ubicacionmaps': function(newVal, oldVal) {
      this.UbiTerrMaps(newVal);
    },
  },
  data() {
    return {
      test: 'Prueba components',
      p: [],
      m: [],
      txtPrinc: [],
      tabs: [],
      cgraf: [],
      mods: [],
      mdc: 0,
      secc: [],
      ubmaps: [],
      keyCorteApe: 0,
      viewEvent: {
        type: '',
        percentInView: 0,
        percentTop: 0,
        percentCenter: 0,
      },
    };
  },
  computed: {
    ...mapState([
      'ubicacionmaps',
      'cifras',
      'texto',
      'modulos',
      'tabulaciones',
      'secciones',
      'cantGraficos',
      'anioI',
      'ipapi',
    ]),
  },
};
</script>
